import { ChangeEvent, useEffect, useState } from "react";
import Props from "./Props";

export function useFilterBody(props: Props) {
  const { chips } = props;
  const [filteredChips, setFilteredChips] = useState([...chips]);
  const needShowMore = filteredChips.length > 10;
  const needAutocomplete = chips.length > 20;
  const [toggled, setToggled] = useState(false);
  const [query, setQuery] = useState("");

  const toggle = () => {
    setToggled((prev) => !prev);
  };

  const handleQueryChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setQuery(event.target.value);
  };

  useEffect(() => {
    setFilteredChips(
      query
        ? chips.filter((chip) =>
            chip.name.toLocaleLowerCase().includes(query.toLocaleLowerCase())
          )
        : [...chips]
    );
  }, [chips, query]);

  const clearQuery = () => {
    setQuery("");
  };

  return {
    needShowMore,
    toggled,
    toggle,
    handleQueryChange,
    query,
    filteredChips,
    needAutocomplete,
    clearQuery,
  };
}
