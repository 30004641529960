import Props from "./Props";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDebounce } from "../../hooks/useDebounce";

export function useSearchField(props: Props) {
  const isQuerySet = useRef(false);
  const [query, setQuery] = useState("");
  const [appliedQuery, setAppliedQuery] = useState("");
  const [onFocus, setOnFocus] = useState(false);
  const { onChange, renderItemsBlock } = props;
  const applyQuery = useDebounce(setAppliedQuery, 500);
  const onChangeWidthDelay = useDebounce(onChange, 500);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchParam = searchParams.get("search");

    if (searchParam && !isQuerySet.current) {
      setQuery(searchParam);
      onChange(searchParam);
      isQuerySet.current = true;
    }
  }, [onChange]);

  const onFocusHandles = () => {
    setOnFocus(true);
  };

  const onBlurHandler = () => {
    setOnFocus(false);
  };

  const handleChangeSearchQuery = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trimStart();

    setQuery(value);
    applyQuery(value);

    if (!renderItemsBlock) {
      onChangeWidthDelay(value);
    }
  };

  const handleClearSearchQuery = () => {
    setQuery("");
    setAppliedQuery("");
    onChange("");
  };

  return {
    handleChangeSearchQuery,
    query,
    appliedQuery,
    handleClearSearchQuery,
    onFocusHandles,
    onBlurHandler,
    onFocus,
  };
}
