import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";
import {
  FOOTER_HEIGHT,
  FOOTER_HEIGHT_MOBILE,
} from "../../common/components/Footer/styles";

export const useStyles = makeStyles()((theme: AppTheme) => ({
  root: {
    width: "100%",
    minHeight: "calc(var(--vh, 1vh) * 100)",
    flex: 1,
  },
  rootNoOverflowX: {
    maxWidth: "100vw",
    overflowX: "hidden",
  },
  sidebar: {
    minWidth: 236,
  },
  content: {
    padding: theme.spacing(0, 2),
    minHeight: `calc(calc(var(--vh, 1vh) * 100) - ${FOOTER_HEIGHT}px)`,
    [theme.breakpoints.down("md")]: {
      minHeight: `calc(calc(var(--vh, 1vh) * 100) - ${FOOTER_HEIGHT_MOBILE}px)`,
      padding: theme.spacing(1, 2),
    },
  },
  contentMinimal: {
    minHeight: `calc(calc(var(--vh, 1vh) * 100) - ${FOOTER_HEIGHT}px)`,
    display: "flex",
    flexDirection: "column",
  },
  link: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: "14px !important",
    padding: "10px 8px !important",
  },
  linkNew: {
    position: "relative",
    fontWeight: theme.typography.fontWeightBold,
  },
  contentWrapperMinimal: {
    minHeight: "calc(var(--vh, 1vh) * 100)",
  },
  dashboardButton: {
    marginRight: theme.spacing(2),
  },
}));
