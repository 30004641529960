import { ClassNameMap } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { AppTheme } from "@/theme";

export type ClassNames = "root" | "sign" | "header" | "headerContainer";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: AppTheme) =>
    createStyles({
      root: {},
      header: {
        width: "100%",
      },
      headerContainer: {
        padding: theme.spacing(1, 0),
        cursor: "pointer",
        "&:hover > div": {
          color: "#458CFF",
        },
      },
      sign: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        width: 15,
        marginTop: -3,
        height: "100%",
        color: "#458CFF",
      },
    })
);
