import { JobsFilter } from "./models/JobsFilter";

export const defaultJobsFilter: JobsFilter = {
  query: "",
  city: [],
  company: [],
  jobType: [],
  country: [],
  state: [],
  category: [],
  order: "DESC",
};
