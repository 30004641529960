import { FC } from "react";

import Props from "./Props";
import Dialog from "../Dialog";
import TextField from "@mui/material/TextField";
import { useCompanyConnectDialog } from "./useCompanyConnectDialog";
import Flex from "../Flex";
import AppNavLink from "../AppNavLink";
import { AppRoute, getRouteWithSlash } from "@/router/AppRoute";
import { Form } from "../Form";
import { D_COMPANY_CONNECT } from "@/i18n/dictionary/companyConnect.dictionary";

const CompanyConnectDialog: FC<Props> = (props: Props) => {
  const { visible, onClose } = props;
  const { acceptAction, form, handleChangeFormField, loading, childRef } =
    useCompanyConnectDialog(props);

  const subtitle = (
    <p>
      {D_COMPANY_CONNECT.subtitle}
      <AppNavLink to={getRouteWithSlash(AppRoute.COMPANY_REGISTER)}>
        {D_COMPANY_CONNECT.signUpLink}
      </AppNavLink>
      {D_COMPANY_CONNECT.signUpMessage}
    </p>
  );

  return (
    <Dialog
      title={D_COMPANY_CONNECT.title}
      subTitle={subtitle}
      loading={loading}
      onClose={onClose}
      open={visible}
      illustration={
        <img
          width={278}
          src={"/images/company-connect-illustration.png"}
          alt={"/images/company-connect-illustration.png"}
        />
      }
      illustrationPosition={"left"}
      acceptText={D_COMPANY_CONNECT.acceptButtonText}
      acceptAction={acceptAction}>
      <Form ref={childRef} formErrors={form.errors} onSubmit={acceptAction}>
        <Flex direction={"column"}>
          <TextField
            required
            fullWidth
            name={"email"}
            onChange={handleChangeFormField}
            title={D_COMPANY_CONNECT.email}
            type={"email"}
            label={D_COMPANY_CONNECT.email}
            value={form.values.email}
            helperText={form.errors.email}
            error={!!form.errors.email}
          />
          <TextField
            multiline
            name={"comment"}
            onChange={handleChangeFormField}
            title={D_COMPANY_CONNECT.comment}
            type={"text"}
            label={D_COMPANY_CONNECT.comment}
            value={form.values.comment}
            helperText={form.errors.comment}
            error={!!form.errors.comment}
            minRows={3}
            maxRows={5}
          />
        </Flex>
      </Form>
    </Dialog>
  );
};

export default CompanyConnectDialog;
