import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";

export const useStyles = makeStyles()((theme: AppTheme) => ({
  root: {},
  iconContainer: {
    padding: theme.spacing(1.5),
    height: 40,
  },
}));
