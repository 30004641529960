import React, { ReactNode } from "react";
import ContentWrapper from "../../layout/common/components/ContentWrapper";

export const wrapContent = (
  content: ReactNode | ReactNode[],
  fullWidth?: boolean,
  flex?: boolean
) =>
  fullWidth ? (
    content
  ) : (
    <ContentWrapper
      style={flex ? { display: "flex", alignItems: "center" } : undefined}>
      {content}
    </ContentWrapper>
  );

export const scrollToRef = (ref: HTMLDivElement | null | undefined) => {
  if (!ref) {
    return;
  }

  ref.scrollIntoView({ behavior: "smooth" });
};
