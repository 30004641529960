import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";

export const FILTERS_WIDTH = 260;

export const useStyles = makeStyles()((theme: AppTheme) => ({
  root: {
    [theme.breakpoints.up("lg")]: {
      minWidth: FILTERS_WIDTH,
      maxWidth: FILTERS_WIDTH,
      marginRight: theme.spacing(3),
    },
  },
  wrapper: {
    position: "relative",
  },
  container: {
    position: "sticky",
    top: 0,
  },
  title: {
    fontWeight: 600,
    margin: theme.spacing(2, 0),
  },
  filtersContainer: {
    [theme.breakpoints.up("md")]: {
      "& > div:not(:last-of-type)": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.03)",
      },
    },
  },
  icon: {
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: "inherit",
    fontWeight: 600,
    borderRadius: "10%",
    transition: "all 0.3s",
    "&:hover": {
      backgroundColor: "#f8f8f9",
      boxShadow: "0px 1px 12px rgba(84, 84, 84, 0.2)",
    },
  },
  filterButton: {
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
    padding: theme.spacing(1.5),
    borderRadius: 0,
    fontSize: theme.typography.pxToRem(16),
    backgroundColor: "#fff",
    zIndex: 10,
  },
}));
