import { showAlert } from "@/common/utils/customEvents";

export function useClipboard() {
  const copyToClipboard = (content: string, successMessage: string) => {
    navigator.clipboard.writeText(content).then(() => {
      showAlert(successMessage, "info");
    });
  };

  return { copyToClipboard };
}
