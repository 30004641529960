const vacancyView = Object.freeze({
  vacancyBudgetTitle: "Budget",
  joinTelegram: "Subscribe",
  statusTooltip:
    "This vacancy is not active. Please, try to find another one similar vacancy",
  status: (status: string) => `Vacancy ${status}`,
  salary: "Salary",
  salaryNotSet: "Not set",
  bonus: "Referral bonus",
  updatedAt: "Updated At",
  vacancyDescriptionTitle: "Description",
  requirementsTitle: "Requirements",
  skillsTitle: "Skills",
  vacancyNotAvailable: "This vacancy is no longer available",
  companyTitle: "Company",
  summaryTitle: "Job details",
  showDetailsTitle: "full details",
  hideDetailsTitle: "hide details",
  similarTitle: "Similar jobs",
  detailsButton: "details",
  requirementExperience: "Min experience",
  requirementEnglish: "English level",
  requirementSeniority: "Seniority",
  ACTIVE: "Active",
  PAUSED: "Paused",
  CLOSED: "Closed",
  CANCELLED: "Cancelled",
  recommend: "Apply or Recommend for a Bonus",
  recommendForRecruiter: "Recommend for a Bonus",
  recommendWithoutBonus: "Apply to this job",
  recommendWithoutBonusForRecruiter: "Recommend a candidate",
  applyButton: "Apply to this job",
  connect: "Connect & Collect",
  warningUnverifiedRecruiter:
    "You will not receive a bonus for this recommendation because your account has not yet been verified. To recommend for a bonus, wait for verification",
});

export default vacancyView;

export type DVacancyViewType = Record<keyof typeof vacancyView, any>;
