import { Dictionary } from "@/i18n/dictionary.types";
import profileDropdownUA from "@/i18n/dictionary/ua/profileDropdown";
import profileDropdownEN, {
  DProfileDropdownType,
} from "@/i18n/dictionary/en/profileDropdown";
import { t } from "@/i18n/t";

const PROFILE_DROPDOWN: Dictionary<DProfileDropdownType> = {
  ua: profileDropdownUA,
  en: profileDropdownEN,
};

export const D_PROFILE_DROPDOWN = t(PROFILE_DROPDOWN);
