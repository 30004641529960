import React, { FC } from "react";
import { NavLink } from "react-router-dom";

import Props from "./Props";
import { useStyles } from "./styles";
import clsx from "clsx";

const AppNavLink: FC<Props> = (props: Props) => {
  const { variant, className, external, ...rest } = props;
  const { classes } = useStyles();

  if (external) {
    return (
      // @ts-ignore
      <a
        className={clsx(
          classes.root,
          variant === "secondary" ? classes.secondary : classes.primary,
          className
        )}
        href={external}
        {...rest}
      />
    );
  }

  return (
    <NavLink
      className={clsx(
        classes.root,
        variant === "secondary" ? classes.secondary : classes.primary,
        className
      )}
      {...rest}
    />
  );
};

export default AppNavLink;
