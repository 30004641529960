import * as Types from "../../../generated/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type NewRecommendationsCountQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type NewRecommendationsCountQuery = {
  __typename?: "Query";
  newRecommendationsCount: number;
};

export const NewRecommendationsCountDocument = gql`
  query NewRecommendationsCount {
    newRecommendationsCount
  }
`;

/**
 * __useNewRecommendationsCountQuery__
 *
 * To run a query within a React component, call `useNewRecommendationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewRecommendationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewRecommendationsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useNewRecommendationsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NewRecommendationsCountQuery,
    NewRecommendationsCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NewRecommendationsCountQuery,
    NewRecommendationsCountQueryVariables
  >(NewRecommendationsCountDocument, options);
}
export function useNewRecommendationsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NewRecommendationsCountQuery,
    NewRecommendationsCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NewRecommendationsCountQuery,
    NewRecommendationsCountQueryVariables
  >(NewRecommendationsCountDocument, options);
}
export type NewRecommendationsCountQueryHookResult = ReturnType<
  typeof useNewRecommendationsCountQuery
>;
export type NewRecommendationsCountLazyQueryHookResult = ReturnType<
  typeof useNewRecommendationsCountLazyQuery
>;
export type NewRecommendationsCountQueryResult = Apollo.QueryResult<
  NewRecommendationsCountQuery,
  NewRecommendationsCountQueryVariables
>;
