import Props from "./Props";
import { useCallback } from "react";

export function useFilterHeader(props: Props) {
  const { onClear } = props;

  const handleClear = useCallback(
    (e: any) => {
      e.stopPropagation();
      onClear();
    },
    [onClear]
  );

  return { handleClear };
}
