import { ClassNameMap } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { AppTheme } from "@/theme";

export type ClassNames = "root" | "select" | "input" | "selectIcon";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: AppTheme) =>
    createStyles({
      root: {
        margin: theme.spacing(0, 0, 0, 0.5),
        width: 55,

        "& .MuiSelect-select": {
          padding: 10,
          paddingRight: "20px !important",
        },

        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "transparent",
          },
          "&:hover fieldset": {
            borderColor: "transparent",
          },
          "&.Mui-focused fieldset": {
            borderColor: "transparent",
          },
        },
      },
      select: {
        padding: 10,
        paddingRight: "24px !important",

        "&:focus": {
          backgroundColor: "transparent",
        },
      },
      selectIcon: {
        right: 0,
      },
      input: {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(14),
      },
    })
);
