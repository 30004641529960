import React, { FC } from "react";

import PublicLayoutProps from "./PublicLayout.types";
import { useStyles } from "./styles";
import Footer from "@/layout/common/components/Footer";
import Header from "@/layout/common/components/Header";
import { useIsAuthorised } from "@/auth/hooks/useIsAuthorised";
import { Redirect } from "react-router-dom";
import { AppRoute, getRouteWithSlash } from "@/router/AppRoute";
import ContentWrapper from "../../common/components/ContentWrapper";
import clsx from "clsx";
import { usePublicLayout } from "./usePublicLayout";
import SidebarNavigation from "@/layout/common/components/Sidebar/components/SidebarNavigation";
import { sidebarLinks } from "./sidebarLinks";
import Sidebar from "@/layout//common/components/Sidebar";
import { useIsCandidate } from "@/auth/hooks/useIsCandidate";

const PublicLayout: FC<PublicLayoutProps> = (props: PublicLayoutProps) => {
  const { children, minimal, hiddenForLoggedIn, hideOverflowX } = props;
  const isAuthorized = useIsAuthorised();
  const { classes } = useStyles();
  const { toggleSidebar, openSidebar, isDesktop } = usePublicLayout();
  const isCandidate = useIsCandidate();
  const navLinks = isCandidate
    ? sidebarLinks.filter((link) => link.path !== AppRoute.CANDIDATES_LIST)
    : sidebarLinks;

  if (hiddenForLoggedIn && isAuthorized) {
    return <Redirect to={getRouteWithSlash(AppRoute.NOT_FOUND)} />;
  }

  const renderContent = () => {
    return minimal ? (
      children
    ) : (
      <ContentWrapper className={minimal && classes.contentWrapperMinimal}>
        {children}
      </ContentWrapper>
    );
  };

  return (
    <div
      className={clsx(classes.root, hideOverflowX && classes.rootNoOverflowX)}>
      {!minimal && (
        <Header
          withBurger
          burgerPlacement={"right"}
          burgerVisible={"mobile"}
          toggleSidebar={toggleSidebar}
        />
      )}
      <main
        className={clsx(classes.content, minimal && classes.contentMinimal)}>
        {renderContent()}
      </main>
      {!minimal && (
        <>
          <Sidebar
            toggleSidebar={toggleSidebar}
            isDesktop={isDesktop}
            anchor={"right"}
            open={openSidebar}
            variant={"temporary"}>
            <SidebarNavigation
              className={classes.sidebar}
              textVisibility={"always"}
              isSidebarOpen={openSidebar}
              links={navLinks}
              toggleSidebar={toggleSidebar}
            />
          </Sidebar>
          <Footer />
        </>
      )}
    </div>
  );
};

export default PublicLayout;
