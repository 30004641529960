import { FC } from "react";
import { ReactComponent as LogoIcon } from "../../../logo.svg";
import { ReactComponent as LogoIconFull } from "../../../logo-full.svg";

import Props from "./Props";
import { useStyles } from "./styles";
import { Hidden, useMediaQuery, useTheme } from "@mui/material";
import Flex from "../Flex";
import clsx from "clsx";
import { AppTheme } from "@/theme";
import { useIsAuthorised } from "@/auth/hooks/useIsAuthorised";
import { useIsAdmin } from "@/auth/hooks/useIsAdmin";

const Logo: FC<Props> = (props: Props) => {
  const theme: AppTheme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { withName, className, size, ...rest } = props;
  const isAuthorized = useIsAuthorised();
  const isAdmin = useIsAdmin();
  const sizeProps: Record<string, any> = {
    height: isMobile ? 34 : 42,
    width: isMobile ? 44 : 52,
  };

  if (size) {
    sizeProps.height = size;
    sizeProps.width = size;
  }

  const classes = useStyles();

  const renderLogo = () => {
    if (!isAdmin && isAuthorized && withName) {
      return <LogoIconFull />;
    }

    return withName ? (
      <>
        <Hidden smDown>
          <LogoIconFull />
        </Hidden>
        <Hidden smUp>
          <LogoIcon {...sizeProps} />
        </Hidden>
      </>
    ) : (
      <LogoIcon {...sizeProps} />
    );
  };

  return (
    <Flex
      className={clsx(classes.root, className)}
      alignVertical={"center"}
      {...rest}>
      {renderLogo()}
    </Flex>
  );
};

export default Logo;
