import React, { FC } from "react";
import { ReactComponent as SearchIcon } from "../../icons/search.svg";
import { ReactComponent as ClearIcon } from "../../icons/close.svg";

import Props from "./Props";
import { useStyles } from "./styles";
import Flex from "../Flex";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useSearchField } from "./useSearchField";
import clsx from "clsx";
import { D_JOBS_LIST } from "@/i18n/dictionary/jobsList.dictionary";

const SearchField: FC<Props> = (props: Props) => {
  const { className, small } = props;
  const {
    handleChangeSearchQuery,
    query,
    handleClearSearchQuery,
    onFocusHandles,
  } = useSearchField(props);
  const { classes } = useStyles();

  return (
    <Flex className={clsx(classes.root, className)} direction={"column"}>
      <TextField
        variant={"outlined"}
        className={classes.textField}
        fullWidth
        onFocus={onFocusHandles}
        placeholder={D_JOBS_LIST.searchPlaceholder}
        onChange={handleChangeSearchQuery}
        value={query}
        size={small && "small"}
        InputProps={{
          classes: {
            notchedOutline: classes.notchedOutline,
            input: classes.input,
          },
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: query.length > 0 && (
            <InputAdornment position="end">
              <IconButton
                size={small && "small"}
                aria-label="toggle password visibility"
                onClick={handleClearSearchQuery}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Flex>
  );
};

export default React.memo(SearchField);
