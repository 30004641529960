import { Dictionary } from "@/i18n/dictionary.types";
import companyConnectUA from "@/i18n/dictionary/ua/companyConnect";
import companyConnectEN, {
  DCompanyConnectType,
} from "@/i18n/dictionary/en/companyConnect";
import { t } from "@/i18n/t";

const COMPANY_CONNECT: Dictionary<DCompanyConnectType> = {
  ua: companyConnectUA,
  en: companyConnectEN,
};

export const D_COMPANY_CONNECT = t(COMPANY_CONNECT);
