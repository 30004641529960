import { FC, useRef } from "react";

import Props from "./Props";
import { useStyles } from "./styles";
import Flex from "../Flex";
import { Chip, Typography } from "@mui/material";
import Button from "../Button";
import isEqual from "lodash.isequal";
import { formatSalary } from "../../utils/priceUtils";
import {
  formatExperience,
  getMinEnglishLevelLabel,
} from "../../utils/searchEnginneUtils";
import clsx from "clsx";
import {
  FilterClickable,
  FilterRange,
  FilterType,
} from "../Filter/models/FilterType";
import { D_JOBS_LIST } from "@/i18n/dictionary/jobsList.dictionary";
import { formatThousandsWithSpace } from "@/common/utils/numbersUtils";

const ActiveFilters: FC<Props> = (props: Props) => {
  const {
    itemsQty,
    className,
    context,
    defaultFilter,
    itemsName,
    title,
    ...rest
  } = props;
  const prevQty = useRef(0);
  const { filter, applyFilter, clearFilter } = context;
  const classes = useStyles();
  const withFilters = !isEqual(defaultFilter, {
    ...filter,
    order: defaultFilter.order,
  });

  if (itemsQty !== undefined) {
    prevQty.current = itemsQty;
  }

  const clearAllFilters = () => {
    for (const filterName of Object.keys(filter)) {
      if (filter.hasOwnProperty(filterName)) {
        clearFilter(filterName as keyof typeof filter);
      }
    }
  };

  const renderFilterClickable = (
    key: keyof typeof filter,
    chip: FilterClickable
  ) => (
    <Chip
      className={classes.chip}
      onDelete={() => applyFilter(key, chip)}
      key={chip.id + chip.name}
      label={chip.name}
    />
  );

  const renderFilterRangeLabel = (
    key: "budget" | "experience",
    { max, min }: FilterRange
  ) => {
    let label = "";

    switch (key) {
      case "budget":
        label = formatSalary(min, max);
        break;
      case "experience":
        label = formatExperience(min, max);
        break;
    }

    return label;
  };

  const renderFilterString = (key: keyof typeof filter, value: string) => {
    if (!value || key === "order") {
      return null;
    }

    let label = "";

    switch (key as keyof typeof filter | "recruiter") {
      case "query":
        label = value;
        break;
      case "recruiter":
        label = value;
        break;
    }

    return (
      <Chip
        className={classes.chip}
        onDelete={() => applyFilter(key, "")}
        key={key}
        label={label}
      />
    );
  };

  const renderFilterNumber = (
    key: keyof typeof filter,
    filterValue: number
  ) => {
    if (isEqual(defaultFilter[key], filterValue)) {
      return null;
    }

    return (
      <Chip
        className={classes.chip}
        onDelete={() => applyFilter(key, "")}
        key={key}
        label={getMinEnglishLevelLabel(filterValue)}
      />
    );
  };

  const renderFilterRange = (
    key: keyof typeof filter,
    filterValue: FilterRange
  ) => {
    if (isEqual(defaultFilter[key], filterValue)) {
      return null;
    }

    return (
      <Chip
        className={classes.chip}
        // @ts-ignore
        onDelete={() => applyFilter(key, defaultFilter[key])}
        key={key}
        // @ts-ignore
        label={renderFilterRangeLabel(key, filterValue)}
      />
    );
  };

  const renderFilterChips = (
    filterValue: FilterType,
    key: keyof typeof filter
  ) => {
    if (Array.isArray(filterValue)) {
      return filterValue.map((chip) => renderFilterClickable(key, chip));
    }

    if (typeof filterValue === "string") {
      return renderFilterString(key, filterValue);
    }

    if (typeof filterValue === "number") {
      return renderFilterNumber(key, filterValue);
    }

    return renderFilterRange(key, filterValue);
  };

  const renderAllChips = () => {
    return Object.entries(filter).map(([key, value]) =>
      renderFilterChips(value, key as keyof typeof filter)
    );
  };

  const renderTitle = () => {
    if (title) {
      return title;
    }

    if (withFilters) {
      return D_JOBS_LIST.withFilters(D_JOBS_LIST[itemsName]);
    }

    return D_JOBS_LIST.withoutFilters(D_JOBS_LIST[itemsName]);
  };

  return (
    <Flex
      alignVertical={"center"}
      className={clsx(classes.root, className)}
      {...rest}>
      <Typography>{D_JOBS_LIST.found}</Typography>
      <Typography className={classes.jobsQty}>
        {formatThousandsWithSpace(itemsQty || prevQty.current)}
      </Typography>
      <Typography>{renderTitle()}</Typography>
      {renderAllChips()}
      {withFilters && (
        <Button onClick={clearAllFilters} variant={"text"}>
          {D_JOBS_LIST.clearAll}
        </Button>
      )}
    </Flex>
  );
};

export default ActiveFilters;
