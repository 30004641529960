interface EntityWithOrder {
  order?: number;
}

export function sortByOrder<T extends EntityWithOrder>(a: T, b: T): number {
  if (!a.order && !b.order) {
    return 0;
  } else if (!a.order) {
    return 1;
  } else if (!b.order) {
    return -1;
  } else {
    return a.order - b.order;
  }
}

export function cleanTypenameFields<T>(obj: any): T {
  if (Array.isArray(obj)) {
    return obj.map(cleanTypenameFields) as T;
  } else if (obj !== null && typeof obj === "object") {
    const { __typename, ...rest } = obj;
    return Object.entries(rest).reduce((acc: any, [key, value]) => {
      acc[key] = cleanTypenameFields(value);
      return acc;
    }, {}) as T;
  }
  return obj;
}
