import { Dictionary } from "@/i18n/dictionary.types";
import ua from "@/i18n/dictionary/ua";
import es from "@/i18n/dictionary/es";
import en, { ENType } from "@/i18n/dictionary/en";
import { t } from "@/i18n/t";

const DICTIONARY: Dictionary<ENType> = {
  ua,
  en,
  es,
};

const dictionary = t(DICTIONARY);

export default dictionary;
