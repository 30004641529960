import { ClassNameMap } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { AppTheme } from "@/theme";

export type ClassNames = "root" | "chip" | "jobsQty";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: AppTheme) =>
    createStyles({
      root: {
        margin: theme.spacing(0, 0, 1.5),
        flexWrap: "wrap",
        minHeight: 36.5,
      },
      chip: {
        margin: theme.spacing(0.5),
      },
      jobsQty: {
        fontWeight: 600,
        margin: theme.spacing(0, 0.5),
      },
    })
);
