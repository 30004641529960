import { getLocality, replacePathnameLocality } from "@/common/utils/navUtils";

export function useFooter() {
  const getLocalityLinkName = () => {
    return getLocality() === "ua" ? "English" : "Українська";
  };

  const getLocalityUrl = () => {
    return replacePathnameLocality(
      window.location.pathname,
      getLocality() === "ua" ? "en" : "ua"
    );
  };

  return {
    localityLinkName: getLocalityLinkName(),
    localityUrl: getLocalityUrl(),
  };
}
