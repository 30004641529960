import React, { FC } from "react";

import ContentLoader from "react-content-loader";
import { useIsMobile } from "@/common/hooks/useIsMobile";

const ProfileDropdownIconLoader: FC = () => {
  const isMobile = useIsMobile();

  return isMobile ? (
    <ContentLoader
      speed={2}
      width={40}
      height={40}
      viewBox="0 0 40 40"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb">
      <circle cx="20" cy="20" r="20" />
    </ContentLoader>
  ) : (
    <ContentLoader
      speed={2}
      width={230}
      height={40}
      viewBox="0 0 230 40"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb">
      <rect x="49" y="9" rx="3" ry="3" width="103" height="8" />
      <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
      <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
      <circle cx="22" cy="20" r="20" />
      <rect x="105" y="120" rx="0" ry="0" width="102" height="35" />
      <rect x="48" y="32" rx="3" ry="3" width="161" height="6" />
    </ContentLoader>
  );
};

export default ProfileDropdownIconLoader;
