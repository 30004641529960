import { EnglishLevel } from "../models/EnglishLevel";
import { RecommendationStatus } from "../models/RecommendationStatus";
import dictionary from "@/i18n/dictionary";
import { RecommendationRejectionReason } from "../models/RecommendationRejectionReason";
import { Skill } from "../models/Skill";
import { CompanyStatus } from "../models/CompanyStatus";
import { VacancyStatus } from "../models/VacancyStatus";
import { VacancyClosingReason } from "../models/VacancyClosingReason";
import dayjs from "dayjs";
import { CompanyType } from "../models/CompanyType";
import { capitalize } from "@mui/material";
import { D_RECOMMENDATION_STATUS } from "@/i18n/dictionary/recommendationStatus";
import { D_COMPANIES_GRID } from "@/i18n/dictionary/companiesGrid.dictionary";
import { D_VACANCY_VIEW } from "@/i18n/dictionary/vacancyView.dictionary";
import { D_COMPANIES_TABLE } from "@/i18n/dictionary/companiesTable.dictionary";
import { D_RECOMMENDATION_STATUS_DIALOG } from "@/i18n/dictionary/recommendationStatusDialog.dictionary";
import { D_RECOMMENDATION_REJECTION_REASON_SHORT } from "@/i18n/dictionary/recommendationRejectionReasonShort.dictionary";
import { D_VACANCY_STATUS_DIALOG } from "@/i18n/dictionary/vacancyStatusDialog.dictionary";
import { MIN_ENGLISH_LEVEL_OPTIONS } from "@/config/EnglishLevelOptions";

export function getEmployment(
  partTime: boolean,
  fullTime: boolean,
  shortFormat = false
) {
  if (partTime && fullTime) {
    return shortFormat ? "Part/Full-Time" : "Part-Time, Full-Time";
  } else if (partTime) {
    return "Part-Time";
  } else {
    return "Full-Time";
  }
}

export function formatExperience(
  minExperience: number,
  maxExperience?: number
) {
  if (maxExperience) {
    return `${minExperience} - ${maxExperience} ${dictionary.experience.years}`;
  }

  return `${minExperience} ${
    minExperience > 1 ? dictionary.experience.years : dictionary.experience.year
  }`;
}

export function formatJobsQty(qty: number) {
  return `${qty} ${D_COMPANIES_GRID.jobs}`;
}

export function formatEmployeesQty(qty?: number | null) {
  return (qty || 0).toString();
}

export function formatProjectsQty(qty?: number | null) {
  return (qty || 0).toString();
}

export function formatFoundationDate(foundationDate?: Date | number | null) {
  return foundationDate ? dayjs(new Date()).diff(foundationDate, "years") : 0;
}

export function formatCountriesQty(countriesQty?: number | null) {
  return countriesQty ? Number(countriesQty) : 0;
}

export function formatCompanyType(companyType?: CompanyType | null) {
  return companyType ? capitalize(companyType.toLocaleLowerCase()) : "";
}

export function formatCompanyOffices(companyOffices?: string | null) {
  return companyOffices || "";
}

export function formatEnglishLevel(
  englishLevelEnumValue: EnglishLevel | undefined | number | null
) {
  let englishLevel = "Unknown";

  switch (englishLevelEnumValue) {
    case EnglishLevel.ELEMENTARY:
      {
        englishLevel = "Elementary";
      }
      break;
    case EnglishLevel.PRE_INTERMEDIATE: {
      englishLevel = "Pre-Intermediate";
      break;
    }
    case EnglishLevel.INTERMEDIATE: {
      englishLevel = "Intermediate";
      break;
    }
    case EnglishLevel.UPPER_INTERMEDIATE: {
      englishLevel = "Upper-Intermediate";
      break;
    }
    case EnglishLevel.ADVANCED: {
      englishLevel = "Advanced";
      break;
    }
    case EnglishLevel.NATIVE: {
      englishLevel = "Native";
      break;
    }
    case EnglishLevel.NOT_SPECIFIED: {
      englishLevel = dictionary.englishLevel.notSpecified;
      break;
    }
  }

  return englishLevel;
}

export const getMinEnglishLevelLabel = (value: number) => {
  const englishLevel = MIN_ENGLISH_LEVEL_OPTIONS.find((option) => {
    if (value === 0) {
      return +option.value === 6;
    }

    return +option.value === value - 1;
  });

  return englishLevel?.label;
};

export const getMinEnglishLevelValue = (value: number) => {
  const englishLevel = MIN_ENGLISH_LEVEL_OPTIONS.find((option) => {
    if (value === 0) {
      return +option.value === 6;
    }

    return +option.value === value - 1;
  });

  return englishLevel && +englishLevel.value;
};

export function formatVacancyStatus(status: VacancyStatus) {
  return D_VACANCY_VIEW[status];
}

export function formatRecommendationStatus(status: RecommendationStatus) {
  return D_RECOMMENDATION_STATUS[status];
}

export function formatCompanyStatus(status: CompanyStatus) {
  return D_COMPANIES_TABLE[status];
}

export function formatRecommendationRejectionReason(
  reason: RecommendationRejectionReason
) {
  return D_RECOMMENDATION_STATUS_DIALOG[reason];
}

export function formatRecommendationRejectionReasonShort(
  reason: RecommendationRejectionReason
) {
  return D_RECOMMENDATION_REJECTION_REASON_SHORT[reason];
}

export function formatVacancyClosingReason(reason: VacancyClosingReason) {
  return D_VACANCY_STATUS_DIALOG[reason];
}

export function formatSkills(
  skills: Skill[] | null,
  separator?: string,
  noSpaces?: boolean
) {
  return skills
    ?.map(({ name }) => (noSpaces ? name.split(" ").join("") : name))
    .join(separator || ", ");
}
