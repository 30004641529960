import { Dictionary } from "@/i18n/dictionary.types";
import vacancyViewUA from "@/i18n/dictionary/ua/vacancyView";
import vacancyViewEN, {
  DVacancyViewType,
} from "@/i18n/dictionary/en/vacancyView";
import { t } from "@/i18n/t";

const VACANCY_VIEW: Dictionary<DVacancyViewType> = {
  ua: vacancyViewUA,
  en: vacancyViewEN,
};

export const D_VACANCY_VIEW = t(VACANCY_VIEW);
