const header = Object.freeze({
  offlineMode:
    "Ви використовуєте сайт в офлайн режимі. Деякі функції та дані можуть бути недоступними або неактуальними",
  publishVacancy: "Розмістити Вакансію",
  publishVacancyPermissions:
    "Щоб розмістити вакансію, будь ласка, увійдіть або зареєструйте профіль компанії",
  publishCandidate: "Розмістити Кандидата",
  vacancies: "Вакансії",
  publishVacancySubtitle:
    "Заповніть форму нижче, щоб зареєструвати компанію і " +
    "розмістити вакансію. Якщо вже зареєстровані, ",
  createCompany: "Додати Компанію",
  subscribeForVacancies: "Підписатися На Вакансії",
  subscribeForCandidates: "Підписатися На Кандидатів",
  joinTelegram: "Телеграм",
  aboutUs: "Про Нас",
  faq: "FAQ",
  perks: "Matchit perks",
  login: "Увійти",
  candidates: "Кандидати",
  register: "Зареєструватися",
  companies: "Компанії",
  logout: "Вийти",
  dashboard: "Інформаційна Панель",
  allVacancies: "Усі вакансії",
  newRecommendationsCountTooltip: "Кількість нових рекомендацій",
  createCompanyAlertInfo:
    "Ви увійшли в персональний акаунт. Щоб скористатися цією функцією потрібно створити профіль компанії",
});

export default header;
