import React, { FC, useState } from "react";

import Props, { DropdownListItem } from "./Props";
import { useStyles } from "./styles";
import Dropdown from "../Dropdown";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import clsx from "clsx";
import { ReactComponent as ChevronRightIcon } from "../../icons/chevron-right.svg";
import Flex from "../Flex";
import { Paper } from "../Paper";

const DropdownList: FC<Props> = (props: Props) => {
  const { icon, activeIcon, list } = props;
  const [visible, setVisible] = useState(false);

  const { classes } = useStyles();

  const renderListItem = ({ name, action }: DropdownListItem) => (
    <ListItemButton className={classes.listItem} key={name} onClick={action}>
      <ListItemText
        classes={{ primary: classes.listItemText }}
        primary={name}
      />
      <ListItemIcon className={classes.listItemIcon}>
        <ChevronRightIcon />
      </ListItemIcon>
    </ListItemButton>
  );

  const switchBodyScroll = (isVisible: boolean) => {
    const body = document.getElementById("body");

    if (!body) {
      return;
    }

    if (isVisible) {
      body.classList.add("modal-visible");
    } else {
      body.classList.remove("modal-visible");
    }

    setVisible(isVisible);
  };

  const renderIcon = () => (
    <Flex
      alignHorizontal={"center"}
      alignVertical={"center"}
      className={clsx(classes.iconContainer, visible && "active-icon")}>
      {visible ? activeIcon : icon}
    </Flex>
  );

  return (
    <Dropdown
      icon={renderIcon()}
      className={classes.root}
      onToggle={switchBodyScroll}>
      <div className={classes.background}>
        <Paper className={classes.paper}>{list.map(renderListItem)}</Paper>
      </div>
    </Dropdown>
  );
};

export default DropdownList;
