import { ClassNameMap } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { AppTheme } from "@/theme";

export type ClassNames = "root" | "title" | "clearButton";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: AppTheme) =>
    createStyles({
      root: {},
      title: {
        fontSize: theme.typography.pxToRem(13),
        fontWeight: 600,
      },
      clearButton: {
        marginLeft: "auto",
        paddingLeft: theme.spacing(1),
      },
    })
);
