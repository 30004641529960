import React, { FC } from "react";
import Props from "./Props";
import { useStyles } from "./styles";
import Typography from "@mui/material/Typography";
import Flex from "../Flex";
import clsx from "clsx";

const IconString: FC<Props> = (props: Props) => {
  const { icon, string, classes } = props;

  const { classes: defaultClasses } = useStyles();

  return (
    <Flex
      className={clsx(defaultClasses.root, classes?.root)}
      alignVertical={"center"}>
      <div className={clsx(classes?.iconWrapper, defaultClasses.iconWrapper)}>
        {icon}
      </div>
      <Typography className={clsx(defaultClasses.text, classes?.text)}>
        {string}
      </Typography>
    </Flex>
  );
};

export default IconString;
