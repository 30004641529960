import React, { FC, useContext } from "react";

import { JobsFilterContext } from "@/pages/public/Jobs/components/JobsFilter/components/JobsFilterContextProvider/JobsFilterContext";
import Props from "./Props";
import Filter from "@/common/components/Filter";
import JobsCompanyFilter from "@/common/components/Filter/components/JobsCompanyFilter/JobsCompanyFilter";
import JobsCountryFilter from "@/common/components/Filter/components/JobsCountryFilter";
import StateFilter from "@/common/components/Filter/components/JobsStateFilter";
import JobsCityFilter from "@/common/components/Filter/components/JobsCityFilter";
import JobsTypeFilter from "@/common/components/Filter/components/JobsTypeFilter";
import { isOnePage } from "@/config";

const JobsFilter: FC<Props> = (props: Props) => {
  const context = useContext(JobsFilterContext);
  const { exclude, isHideFilter, minEnglishLevel, isUsePrivateLayout } = props;

  return (
    <Filter isHideFilter={isHideFilter}>
      {!isOnePage && <JobsCountryFilter context={context} />}
      <StateFilter context={context} />
      <JobsCityFilter context={context} />
      <JobsCompanyFilter context={context} />
      {/*<JobsCategoryFilter context={context} />*/}
      <JobsTypeFilter context={context} />
    </Filter>
  );
};

export default React.memo(
  JobsFilter,
  (prevProps, newProps) =>
    prevProps.exclude?.toString() === newProps.exclude?.toString()
);
