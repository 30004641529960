const recommendationStatusDialog = Object.freeze({
  title: "Змінити статус рекомендації",
  errorUpdateRecommendationStatus:
    "Сталася помилка під час оновлення статуса рекомендації. Будь-ласка спробуйте пізніше",
  successUpdateRecommendationStatus: "Статус рекомендації успішно оновлено",
  statusSelectLabel: "Виберіть статус",
  reasonSelectLabel: "Виберіть причину відмови",
  formFieldComment: "Коментар",
  formFieldReason: "Причина",
  formFieldTimezoneCompany: "Часовий пояс компанії",
  formFieldTimezoneCandidate: "Часовий пояс кандидата",
  formFieldInterviewDate: "Дата співбесіди за вашим місцевим часом",
  formFieldSalary: "Зарплата кандидата",
  CANT_CONTACT: "Не вдається зв'язатися з кандидатом",
  CANDIDATE_REFUSED: "Кандидат відмовився від вакансії",
  CANDIDATE_NOT_MEET_REQUIREMENTS: "Кандидат не відповідає вимогам",
  CANDIDATE_REFUSED_AFTER_INTERVIEW:
    "Кандидат відмовився від вакансії після співбесіди",
  CANDIDATE_REFUSED_AFTER_OFFER:
    "Кандидат відмовлявся від вакансії після офера",
  CANDIDATE_ACCEPT_ANOTHER_OFFER: "Кандидат прийняв інший офер",
  CANDIDATE_ALREADY_REVIEWED: "Кандидат уже розглядався компанією",
  VACANCY_NOT_ACTUAL: "Вакансія більше не актуальна",
  CANDIDATE_RECOMMENDED_BY_ANOTHER_RECRUITER:
    "Кандидата рекомендував інший рекрутер",
  CANDIDATE_LOCATION_NOT_MATCH_VACANCY:
    "Локація кандидата не співпадає з вакансією",
  CANDIDATE_EXPERIENCE_NOT_MEET_VACANCY:
    "Досвід канидата не віповідає вимогам вакансії",
  CANDIDATE_OFTEN_CHANGES_JOB: "Кандидат надто часто змінює роботу",
  CANDIDATE_SALARY_NOT_MATCH_BUDGET:
    "Зарплата кандидата поза бюджетом вакансії",
});

export default recommendationStatusDialog;
