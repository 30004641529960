const recommendatorProfile = Object.freeze({
  pageTitle: "Налаштування Профілю",
  deleteProfile: "Видалити обліковий запис",
  deleteProfileNote:
    "Ми робимо все можливе, щоб подарувати вам чудові враження - нам буде сумно бачити, як ви покидаєте нас. ",
  deleteProfileMessage:
    "Ця дія назавжди видалить ваш обліковий запис. Усі дані, історія рекомендацій буде повністю видалена. Ви хочете підтвердити цю дію?",
  deleteProfileTitle: "Ви впевнені, що хочете видалити обліковий запис?",
  settingsUpdateSuccess: "Налаштування профілю успішно оновлено",
  settingsUpdateError: "Налаштування профілю не оновлено",
  avatarChangeSuccess: "Ваше зображення профілю було успішно змінено",
  avatarChangeError: "Ваше зображення профілю не було змінено",
  avatarDeleteSuccess: "Ваше зображення профілю було успішно видалено",
  avatarDeleteError: "Ваше зображення профілю не було видалено",
  changeProfileSuccess: "Інформацію вашого профілю успішно оновлено",
  changeProfileError: "Інформація вашого профілю не оновлена...",
  referralCodeCopyMessage: (code?: string) =>
    `Реферальне посилання з кодом ${
      code || ""
    } скопійовано у ваш буфер обміну. Готове щоб поділитися з друзями 😉`,
  referralCodeDescription:
    "Використовуйте цей код, щоб запросити людей на платформу та отримати $10 бонусів за кожну їх успішну рекомендацію",
  updateProfileInformation: "Зберегти Зміни",
  changePassword: "Змінити пароль",
  changePasswordSuccess: "Ваш пароль успішно змінено",
  changePasswordError: (error?: string) =>
    error === "INCORRECT_OLD_PASSWORD"
      ? "Недійсний старий пароль"
      : "Ваш пароль не змінено",
  firstName: "Ім'я",
  lastName: "Прізвище",
  email: "Електронна пошта",
  contacts: "Контакти",
  candidateProcessing: "Анонімний акаунт",
  candidateProcessingDescription:
    "Увімкніть, якщо ви не готові комунікувати з кандидатами самостійно. Ми зробимо все за вас 😉",
  invisibleMode: 'Режим "невидимки"',
  anonymousModeDescription:
    'Увімкніть режим "невидимки", щоб приховати своє ім’я та фотографію для інших',
  emailNotification: "Сповіщення електронною поштою",
  pushNotification: "Спливаючі повідомлення",
  pushNotificationDescription:
    "Увімкніть push сповіщення щоб отримувати оновлення з вашого браузера",
  emailNotificationDescription:
    "Увімкніть, щоб отримувати сповіщення про оновлення електронною поштою",
  contactsCandidate: "Контакти",
  referralCode: "Реферальний Код",
  editProfileSubtitle: "Редагувати профіль",
  changePasswordSubtitle: "Змінити пароль",
  additionalInfoSubtitle: "Додаткові налаштування",
  changeAvatarError:
    "Зараз не вдається змінити ваше фото. Будь-ласка спробуйте пізніше",
  showCv: "Переглянути поточне резюме",
  uploadCv: "Завантажте резюме",
  uploadNewCv: "Завантажте нове резюме",
  errorUploadCv: "Не вдалось оновити резюме. Будь ласка, спробуйте пізніше",
  successUploadCv: "Ваше резюме успішно оновлено!",
});

export default recommendatorProfile;
