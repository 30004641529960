const vacancyStatusDialog = Object.freeze({
  title: "Змінити статус вакансії",
  errorUpdateVacancyStatus:
    "Під час оновлення статусу вакансій сталася помилка. Будь-ласка, спробуйте пізніше",
  successUpdateVacancyStatus: "Статус вакансії успішно оновлено",
  statusSelectLabel: "Виберіть статус",
  reasonSelectLabel: "Виберіть причину",
  formFieldComment: "Коментар",
  formFieldReason: "Причина",
  CANDIDATE_HIRED: "Кандидат успішно прийнятий на роботу",
  CLOSED_BY_COMPANY: "Вакансія закрита компанією",
});

export default vacancyStatusDialog;
