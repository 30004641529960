const companiesTable = Object.freeze({
  edit: "Edit company",
  delete: "Delete company",
  deleteCompanyMessage:
    "Do you really wanna to delete this company. All linked vacancies and recommendations will be permanently deleted too",
  deleteCompanyError: "The company hasn't been deleted",
  deleteCompanySuccess: "The company has been deleted successfully",
  deleteCompanyErrorNoCompany: "Please, select company to delete",
  logo: "",
  email: "Email",
  createdAt: "Created",
  updatedAt: "Updated",
  name: "Name",
  actions: "",
  ACTIVE: "Active",
  DISABLED: "Disabled",
  emptyTitle: "Companies not found",
  emptySubtitle:
    "There are no any companies matched for your filters at the moment.",
  clearFiltersButton: "Clear all filters",
});

export default companiesTable;

export type DCompaniesTableType = Record<keyof typeof companiesTable, string>;
