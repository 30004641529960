import { FC } from "react";
import { useStyles } from "./ProfileDropdownContent.styles";
import Flex from "@/common/components/Flex";
import { Avatar } from "@/common/components/Avatar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { ReactComponent as CopyIcon } from "@/common/icons/profileDropdown/copy.svg";
import { ReactComponent as CopyIconActive } from "@/common/icons/profileDropdown/copy-active.svg";
import ProfileDropdownActionsList from "@/sections/ProfileDropdown/ProfileDropdownContent/ProfileDropdownActionsList";
import Loader from "@/common/components/loaders/Loader";
import { ProfileDropdownContentProps } from "@/sections/ProfileDropdown/ProfileDropdownContent/ProfileDropdownContent.types";
import clsx from "clsx";
import { Paper } from "@/common/components/Paper";
import { IS_SHOW_REFERRAL_CODE } from "@/config";
import { D_PROFILE_DROPDOWN } from "@/i18n/dictionary/profileDropdown.dictionary";

const ProfileDropdownContent: FC<ProfileDropdownContentProps> = (props) => {
  const {
    avatar,
    userName,
    userEmail,
    referralCode,
    handleClickCopyReferralCode,
    mainActions,
    actions,
    loading,
  } = props;

  const { classes } = useStyles();

  return (
    <Paper className={classes.dropdownContent}>
      <Flex direction={"column"}>
        <Flex direction={"column"} alignHorizontal={"center"}>
          <Avatar size={100} src={avatar} />
          <Typography
            className={clsx(classes.dropdownUserInfo, classes.dropdownName)}
            data-testid={"user-profile-dropdown-firstname"}>
            {userName}
          </Typography>
          <Typography
            className={clsx(classes.dropdownUserInfo, classes.dropdownEmail)}
            data-testid={"user-profile-dropdown-email"}>
            {userEmail}
          </Typography>
        </Flex>
        {IS_SHOW_REFERRAL_CODE && (
          <TextField
            fullWidth
            className={classes.referralCode}
            name={"referralCode"}
            title={D_PROFILE_DROPDOWN.referralCode}
            type={"text"}
            label={D_PROFILE_DROPDOWN.referralCode}
            value={referralCode}
            InputProps={{
              inputProps: {
                "data-testid": "user-profile-dropdown-referral-code",
              },
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.copyContainer}
                  onClick={handleClickCopyReferralCode}>
                  <CopyIcon className={"copy"} />
                  <CopyIconActive className={"copy-active"} />
                </InputAdornment>
              ),
            }}
          />
        )}
        <ProfileDropdownActionsList
          mainActions={mainActions}
          additionalActions={actions}
        />
        <Loader withMask visible={loading} />
      </Flex>
    </Paper>
  );
};

export default ProfileDropdownContent;
