import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";

export const useStyles = makeStyles()((theme: AppTheme) => ({
  headerInfoContainer: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
  },
  headerInfoContainerName: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(15),
  },
  headerInfoContainerEmail: {
    fontSize: theme.typography.pxToRem(13),
    color: "#A2A9B0",
  },
}));
