const companyConnect = Object.freeze({
  signUpLink: "create your company profile ",
  signUpMessage: "by yourself to publish vacancies for free.",
  email: "Email",
  comment: "Comment",
  messageSuccess:
    "Your request has been sent successfully. We will contact you soon!😉",
  messageError: "Your request hasn't been sent... Please, try again later",
  title: "Connect your company",
  subtitle:
    "If you want to publish vacancies with bonus, please leave your email and we will contact you soon! Or ",
  acceptButtonText: "Send",
});

export default companyConnect;

export type DCompanyConnectType = Record<keyof typeof companyConnect, string>;
