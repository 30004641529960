import { FC } from "react";
import { useStyles } from "./Avatar.styles";
import { AvatarProps } from "./Avatar.types";
import clsx from "clsx";

export const Avatar: FC<AvatarProps> = (props) => {
  const { size, src, className, children, logo, alt } = props;
  const { classes } = useStyles();

  const image = src ? `url(${src})` : "url(/images/profile-placeholder.svg)";
  const isShowChildren = children && !src;

  return (
    <>
      {logo ? (
        <div
          className={clsx(classes.rootLogo, className)}
          style={{
            width: size,
            height: size,
          }}>
          <img
            style={{
              width: size,
            }}
            className={classes.logo}
            src={src || "/images/default-placeholder.png"}
            alt={alt ? alt : "logo"}
            title={alt}
          />
        </div>
      ) : (
        <div
          className={clsx(
            classes.rootAvatar,
            className,
            isShowChildren && classes.withChildren
          )}
          style={{
            width: size,
            height: size,
            backgroundImage: isShowChildren ? "" : image,
          }}>
          {isShowChildren && children}
        </div>
      )}
    </>
  );
};
