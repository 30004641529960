import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppRoute, getRouteWithSlash } from "@/router/AppRoute";
import { AuthContext } from "@/auth/components/AuthContextProvider";
import { useLazyQuery } from "@apollo/client";
import { GET_USER_COMPANY_ID, GetUserCompanyIdData } from "./gql";
import { useIsAuthorised } from "@/auth/hooks/useIsAuthorised";
import { useIsCompany } from "@/auth/hooks/useIsCompany";
import { useDialog } from "@/common/hooks/useDialog";
import { useSearchParams } from "@/common/hooks/useSearchParams";
import { SearchKeys } from "@/common/models/SearchKeys";
import { Role } from "@/generated/types";
import { useRenderDialog } from "@/common/hooks/useRenderDialog";
import { useRenderCompanyEditDialog } from "@/common/hooks/useRenderCompanyEditDialog";
import { useParsedCompanyLazyQuery } from "@/common/components/CompanyWithParsedVacanciesDialog/CompanyWithParsedVacanciesDialog.operations.generated";
import { D_REGISTRATION_DIALOG } from "@/i18n/dictionary/registrationDialog.dictionary";

const excludedPagesForCompanyDialog = [
  getRouteWithSlash(AppRoute.RELEVANCE_CHECKER_COMPANY),
];

export function useHeader() {
  const [companyEditDialogVisible, setCompanyEditDialogVisible] =
    useState(false);
  const isAuthorized = useIsAuthorised();
  const isCompany = useIsCompany();
  const { logout, showLoginDialog } = useContext(AuthContext);
  const history = useHistory<{ role: string } | undefined>();
  const { getSearchParams, clearSearchParams } = useSearchParams();
  const {
    hide: hideRegisterDialog,
    show: registerDialogShow,
    visible: registerDialogVisible,
  } = useDialog();
  const {
    hide: hideCompanyWithParsedVacanciesDialog,
    show: showCompanyWithParsedVacanciesDialog,
    visible: visibleCompanyWithParsedVacanciesDialog,
  } = useDialog();

  const renderRegisterDialog = useRenderDialog({
    dialogName: "registerDialog",
    dialogProps: {
      title: D_REGISTRATION_DIALOG.title,
      open: registerDialogVisible,
      onClose: hideRegisterDialog,
    },
    visible: registerDialogVisible,
  });

  const [getUserCompanyId, { data }] = useLazyQuery<GetUserCompanyIdData>(
    GET_USER_COMPANY_ID,
    { fetchPolicy: "cache-and-network" }
  );

  const [getParsedCompany, { data: parsedCompanyData }] =
    useParsedCompanyLazyQuery();

  const auth = getSearchParams(SearchKeys.AUTH);
  const companyId = data?.getUser.companyId;
  const parsedCompany = parsedCompanyData?.parsedCompany;

  useEffect(() => {
    if (!auth) {
      return;
    }

    switch (auth) {
      case "login":
        showLoginDialog(AppRoute.RECOMMENDATOR_SIGN_UP);
        break;

      case "register":
        registerDialogShow();
        break;

      default:
        return;
    }

    clearSearchParams();
  }, [auth, clearSearchParams, registerDialogShow, showLoginDialog]);

  useEffect(() => {
    if (isAuthorized) {
      getUserCompanyId().then();
    }
  }, [getUserCompanyId, isAuthorized]);

  const actionsAfterCompanyRegistration = useCallback(async () => {
    try {
      const { data } = await getParsedCompany({
        fetchPolicy: "cache-and-network",
      });
      if (data?.parsedCompany) {
        showCompanyWithParsedVacanciesDialog();
        history.replace({
          state: undefined,
        });
      } else {
        showCompanyEditDialog();
      }
    } catch (error) {
      showCompanyEditDialog();
    }
  }, [getParsedCompany, history, showCompanyWithParsedVacanciesDialog]);

  useEffect(() => {
    const role = history.location.state?.role;
    const { pathname } = history.location;

    if (
      !isAuthorized ||
      !role ||
      role !== Role.Company ||
      excludedPagesForCompanyDialog.includes(pathname)
    ) {
      return;
    }

    actionsAfterCompanyRegistration();
  }, [isAuthorized, actionsAfterCompanyRegistration, history]);

  const handleClickPublishVacancy = () => {
    history.push({ pathname: getRouteWithSlash(AppRoute.COMPANY_REGISTER) });
  };

  const showCompanyEditDialog = () => {
    setCompanyEditDialogVisible(true);
  };

  const hideCompanyEditDialog = () => {
    setCompanyEditDialogVisible(false);
  };

  const handlePressSettingsButton = () => {
    history.push({
      pathname: getRouteWithSlash(AppRoute.USER_PROFILE_SETTINGS),
    });
  };

  const handlePressLogoutButton = async () => {
    logout();
  };

  const showRegisterDialog = useCallback(
    (e?: any) => {
      if (e) {
        e.preventDefault();
      }

      registerDialogShow();
    },
    [registerDialogShow]
  );

  const {
    show: showPublishVacancyDialog,
    visible: publishVacancyDialogVisible,
    hide: hidePublishVacancyDialog,
  } = useDialog();

  const renderCompanyEditDialog = useRenderCompanyEditDialog({
    visible: companyEditDialogVisible,
    onClose: hideCompanyEditDialog,
    companyId: String(companyId),
    showAdditionalDialog: showPublishVacancyDialog,
  });

  const renderVacancyCreateDialog = useRenderDialog({
    dialogName: "vacancyCreate",
    dialogProps: {
      visible: publishVacancyDialogVisible,
      onClose: hidePublishVacancyDialog,
    },
    visible: publishVacancyDialogVisible,
  });

  const companyWithParsedVacanciesDialog = useRenderDialog({
    dialogName: "companyWithParsedVacancies",
    dialogProps: {
      visible: visibleCompanyWithParsedVacanciesDialog,
      onClose: hideCompanyWithParsedVacanciesDialog,
      parsedCompany,
    },
    visible: visibleCompanyWithParsedVacanciesDialog,
  });

  return {
    showLoginDialog,
    isAuthorized,
    handlePressLogoutButton,
    handleClickPublishVacancy,
    handlePressSettingsButton,
    companyEditDialogVisible,
    hideCompanyEditDialog,
    showCompanyEditDialog,
    isCompany,
    showRegisterDialog,
    companyId,
    pagePathName: location.pathname,
    renderVacancyCreateDialog,
    renderCompanyEditDialog,
    renderRegisterDialog,
    companyWithParsedVacanciesDialog,
    showPublishVacancyDialog,
  };
}
