import { Dictionary } from "@/i18n/dictionary.types";
import recommendationRejectionReasonShortUA from "@/i18n/dictionary/ua/recommendationRejectionReasonShort";
import recommendationRejectionReasonShortEN, {
  DRecommendationRejectionReasonShortType,
} from "@/i18n/dictionary/en/recommendationRejectionReasonShort";
import { t } from "@/i18n/t";

const RECOMMENDATION_REJECTION_REASON_SHORT: Dictionary<DRecommendationRejectionReasonShortType> =
  {
    ua: recommendationRejectionReasonShortUA,
    en: recommendationRejectionReasonShortEN,
  };

export const D_RECOMMENDATION_REJECTION_REASON_SHORT = t(
  RECOMMENDATION_REJECTION_REASON_SHORT
);
