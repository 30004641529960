import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";

const PROFILE_DROPDOWN_ICON_SIZE = 40;

export const useStyles = makeStyles()((theme: AppTheme) => ({
  dropdownContent: {
    zIndex: 10,
    right: 0,
    top: PROFILE_DROPDOWN_ICON_SIZE + Number(theme.spacing(2)),
    position: "absolute",
    borderRadius: 4,
    padding: theme.spacing(4, 2),
    width: 310,
  },
  dropdownUserInfo: {
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "center",
  },
  dropdownName: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(18),
    marginTop: theme.spacing(1.5),
  },
  dropdownEmail: {
    fontSize: theme.typography.pxToRem(17),
    color: "#A2A9B0",
  },
  referralCode: {
    margin: theme.spacing(3, 0, 2),
  },
  copyContainer: {
    cursor: "pointer",
    "& > .copy-active": {
      display: "none",
    },
    "&:hover > .copy-active": {
      display: "block",
    },
    "&:hover > .copy": {
      display: "none",
    },
  },
}));
