import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";

export const useStyles = makeStyles()((theme: AppTheme) => ({
  root: {},
  link: {
    paddingLeft: "16px !important",
    paddingRight: "16px !important",
    position: "relative",
    color: theme.palette.text.primary + "!important",
    fontSize: "14px !important",
    paddingTop: "10px !important",
    paddingBottom: "10px !important",
  },
}));
