import { formatBonus } from "@/common/utils/priceUtils";

const vacancyCard = Object.freeze({
  bonus: (bonus: number) => `Ваш бонус ${formatBonus(bonus, "")}`,
  bonusTooltip: (bonus: number | null, vacancyBonusPercent: number) => {
    const bonusType = "бонус за рекомендацію";
    const roleType = "кандидата, якого ви порекомендуєте";
    const salaryType = "зарплати кандидата";

    return `Ви отримаєте ${bonusType}${
      vacancyBonusPercent > 0
        ? ` , який дорівнює ${vacancyBonusPercent}% від ${salaryType}`
        : ""
    }${
      bonus === null
        ? ""
        : ". Для даної вакансії це до " + formatBonus(bonus, "")
    },  якщо компанія найме ${roleType}`;
  },
  bonusValue: (percent: number) => `${percent}%\xa0ЗП`,
  noBonus: "Без бонуса",
  noBonusTooltip: "Бонус за рекомендацію кандидата на дану вакансію відсутній",
  uploadCv: "Надіслати\xa0CV",
  message: "Повідомлення",
  messageError:
    "Повідомлення на разі недоступні. Будь ласка, спробуйте пізніше",
  updatedAt: "Опубліковано",
  telegramButton: (companyName: string) =>
    "Дізнавайтесь про нові вакансії від \n" +
    companyName +
    " у нашому Telegram каналі",
  chipDescription: "+ ще {{value}}",
});

export default vacancyCard;
