const profileDropdown = Object.freeze({
  referralCode: "Referral code",
  home: "Home",
  dashboard: "Dashboard",
  chat: "Messaging",
  profileSettings: "Profile settings",
  companyProfileSettings: "My company",
  logout: "Logout",
  leftSwitch: "Recruiter",
  rightSwitch: "Candidate",
  leftSwitchTooltip: "Choose if you want to recommend candidates for bonuses",
  rightSwitchTooltip: "Choose if you are looking for a job for yourself",
});

export default profileDropdown;

export type DProfileDropdownType = Record<keyof typeof profileDropdown, string>;
