const companiesGrid = Object.freeze({
  emptyTitle: "Companies not found",
  emptySubtitle:
    "There are no any vacancies matched for your filters at the moment.",
  createCompany: "Create Company",
  jobs: "jobs",
  clearFiltersButton: "Clear all filters",
});

export default companiesGrid;

export type DCompaniesGridType = Record<keyof typeof companiesGrid, string>;
