const profileDropdown = Object.freeze({
  referralCode: "Реферальний код",
  home: "Головна",
  dashboard: "Інформаційна Панель",
  chat: "Повідомлення",
  profileSettings: "Налаштування профілю",
  companyProfileSettings: "Моя компанія",
  logout: "Вийти",
  leftSwitch: "Рекрутер",
  rightSwitch: "Кандидат",
  leftSwitchTooltip: "Оберіть, якщо бажаєте рекомендувати кандидатів за бонуси",
  rightSwitchTooltip: "Оберіть, якщо шукаєте роботу для себе",
});

export default profileDropdown;
