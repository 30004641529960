import { SelectOptionItem } from "../StyledSelectPicker/Props";
import React, { useCallback, useState } from "react";
import { getLocality, replacePathnameLocality } from "../../utils/navUtils";
import { DEFAULT_LOCALITY, Locality } from "@/config";

export function useLanguagePicker() {
  const [value, setValue] = useState(getLocality() || DEFAULT_LOCALITY);
  const options: SelectOptionItem[] = [
    { value: "en", label: "EN" },
    { value: "ua", label: "UA" },
    // { value: "es", label: "ES" },
    // { value: "de", label: "DE" },
  ];

  const onChangeLocality = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      setValue(event.target.value as Locality);

      window.location.pathname = replacePathnameLocality(
        window.location.pathname,
        event.target.value as string
      );
    },
    []
  );

  return { options, onChangeLocality, value };
}
