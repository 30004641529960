export function cropText(text: string | null, signsQty: number) {
  if (!text) {
    return text;
  }

  return text.length > signsQty ? text.substring(0, signsQty) + "..." : text;
}

export function extractIdFromString(textWithId: string) {
  const idMatchArr = textWithId.match("([^-]+)");
  return idMatchArr ? idMatchArr[0] : null;
}

export const getStringWithValue = (str: string, value: string | number) =>
  str.replaceAll("{{value}}", `${value}`);

export const getStringWithValues = (
  str: string,
  ...values: (string | number)[]
) => {
  values.forEach((value, i) => {
    const placeholder = `{{value${i + 1}}}`;
    str = str.replaceAll(placeholder, `${value}`);
  });

  return str;
};

export const highlightText = (containerRef: HTMLDivElement | null) => {
  if (!containerRef) {
    return;
  }

  window.getSelection()?.selectAllChildren(containerRef);
};
