import { Dictionary } from "@/i18n/dictionary.types";
import vacancyStatusDialogUA from "@/i18n/dictionary/ua/vacancyStatusDialog";
import vacancyStatusDialogEN, {
  DVacancyStatusDialogType,
} from "@/i18n/dictionary/en/vacancyStatusDialog";
import { t } from "@/i18n/t";

const VACANCY_STATUS_DIALOG: Dictionary<DVacancyStatusDialogType> = {
  ua: vacancyStatusDialogUA,
  en: vacancyStatusDialogEN,
};

export const D_VACANCY_STATUS_DIALOG = t(VACANCY_STATUS_DIALOG);
