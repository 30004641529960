import { ClassNameMap } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { AppTheme } from "@/theme";

export type ClassNames =
  | "root"
  | "title"
  | "titleSmall"
  | "subtitle"
  | "subtitleSmall"
  | "button"
  | "imageContainer"
  | "imageContainerSmall";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: AppTheme) =>
    createStyles({
      root: {},
      title: {
        textAlign: "center",
        fontSize: theme.typography.pxToRem(36),
        fontWeight: 700,
        color: "#464854",
        [theme.breakpoints.down("md")]: {
          fontSize: theme.typography.pxToRem(28),
        },
      },
      titleSmall: {
        fontSize: theme.typography.pxToRem(28),
      },
      imageContainerSmall: {
        maxWidth: 300,
        maxHeight: 200,
      },
      subtitle: {
        textAlign: "center",
        fontSize: theme.typography.pxToRem(17),
        color: "#596880",
        [theme.breakpoints.down("md")]: {
          fontSize: theme.typography.pxToRem(14),
        },
        maxWidth: "90%",
      },
      subtitleSmall: {
        fontSize: theme.typography.pxToRem(14),
      },
      button: {
        marginTop: theme.spacing(3),
      },
      imageContainer: {
        [theme.breakpoints.down("md")]: {
          maxWidth: 300,
          maxHeight: 200,
        },
      },
    })
);
