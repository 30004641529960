const jobsList = Object.freeze({
  applyFilters: "Mostrar resultados",
  emptyFilters: (title: string, query: string) =>
    `${title} como "${query}" não foi encontrado...`,
  autocompletePlaceholder: "Comece a digitar...",
  title: "Filtro",
  clear: "Limpar",
  yearLabel: "ano",
  yearsLabel: "anos",
  skillsTitle: "Habilidades",
  paidPlansTitle: "Tipo de Vaga",
  companiesTitle: "Empresas",
  sliderApply: "OK",
  englishLevelTitle: "Nível de Inglês",
  seniorityTitle: "Nível de Experiência",
  locationTitle: "Localização",
  countryTitle: "País",
  stateTitle: "Região/Estado",
  cityTitle: "Cidade",
  typeTitle: "Tipo de Trabalho",
  categoryTitle: "Categoria",
  experienceTitle: "Experiência",
  budgetTitle: "Salário",
  experienceMin: "Mín",
  experienceMax: "Máx",
  showMore: "Mostrar mais",
  showLess: "Mostrar menos",
  searchPlaceholder: "Comece a digitar...",
  clearAll: "Limpar tudo",
  found: "Encontrado",
  withFilters: (itemsName: string) => `${itemsName} com filtros:`,
  withoutFilters: (itemsName: string) => itemsName,
  loadMore: "Carregar mais",
  vacancies: "vagas ativas",
  recommendations: "recomendações",
  items: "itens",
  companies: "empresas",
  candidates: "candidatos ativos",
  jobs: "trabalhos ativos",
  bannerTitle: "O futuro do recrutamento",
  emptyTitle: "Nenhuma vaga encontrada",
  emptySubtitle:
    "No momento, não há vagas correspondentes aos seus filtros." +
    " Por favor, tente alterar seus filtros",
  pageTitle: "MATCHIT.ME Quadro de Vagas",
  description: `Dê o próximo passo na sua carreira com ferramentas baseadas em IA para combinar seu perfil com vagas relevantes. Qualquer nível, qualquer localização - apenas as melhores ofertas do mercado`,
  keywords:
    "Matchit, Djinni, SkyWorker, LinkedIn, vaga, bônus, TI, trabalho, busca de emprego",
  acceptButton: "Aceitar",
  message:
    'Utilizamos cookies do navegador para algumas necessidades do aplicativo. Ao clicar no botão "Aceitar", você concorda com o uso de cookies',
});

export default jobsList;
