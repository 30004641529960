import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";

export const useStyles = makeStyles()((theme: AppTheme) => ({
  root: {
    padding: theme.spacing(3),
    position: "relative",
    display: "flex",
    flex: 1,
    height: "100%",
    boxShadow: "0px 2px 16px rgba(121, 121, 121, 0.1)",
    transition: "box-shadow 0.3s",

    "&:hover": {
      boxShadow: "0px 2px 16px rgba(121, 121, 121, 0.25)",
    },
  },
  secondContainer: {
    flexDirection: "row",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  mainInfoSection: {
    flex: 1,
  },
  mainInfoSectionNoLogo: {
    [theme.breakpoints.up("md")]: {
      marginLeft: 0,
    },
  },
  bonus: {
    position: "absolute",
    top: -4,
    right: 20,
  },
  companyName: {
    color: "#93A1AF",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "22px",
    marginRight: theme.spacing(1),
  },
  vacancyName: {
    flexGrow: 1,
    width: "167px",
    color: "rgba(0, 0, 0, 0.87)",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: "bold",
    cursor: "pointer",
  },
  vacancyNameRow: {
    margin: theme.spacing(0.5, 0),
  },
  skills: {
    marginTop: theme.spacing(0.5),
  },
  chip: {
    cursor: "pointer",
    margin: "4px 3px",

    "&:first-of-type": {
      marginLeft: 0,
    },
    "&:last-of-type": {
      marginRight: 0,
    },
  },
  uploadCvButton: {
    minWidth: "100%",
    marginTop: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      minWidth: 118,
    },
  },
  publishDate: {
    marginLeft: "auto",
    paddingLeft: theme.spacing(1),
  },
  englishLvl: {
    marginTop: "-6px !Important",
    marginRight: "-5px",
    marginLeft: "-5px",
  },
}));
