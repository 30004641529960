import { gql } from "@apollo/client";

export interface CompanyConnectData {
  companyConnect: boolean;
}

export interface CompanyConnectVars {
  email: string;
  comment: string;
  referralCode?: string;
}

export const COMPANY_CONNECT = gql`
  mutation CompanyConnect(
    $comment: String!
    $email: String!
    $referralCode: String
  ) {
    companyConnect(
      email: $email
      comment: $comment
      referralCode: $referralCode
    )
  }
`;
