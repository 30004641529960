import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import Props from "./Props";
import { useStyles } from "./styles";
import { Hidden, Typography } from "@mui/material";
import NewLabel from "@/common/components/NewLabel";

const SidebarNavigationLink: FC<Props> = (props: Props) => {
  const {
    title,
    icon,
    external,
    iconActive,
    iconHover,
    isNew,
    textVisibility,
    ...rest
  } = props;
  const { classes } = useStyles({
    textMobileOnly: textVisibility !== "always",
  });

  if (external) {
    return (
      <a
        {...rest}
        className={classes.root}
        href={external}
        target="_blank"
        rel="noopener noreferrer">
        <Typography className={classes.title}>{title}</Typography>
      </a>
    );
  }

  return (
    <NavLink exact className={classes.root} title={title} {...rest}>
      {isNew && <NewLabel />}
      {textVisibility !== "always" && (
        <>
          {icon}
          {iconHover}
          {iconActive}
        </>
      )}
      <Hidden lgUp={textVisibility !== "always"}>
        <Typography className={classes.title}>{title}</Typography>
      </Hidden>
    </NavLink>
  );
};

export default SidebarNavigationLink;
