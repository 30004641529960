const vacancyView = Object.freeze({
  vacancyBudgetTitle: "Бюджет",
  joinTelegram: "Підписатися",
  statusTooltip:
    "Ця вакансія неактивна. Будь ласка, спробуйте знайти іншу подібну вакансію",
  status: (status: string) => `Вакансія ${status}`,
  salary: "Зарплата",
  salaryNotSet: "Не вказана",
  bonus: "Бонус",
  updatedAt: "Оновлено",
  vacancyDescriptionTitle: "Опис",
  requirementsTitle: "Вимоги",
  skillsTitle: "Навички",
  vacancyNotAvailable: "Вакансія більше недоступна",
  companyTitle: "Компанія",
  summaryTitle: "Опис вакансії",
  showDetailsTitle: "повний опис",
  hideDetailsTitle: "сховати опис",
  similarTitle: "Подібні вакансії",
  detailsButton: "деталі",
  requirementExperience: "Мінімальний досвід",
  requirementEnglish: "Рівень англійської",
  requirementSeniority: "Рівень",
  ACTIVE: "Активна",
  PAUSED: "Призупинена",
  CLOSED: "Закрита",
  CANCELLED: "Скасована",
  recommend: "Відгукнутися / рекомендувати за бонус",
  recommendForRecruiter: "Порекомендувати за бонус",
  recommendWithoutBonus: "Відгукнутися на вакансію",
  recommendWithoutBonusForRecruiter: "Порекомендувати кандидата",
  applyButton: "Відгукнутися на вакансію",
  connect: "Поділитися за бонус",
  warningUnverifiedRecruiter:
    "Ви не отримаєте бонус за дану рекомендацію, оскільки ваш акаунт ще не верифіковано. Щоб порекомендувати за бонус, дочекайтеся верифікації",
});

export default vacancyView;
