const recommendatorProfile = Object.freeze({
  pageTitle: "My profile settings",
  deleteProfile: "Delete an account",
  deleteProfileNote:
    "We do our best to give you greate experience - we’ll be sad to see you leave us. ",
  deleteProfileMessage:
    "This action will permanently delete your account. All data, recommendations history will be completely removed. Do you wanna confirm this action?",
  deleteProfileTitle: "Are you sure you want delete the account?",
  settingsUpdateSuccess: "Profile settings has been updated successfully",
  settingsUpdateError: "Profile settings hasn't been updated",
  avatarChangeSuccess: "Your profile picture has been changed successfully",
  avatarChangeError: "Your profile picture hasn't been changed",
  avatarDeleteSuccess: "Your profile picture has been deleted successfully",
  avatarDeleteError: "Your profile picture hasn't been deleted",
  changeProfileSuccess:
    "Your profile information has been updated successfully",
  changeProfileError: "Your profile information hasn't been updated...",
  referralCodeCopyMessage: (code?: string) =>
    `The referral link with code ${
      code || ""
    } has been copied to the your clipboard. Ready to share with friends 😉`,
  referralCodeDescription:
    "Use this code to invite people to the platform and receive $10 bonuses from each their successful recommendation",
  updateProfileInformation: "Save Changes",
  changePassword: "Save Changes",
  changePasswordSuccess: "Your password has been changed successfully",
  changePasswordError: (error?: string) =>
    error === "INCORRECT_OLD_PASSWORD"
      ? "Invalid old password"
      : "Your password hasn't been changed",
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  candidateProcessing: "Anonymous account",
  candidateProcessingDescription:
    "Turn it on if you don't ready to process candidates by yourself. We will do everything for you 😉",
  invisibleMode: "Invisible mode",
  anonymousModeDescription:
    "Turn on anonymous mode to hide your name and photo for others",
  emailNotification: "Email notifications",
  pushNotification: "Push notifications",
  pushNotificationDescription:
    "Turn on push notification to get updates through your browser",
  emailNotificationDescription:
    "Turn on email notification to get updates through email",
  contacts: "Contacts",
  referralCode: "Referral Code",
  editProfileSubtitle: "Edit profile",
  changePasswordSubtitle: "Change password",
  additionalInfoSubtitle: "Additional settings",
  changeAvatarError:
    "Can't change your photo right now. Please, try again later",
  showCv: "View Current CV",
  uploadCv: "Upload CV",
  uploadNewCv: "Upload New CV",
  errorUploadCv: "Your resume has’t been updated. Please try again later",
  successUploadCv: "Your resume has been successfully updated!",
});

export default recommendatorProfile;

export type DRecommendatorProfileType = Record<
  keyof typeof recommendatorProfile,
  any
>;
