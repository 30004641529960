import { Dictionary } from "@/i18n/dictionary.types";
import validationUA from "@/i18n/dictionary/ua/validation";
import validationEN, { validationType } from "@/i18n/dictionary/en/validation";
import { t } from "@/i18n/t";

const VALIDATION: Dictionary<validationType> = {
  ua: validationUA,
  en: validationEN,
};

export const D_VALIDATION = t(VALIDATION);
