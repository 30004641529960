import React, { FC, memo } from "react";

import { ProfileDropdownProps } from "./ProfileDropdown.types";
import { useStyles } from "./ProfileDropdown.styles";
import { useProfileDropdown } from "./useProfileDropdown";
import Dropdown from "@/common/components/Dropdown";
import isEqual from "lodash.isequal";
import ProfileDropdownIconLoader from "./ProfileDropdownIconLoader";
import ProfileDropdownIcon from "@/sections/ProfileDropdown/ProfileDropdownIcon";
import ProfileDropdownContent from "@/sections/ProfileDropdown/ProfileDropdownContent";

const ProfileDropdown: FC<ProfileDropdownProps> = (props) => {
  const { actions, isUsePrivateLayout } = props;
  const {
    handleClickCopyReferralCode,
    userName,
    avatar,
    loading,
    userEmail,
    referralCode,
    mainActions,
    goDashboard,
  } = useProfileDropdown();
  const { classes } = useStyles();

  return (
    <Dropdown
      icon={
        loading ? (
          <ProfileDropdownIconLoader />
        ) : (
          <ProfileDropdownIcon
            avatar={avatar}
            userEmail={userEmail}
            userName={userName}
          />
        )
      }
      goDashboard={goDashboard}
      isUsePrivateLayout={isUsePrivateLayout}
      classes={{ iconContainer: classes.iconContainer }}
      className={classes.root}
      data-testid={"user-profile-dropdown"}>
      <div>
        <ProfileDropdownContent
          mainActions={mainActions}
          actions={actions}
          handleClickCopyReferralCode={handleClickCopyReferralCode}
          loading={loading}
          userName={userName}
          userEmail={userEmail}
          referralCode={referralCode}
          avatar={avatar}
        />
      </div>
    </Dropdown>
  );
};

export default memo(ProfileDropdown, (prevProps, newProps) =>
  isEqual(prevProps, newProps)
);
