import { FC } from "react";

import { FilterBodyPaginatedProps } from "./FilterBodyPaginated.types";
import { useStyles } from "./styles";
import ChipsContainer from "@/common/components/ChipsContainer";
import Flex from "@/common/components/Flex";
import Button from "@/common/components/Button";
import { ReactComponent as CloseIcon } from "@/common/icons/close.svg";
import { Box, IconButton, Input, Typography } from "@mui/material";
import { useFilterBodyPaginated } from "./useFilterBodyPaginated";
import { D_JOBS_LIST } from "@/i18n/dictionary/jobsList.dictionary";

const FilterBodyPaginated: FC<FilterBodyPaginatedProps> = (props) => {
  const { activeChips, onChangeFilter, chips, title, loadMore, ...rest } =
    props;
  const { needShowMore, handleQueryChange, handleLoadMore, query, clearQuery } =
    useFilterBodyPaginated(props);
  const { classes } = useStyles();

  return (
    <Flex direction={"column"}>
      <Box className={classes.inputWrapper}>
        <Input
          className={classes.input}
          value={query}
          placeholder={D_JOBS_LIST.autocompletePlaceholder}
          onChange={handleQueryChange}
          inputProps={{
            type: "text",
          }}
        />
        {query.length > 0 && (
          <IconButton
            className={classes.clearQuery}
            size={"small"}
            onClick={clearQuery}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      {chips.length ? (
        <ChipsContainer
          className={classes.chipsContainer}
          chips={chips}
          activeChips={activeChips}
          onChangeFilter={onChangeFilter}
          {...rest}
        />
      ) : (
        <Typography
          className={classes.emptyText}
          variant={"body2"}
          color={"textSecondary"}>
          {D_JOBS_LIST.emptyFilters(title, query)}
        </Typography>
      )}
      {needShowMore && (
        <Box>
          <Button onClick={handleLoadMore} variant={"text"}>
            {D_JOBS_LIST.showMore}
          </Button>
        </Box>
      )}
    </Flex>
  );
};

export default FilterBodyPaginated;
