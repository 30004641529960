import { FC } from "react";

import FilterContainer from "../../common/components/FilterContainer";
import FilterBodyPaginated from "@/common/components/Filter/common/components/FilterContainer/components/FilterBodyPaginated";
import { useJobsCompanyFilter } from "./useJobsCompanyFilter";
import JobsCompanyFilterProps from "./JobsCompanyFilter.types";
import { D_JOBS_LIST } from "@/i18n/dictionary/jobsList.dictionary";

const JobsCompanyFilter: FC<JobsCompanyFilterProps> = (props) => {
  const {
    activeCompanies,
    allCompanies,
    handleChangeCompanyFilter,
    handleClear,
    loadMore,
    companiesTotalCount,
    clearDisabled,
    handleQueryChange,
  } = useJobsCompanyFilter(props);

  return (
    <FilterContainer
      title={D_JOBS_LIST.companiesTitle}
      clearDisabled={clearDisabled}
      onClear={handleClear}
      chips={allCompanies}
      totalChipsQty={companiesTotalCount}
      activeChips={activeCompanies}
      onChangeFilter={handleChangeCompanyFilter}>
      <FilterBodyPaginated
        title={D_JOBS_LIST.companiesTitle}
        chips={allCompanies}
        activeChips={activeCompanies}
        onChangeFilter={handleChangeCompanyFilter}
        loadMore={loadMore}
        onQueryChange={handleQueryChange}
        totalChipsQty={companiesTotalCount}
      />
    </FilterContainer>
  );
};

export default JobsCompanyFilter;
