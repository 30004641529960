import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";

export const useStyles = makeStyles()((theme: AppTheme) => ({
  root: {
    marginRight: theme.spacing(1),
    borderRadius: 20,
  },
  showDialogIcon: {
    position: "fixed",
    zIndex: 10,
    bottom: theme.spacing(11),
    background: "rgba(255,255,255,1)",
    boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.4)",
    right: theme.spacing(3),
    "&:hover": {
      background: "#fff",
    },
  },
}));
