import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";

export const useStyles = makeStyles()((theme: AppTheme) => ({
  root: {
    marginRight: theme.spacing(1),
    position: "relative",
  },
  counter: {
    height: 16,
    width: 16,
    lineHeight: "12px",
    position: "absolute",
    top: 0,
    right: 0,
    fontSize: 10,
    fontWeight: 600,
    color: "white",
    background: theme.palette.primary.main,
    borderRadius: "50%",
    padding: 2,
  },
  chatIcon: {
    width: "20px",
    height: "20px",
  },
}));
