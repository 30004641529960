import { PROJECT_NAME } from "@/config";

const footer = Object.freeze({
  copyright: `© ${new Date().getFullYear()} ${PROJECT_NAME}`,
  aboutUs: "About us",
  userAgreement: "User agreement",
  vacancies: "Vacancies",
  candidates: "Candidates",
  contactUs: "Contact us",
  pricing: "Pricing",
  telegram: "Our telegram",
  privacyPolicy: "Privacy policy",
  faq: "FAQ",
  bannerTitle: "The future of the recruitment",
});

export default footer;

export type DFooterType = Record<keyof typeof footer, any>;
