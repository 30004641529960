import React, { FC } from "react";
import Props from "./Props";
import { useInfiniteScroll } from "./useInfiniteScroll";

const InfiniteScroll: FC<Props> = (props) => {
  const { children, isLoading, contentLoader } = props;
  const { contentRef } = useInfiniteScroll(props);

  return (
    <div ref={contentRef}>
      {children}
      {isLoading && <div style={{ marginTop: 8 }}>{contentLoader}</div>}
    </div>
  );
};

export default InfiniteScroll;
