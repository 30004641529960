const mainNav = Object.freeze({
  home: "Головна",
  about: "Про Нас",
  faq: "Поширені Питання",
  telegram: "Наш Telegram",
  candidates: "Кандидати",
  vacancies: "Вакансії",
  companies: "Компанії",
  pricing: "Прайс-Лист",
  perks: "Matchit perks",
});

export default mainNav;
