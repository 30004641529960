import * as Types from "../../../generated/types";

import { gql } from "@apollo/client";
import { JobCardFragmentDoc } from "../JobView/JobView.operations.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type JobsListCardFragment = {
  __typename?: "Job";
  country: string;
  category?: string | null;
  url: string;
  referencenumber: string;
  title: string;
  company: string;
  city?: string | null;
  state: string;
  date: string;
};

export type JobsQueryVariables = Types.Exact<{
  filter: Types.JobsFilter;
}>;

export type JobsQuery = {
  __typename?: "Query";
  jobs: Array<{
    __typename?: "Job";
    country: string;
    category?: string | null;
    url: string;
    referencenumber: string;
    title: string;
    company: string;
    city?: string | null;
    state: string;
    date: string;
  }>;
};

export type JobsQtyQueryVariables = Types.Exact<{
  filter: Types.JobsQtyFilter;
}>;

export type JobsQtyQuery = { __typename?: "Query"; jobsQty: number };

export const JobsListCardFragmentDoc = gql`
  fragment JobsListCard on Job {
    ...JobCard
    country
    category
    url
  }
  ${JobCardFragmentDoc}
`;
export const JobsDocument = gql`
  query Jobs($filter: JobsFilter!) {
    jobs(filter: $filter) {
      ...JobsListCard
    }
  }
  ${JobsListCardFragmentDoc}
`;

/**
 * __useJobsQuery__
 *
 * To run a query within a React component, call `useJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useJobsQuery(
  baseOptions: Apollo.QueryHookOptions<JobsQuery, JobsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<JobsQuery, JobsQueryVariables>(JobsDocument, options);
}
export function useJobsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JobsQuery, JobsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<JobsQuery, JobsQueryVariables>(
    JobsDocument,
    options
  );
}
export type JobsQueryHookResult = ReturnType<typeof useJobsQuery>;
export type JobsLazyQueryHookResult = ReturnType<typeof useJobsLazyQuery>;
export type JobsQueryResult = Apollo.QueryResult<JobsQuery, JobsQueryVariables>;
export const JobsQtyDocument = gql`
  query JobsQty($filter: JobsQtyFilter!) {
    jobsQty(filter: $filter)
  }
`;

/**
 * __useJobsQtyQuery__
 *
 * To run a query within a React component, call `useJobsQtyQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobsQtyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobsQtyQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useJobsQtyQuery(
  baseOptions: Apollo.QueryHookOptions<JobsQtyQuery, JobsQtyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<JobsQtyQuery, JobsQtyQueryVariables>(
    JobsQtyDocument,
    options
  );
}
export function useJobsQtyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JobsQtyQuery, JobsQtyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<JobsQtyQuery, JobsQtyQueryVariables>(
    JobsQtyDocument,
    options
  );
}
export type JobsQtyQueryHookResult = ReturnType<typeof useJobsQtyQuery>;
export type JobsQtyLazyQueryHookResult = ReturnType<typeof useJobsQtyLazyQuery>;
export type JobsQtyQueryResult = Apollo.QueryResult<
  JobsQtyQuery,
  JobsQtyQueryVariables
>;
