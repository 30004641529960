import React, { FC } from "react";
import Hidden from "@mui/material/Hidden";
import Flex from "@/common/components/Flex";
import Typography from "@mui/material/Typography";
import { ProfileDropdownIconProps } from "@/sections/ProfileDropdown/ProfileDropdownIcon/ProfileDropdownIcon.types";
import { useStyles } from "@/sections/ProfileDropdown/ProfileDropdownIcon/ProfileDropdownIcon.styles";
import { Avatar } from "@/common/components/Avatar";

const ProfileDropdownIcon: FC<ProfileDropdownIconProps> = (props) => {
  const { userName, userEmail, avatar } = props;
  const { classes } = useStyles();

  return (
    <Flex alignVertical={"center"}>
      <Avatar src={avatar} />
      <Hidden smDown>
        <Flex direction={"column"} className={classes.headerInfoContainer}>
          <Typography className={classes.headerInfoContainerName}>
            {userName}
          </Typography>
          <Typography className={classes.headerInfoContainerEmail}>
            {userEmail}
          </Typography>
        </Flex>
      </Hidden>
    </Flex>
  );
};

export default ProfileDropdownIcon;
