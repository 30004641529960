import { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import Flex from "../../../../../../Flex";
import { useFilterHeader } from "./useFilterHeader";
import Button from "../../../../../../Button";
import { D_JOBS_LIST } from "@/i18n/dictionary/jobsList.dictionary";

const FilterHeader: FC<Props> = (props: Props) => {
  const { className, title, clearDisabled, count, countActive, ...rest } =
    props;
  const classes = useStyles();
  const { handleClear } = useFilterHeader(props);

  return (
    <Flex
      alignVertical={"center"}
      className={clsx(classes.root, className)}
      {...rest}>
      <Typography className={classes.title}>
        {`${title}${count ? ` (${countActive}/${count})` : ""}`}
      </Typography>
      <Button
        disabled={clearDisabled}
        className={classes.clearButton}
        variant={"text"}
        onClick={handleClear}>
        {D_JOBS_LIST.clear}
      </Button>
    </Flex>
  );
};

export default FilterHeader;
