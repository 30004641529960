const validation = Object.freeze({
  isRequired: "is required",
  isStrongPassword:
    "must contain at least one number, uppercase and lowercase letter and has length 8 or more symbols",
  isPasswordSame: "must be the same as",
  isEmail: "is not valid",
  isContainMore: "can't contain more than {{value}} symbols",
  isContainLess: "can't contain less than {{value}} symbols",
  isUrl: "is not valid url",
  isNumeric: "is not valid number",
  minValue: "can't be less than {{value}}",
  maxValue: "can't be more than {{value}}",
});

export default validation;

export type validationType = Record<keyof typeof validation, any>;
