import React, { FC, ReactNode, useCallback } from "react";
import ContentLoader from "react-content-loader";

import Props from "./Props";
import { useStyles } from "./styles";
import { Grid } from "@mui/material";
import JobCard from "../JobCard";
import { Paper } from "@/common/components/Paper";

const JobsGrid: FC<Props> = (props: Props) => {
  const { jobs, loading } = props;
  const { classes } = useStyles();

  const renderGridItem = useCallback(
    (children: ReactNode, key: string) => (
      <Grid
        className={classes.gridItem}
        key={key}
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}>
        {children}
      </Grid>
    ),
    [classes.gridItem]
  );

  const renderJobs = useCallback(
    () =>
      jobs.map((job) =>
        renderGridItem(<JobCard job={job} />, job.referencenumber)
      ),
    [renderGridItem, jobs]
  );

  const renderPlaceholders = () => {
    const placeholders: ReactNode[] = [];

    for (let i = 0; i < 10; i++) {
      placeholders.push(
        renderGridItem(
          <Paper className={classes.placeholder}>
            <ContentLoader height={132} width={"100%"} />
          </Paper>,
          i.toString()
        )
      );
    }

    return placeholders;
  };

  return (
    <>
      <Grid classes={{ root: classes.root }} container spacing={2}>
        {!jobs.length && loading ? renderPlaceholders() : renderJobs()}
      </Grid>
    </>
  );
};

export default React.memo(JobsGrid);
