import { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";
import Flex from "../Flex";
import { Hidden, IconButton, Tooltip, Typography } from "@mui/material";
import clsx from "clsx";
import { ReactComponent as FilterIcon } from "../../icons/filter-2.svg";
import Dialog from "../Dialog";
import { useFilterComponent } from "./useFilterComponent";
import { useIsMobile } from "@/common/hooks/useIsMobile";
import { D_JOBS_LIST } from "@/i18n/dictionary/jobsList.dictionary";
import Button from "../Button";

const Filter: FC<Props> = (props: Props) => {
  const { className, children, isHideFilter, ...rest } = props;
  const { classes } = useStyles();
  const isMobile = useIsMobile();
  const {
    filterModalVisible,
    showFilterModal,
    hideFilterModal,
    containerRef,
    isMyCandidates,
  } = useFilterComponent(props);

  const filter = (
    <div className={classes.wrapper}>
      <div
        ref={containerRef}
        className={clsx(!isMyCandidates && classes.container)}>
        <Flex
          direction={"column"}
          className={clsx(!isHideFilter && classes.root, className)}
          {...rest}>
          {!isHideFilter && (
            <Hidden lgDown>
              <Typography className={classes.title}>
                {D_JOBS_LIST.title}
              </Typography>
            </Hidden>
          )}
          <Flex direction={"column"} className={classes.filtersContainer}>
            {children}
          </Flex>
        </Flex>
      </div>
    </div>
  );

  return (
    <>
      {isHideFilter ? (
        <IconButton onClick={showFilterModal} className={classes.icon}>
          <Tooltip title={D_JOBS_LIST.title}>
            <FilterIcon />
          </Tooltip>
          <Hidden mdDown>{D_JOBS_LIST.title}</Hidden>
        </IconButton>
      ) : (
        <Hidden lgUp>
          <Button
            onClick={showFilterModal}
            startIcon={<FilterIcon />}
            variant={"outlined"}
            className={classes.filterButton}>
            {D_JOBS_LIST.title}
          </Button>
        </Hidden>
      )}
      {!isHideFilter && <Hidden lgDown>{filter}</Hidden>}
      <Hidden lgUp={!isHideFilter}>
        <Dialog
          hideCancelAction
          fullScreen={isMobile}
          acceptText={D_JOBS_LIST.applyFilters}
          acceptAction={hideFilterModal}
          title={D_JOBS_LIST.title}
          onClose={hideFilterModal}
          open={filterModalVisible}>
          {filter}
        </Dialog>
      </Hidden>
    </>
  );
};

export default Filter;
