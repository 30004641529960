const validation = Object.freeze({
  isRequired: "обов'язкове поле",
  isStrongPassword:
    "має містити принаймні одну цифру, велику та малу літеру та мати довжину 8 або більше символів",
  isPasswordSame: "має бути таким же, як",
  isEmail: "не є дійсною",
  isContainMore: "не може містити більше ніж {{value}} символів",
  isContainLess: "не може містити менше ніж {{value}} символів",
  isUrl: "недійсна URL-адреса",
  isNumeric: "недійсне число",
  minValue: "не може бути менше ніж {{value}}",
  maxValue: "не може бути більше ніж {{value}}",
});

export default validation;
