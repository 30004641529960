import { ClassNameMap } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { AppTheme } from "@/theme";

export type ClassNames =
  | "root"
  | "drawer"
  | "logo"
  | "drawerOpen"
  | "drawerClose"
  | "modal";

export const SIDEBAR_WIDTH_DESKTOP = 72;

export const useStyles: (
  desktopWidthSmall: boolean
) => ClassNameMap<ClassNames> = ((desktopWidthSmall) =>
  makeStyles((theme: AppTheme) =>
    createStyles({
      root: {},
      drawer: {
        minWidth: desktopWidthSmall ? 200 : undefined,
        [theme.breakpoints.up("lg")]: {
          width: desktopWidthSmall ? SIDEBAR_WIDTH_DESKTOP : undefined,
          padding: theme.spacing(1.75),
        },
        padding: theme.spacing(1),
        border: 0,
        boxShadow: "rgb(59 72 118 / 8%) 0px 0px 30px",
      },
      drawerClose: {},
      drawerOpen: {},
      modal: {},
      logo: {
        margin: "6px auto",
      },
    })
  ))();
