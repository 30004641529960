import React, { FC } from "react";
import Props from "./Props";
import { useStyles } from "./styles";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import { formatDate } from "@/common/utils/dateTimeUtils";

const JobPublished: FC<Props> = (props: Props) => {
  const { updatedAt, className, ...rest } = props;

  const classes = useStyles();

  return (
    <Typography
      variant={"subtitle2"}
      className={clsx(classes.updatedAt, className)}
      {...rest}>
      {formatDate(updatedAt)}
    </Typography>
  );
};

export default JobPublished;
