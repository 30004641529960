import { CURRENCY_SYMBOL, VACANCY_BONUS_PERCENT_BASIC } from "@/config";
import { D_VACANCY_CARD } from "@/i18n/dictionary/vacancyCard.dictionary";
import { formatThousandsWithSpace } from "./numbersUtils";
import { Recommendation } from "@/common/models/Recommendation";
import { PaidPlanName } from "@/generated/types";

export const formatPrice = (price: number | null | undefined, symbol = "") =>
  price !== null && price !== undefined
    ? CURRENCY_SYMBOL + formatThousandsWithSpace(price)
    : symbol;

export const formatFeeV1 = (
  feePercent?: number | null,
  feeAmount?: number | null
) => {
  if (feeAmount) {
    return formatPrice(feeAmount);
  }

  if (feePercent) {
    return D_VACANCY_CARD.bonusValue(feePercent);
  }

  return formatPrice(0);
};

export const formatFee = (recommendation: Recommendation) => {
  return recommendation.bonusModelVersion === 1
    ? formatFeeV1(recommendation.feePercent, recommendation.feeAmount)
    : formatFeeV2(recommendation.vacancyBonus, recommendation.bonus);
};

export const formatFeeV2 = (
  fullBonus: number | null | undefined,
  recruiterBonus: number | null | undefined
) => {
  if (fullBonus) {
    return formatPrice(recruiterBonus ? fullBonus - recruiterBonus : fullBonus);
  }

  return formatPrice(0);
};

export const formatBonus = (
  bonus: number | null | undefined,
  prefix = "",
  strict?: boolean,
  withMask?: boolean | null
) => {
  if (withMask) {
    return "$X XXX";
  }

  let bonusLabel = "";

  if (prefix) {
    bonusLabel = prefix + " " + bonusLabel;
  }

  if (bonus) {
    bonusLabel += (strict ? "\xa0" : "≈\xa0") + formatPrice(bonus);
  } else {
    bonusLabel = D_VACANCY_CARD.noBonus;
  }

  return bonusLabel;
};

export const getRecommendationBonus = (
  recommendation: Recommendation,
  isVacancyAdmin: boolean
) => {
  if (recommendation.bonusModelVersion === 1) {
    return isVacancyAdmin &&
      recommendation.bonus &&
      recommendation.vacancy.plan !== PaidPlanName.Premium
      ? recommendation.bonus +
          Math.floor(
            (recommendation.bonus * VACANCY_BONUS_PERCENT_BASIC) /
              (100 - VACANCY_BONUS_PERCENT_BASIC)
          )
      : recommendation.bonus;
  }

  return isVacancyAdmin
    ? recommendation.vacancyBonus || null
    : recommendation.bonus || null;
};

export const formatRecommendationBonus = (
  recommendation: Recommendation,
  isVacancyAdmin: boolean
) => {
  const bonus = getRecommendationBonus(recommendation, isVacancyAdmin);

  return recommendation.bonusModelVersion === 1
    ? formatBonus(bonus, "", true)
    : bonus
    ? formatPrice(bonus)
    : "-";
};

export const formatSalary = (
  minBudget: number | null | undefined,
  maxBudget: number | null | undefined
) => {
  const isShowOnlyMinBudget =
    minBudget === maxBudget || typeof maxBudget !== "number";

  if (isShowOnlyMinBudget) {
    return formatPrice(minBudget);
  }

  if (typeof minBudget !== "number") {
    return formatPrice(maxBudget);
  }

  return formatPrice(minBudget) + "\xA0-\xA0" + formatPrice(maxBudget);
};

export const calculateBonusPercent = (
  recommendationBonusPercent: number | null,
  userBonusPercent?: number
) => {
  if (!recommendationBonusPercent && !userBonusPercent) {
    return null;
  } else if (recommendationBonusPercent && userBonusPercent) {
    return recommendationBonusPercent + userBonusPercent;
  } else {
    return recommendationBonusPercent || userBonusPercent;
  }
};
