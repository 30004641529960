import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";

export const useStyles = makeStyles()((theme: AppTheme) => ({
  root: {
    visibility: "hidden",
    position: "fixed",
    right: 40,
    bottom: 40,
    zIndex: 10,
    borderRadius: "50%",
    overflow: "hidden",
    cursor: "pointer",
    opacity: 0,
    transition: "visibility 0.5s, opacity 0.5s ease-in-out",

    [theme.breakpoints.down("md")]: {
      right: 24,
      bottom: 70,
    },
  },
  visible: {
    visibility: "visible",
    opacity: 1,
  },
  icon: {
    width: 35,
    height: 35,
    transform: "rotate(270deg)",

    "&:hover": {
      backgroundColor: "#f9f9f9",
    },
  },
}));
