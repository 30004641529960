import { D_VALIDATION } from "@/i18n/dictionary/validation.dictionary";
import { BaseForm } from "../hooks/useForm";
import { getStringWithValue } from "./textUtils";

export const isValid = (
  value: string,
  regexp: RegExp,
  flags?: string
): boolean => {
  const condition = new RegExp(regexp, flags);
  return condition.test(value);
};

interface LengthRule {
  max?: number;
  min?: number;
}

export type FieldType = "email" | "url" | "password" | "number" | "linkedin";

type FormSchema = Record<string, ValidationRules>;

export interface ValidationRules {
  type?: FieldType | FieldType[];
  length?: LengthRule;
  value?: LengthRule;
  required?: boolean;
  equal?: string;
  fieldName?: string;
}

function validateLength(
  value: string,
  rules: LengthRule,
  handleError: (error: string) => void
) {
  const valueLength = value.length;
  const ruleMax = rules.max;
  const ruleMin = rules.min;

  if (ruleMax && ruleMax < valueLength) {
    handleError(getStringWithValue(D_VALIDATION.isContainMore, ruleMax));
  }

  if (ruleMin && ruleMin > valueLength) {
    handleError(getStringWithValue(D_VALIDATION.isContainLess, ruleMin));
  }
}

function validateNumbersRange(
  value: string,
  rules: LengthRule,
  handleError: (error: string) => void
) {
  const ruleMax = rules.max;
  const ruleMin = rules.min;

  if (ruleMax && ruleMax < +value) {
    handleError(getStringWithValue(D_VALIDATION.maxValue, ruleMax));
  }

  if (ruleMin && ruleMin > +value) {
    handleError(getStringWithValue(D_VALIDATION.minValue, ruleMin));
  }
}

function validateEqual(
  value: string,
  comparisonFieldName: string,
  comparison: string,
  handleError: (error: string) => void
) {
  if (value !== comparison) {
    handleError(`${D_VALIDATION.isPasswordSame} ${comparisonFieldName}`);
  }
}

export function validate<S extends FormSchema, F extends BaseForm>(
  key: keyof S,
  value: string,
  schema: S,
  form: F
): string {
  let error = "";
  const validationRules: ValidationRules = schema[key];

  const handleError = (errorString: string) => {
    const editedKey = key
      .toString()
      .replace(/([A-Z])/g, " $1")
      .trim();
    const fieldName =
      validationRules.fieldName ||
      // @ts-ignore
      editedKey[0].toUpperCase() + editedKey.slice(1, editedKey.length);

    error = fieldName + " " + errorString;
  };

  if (!value) {
    if (validationRules.required) {
      handleError(D_VALIDATION.isRequired);
    }

    return error;
  }

  // @ts-ignore
  for (const ruleKey: keyof ValidationRules in validationRules) {
    if (validationRules.hasOwnProperty(ruleKey)) {
      const rules = validationRules[ruleKey as keyof ValidationRules];

      switch (ruleKey) {
        case "type": {
          validateType(value, rules as FieldType | FieldType[], handleError);
          break;
        }
        case "length": {
          validateLength(value, rules as LengthRule, handleError);
          break;
        }
        case "numbersRange": {
          validateNumbersRange(value, rules as LengthRule, handleError);
          break;
        }
        case "equal": {
          const fieldToCompare = rules as string;
          const comparisonFieldName =
            schema[fieldToCompare].fieldName || fieldToCompare;

          validateEqual(
            value,
            comparisonFieldName,
            // @ts-ignore
            form.values[fieldToCompare],
            handleError
          );
          break;
        }
        default: {
          // nothing
        }
      }
    }
  }

  return error;
}

function isEmail(email: string) {
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

const isURL = (url: string) => {
  return new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ).test(url);
};

export function isUrlLinkedin(url: string) {
  const regex = /^(https?:\/\/)?(www\.)?linkedin\.com\/.+/;

  return regex.test(url);
}

function isPositiveNumber(number: number) {
  return number >= 0;
}

function isNumeric(value: string) {
  return !isNaN(parseFloat(value)) && isPositiveNumber(+value);
}

function isStrongPassword(value: string) {
  return new RegExp(
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$"
    // TODO restore special symbols
    //'^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#_?!@$%^&*-]).{8,}$',
  ).test(value);
}

function validateType(
  value: string,
  type: FieldType | FieldType[],
  handleError: (error: string) => void
): void {
  switch (type) {
    case "email": {
      if (!isEmail(value)) {
        handleError(D_VALIDATION.isEmail);
      }
      break;
    }
    case "url": {
      if (!isURL(value)) {
        handleError(D_VALIDATION.isUrl);
      }
      break;
    }
    case "password": {
      if (!isStrongPassword(value)) {
        handleError(D_VALIDATION.isStrongPassword);
      }
      break;
    }
    case "number": {
      if (!isNumeric(value)) {
        handleError(D_VALIDATION.isNumeric);
      }
      break;
    }
    case "linkedin": {
      if (!isUrlLinkedin(value)) {
        handleError(D_VALIDATION.isUrl);
      }
      break;
    }
  }
}
