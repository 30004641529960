import { Dictionary } from "@/i18n/dictionary.types";
import recommendationStatusUA from "@/i18n/dictionary/ua/recommendationStatus";
import recommendationStatusEN, {
  DRecommendationStatusType,
} from "@/i18n/dictionary/en/recommendationStatus";
import { t } from "@/i18n/t";

const RECOMMENDATION_STATUS: Dictionary<DRecommendationStatusType> = {
  ua: recommendationStatusUA,
  en: recommendationStatusEN,
};

export const D_RECOMMENDATION_STATUS = t(RECOMMENDATION_STATUS);
