import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";

export const useStyles = makeStyles()((theme: AppTheme) => ({
  root: {
    marginRight: theme.spacing(1.5),
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    marginLeft: theme.spacing(0.5),
    color: "#93A1AF",
  },
  iconWrapper: {
    marginTop: 2,
  },
}));
