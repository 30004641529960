import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";

export const HEADER_HEIGHT = 70;
export const OFFLINE_MODE_HEIGHT = 20;

export const useStyles = makeStyles()((theme: AppTheme) => ({
  root: {
    display: "flex",
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up("md")]: {
      minHeight: HEADER_HEIGHT,
    },
  },
  profileIcon: {
    marginTop: -3,
    width: 22,
    height: 22,
  },
  rootOfflineMode: {
    top: `${OFFLINE_MODE_HEIGHT}px !important`,
  },
  divider: {
    height: "15px !important",
    backgroundColor: `${theme.palette.text.primary} !important`,
  },
  menuBurger: {},
  headerContent: {
    flex: 1,
    height: "auto",
  },
  authContainer: {
    border: `1px solid ${theme.palette.text.secondary}`,
    borderRadius: 20,
    overflow: "hidden",
  },
  mainActions: {
    marginLeft: "auto",
  },
  container: {
    width: "100%",
  },
  pageTitleContainer: {
    marginRight: "auto",
    padding: theme.spacing(0, 1),
  },
  login: {
    paddingLeft: "16px !important",
    paddingRight: "16px !important",
  },
  register: {
    paddingLeft: "16px !important",
    paddingRight: "16px !important",
  },
  commonActions: {
    margin: theme.spacing(0, 2),
  },
  button: {
    // bug on prod build
    borderRadius: "20px !important",
    paddingLeft: "16px !important",
    paddingRight: "16px !important",
  },
  link: {
    position: "relative",
    color: theme.palette.text.primary + "!important",
    fontSize: "14px !important",
    paddingTop: "10px !important",
    paddingBottom: "10px !important",
  },
  buttonPublishVacancy: {
    marginLeft: "16px !important",
  },
  logo: {
    cursor: "pointer",
  },
  legalNotice: {
    marginTop: theme.spacing(1),
  },
  offlineMode: {
    position: "sticky",
    justifyContent: "center",
    top: 0,
    zIndex: 11,
    height: OFFLINE_MODE_HEIGHT,
    overflow: "hidden",
    background: "#ff4c4c",
    color: "#fff",
    fontSize: "13px",
  },
  offlineModeContent: {
    [theme.breakpoints.down("md")]: {
      position: "absolute",
      width: "770px",
      textAlign: "center",
      flexShrink: "0",
      animation: "ticker 20s linear infinite",
    },
    "@keyframes ticker": {
      "0%": {
        left: "20px",
      },
      "100%": {
        left: "calc(100% - 790px)",
      },
    },
  },
}));
