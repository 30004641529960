import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  root: {
    position: "relative",
  },
  iconContainer: {
    padding: "0 !important",
  },
}));
