const companyConnect = Object.freeze({
  email: "Електронна пошта",
  comment: "Коментар",
  messageSuccess:
    "Ваш запит успішно надіслано. Ми зв'яжемося з Вами найближчим часом!😉",
  messageError: "Ваш запит не надіслано... Будь ласка, спробуйте пізніше",
  title: "Розмістити вакансію",
  subtitle:
    "Якщо ви хочете опублікувати вакансії з бонусом, залиште свою електронну пошту і ми зв’яжемося з вами найближчим часом! Або самостійно ",
  acceptButtonText: "Надіслати",
  signUpLink: "створіть профіль компанії",
  signUpMessage: ", щоб опублікувати вакансії безкоштовно.",
});

export default companyConnect;
