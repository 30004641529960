import { SelectOptionItem } from "@/common/components/StyledSelectPicker/Props";
import { EnglishLevel } from "@/common/models/EnglishLevel";
import { Seniority } from "@/common/models/Seniority";
import { JobSource } from "@/generated/types";

export const PROJECT_NAME = "Matchit";
export const CURRENCY_SYMBOL = "$";
export const EXPERIENCE_MIN = 0;
export const EXPERIENCE_MAX = 20;
export const BUDGET_MAX = 30000;
export const BUDGET_STEP = 100;
export const BUDGET_MIN = 0;
export const ENGLISH_LEVEL_MIN = EnglishLevel.ELEMENTARY;
export const ENGLISH_LEVEL_MAX = EnglishLevel.NOT_SPECIFIED;

export const VACANCY_BONUS_PERCENT_PREMIUM = 50;
export const VACANCY_BONUS_PERCENT_BASIC = 25;
export const FILTER_ITEMS_LIMIT = 20;
export const SELECT_PICKER_ITEMS_LIMIT = 1000;

export const IS_SHOW_REFERRAL_CODE = false;

export const SENIORITY_OPTIONS: SelectOptionItem[] = [
  { label: Seniority.JUN, value: "Junior" },
  { label: Seniority.JUN_MID, value: "Junior-Middle" },
  { label: Seniority.MID, value: "Middle" },
  { label: Seniority.MIN_SEN, value: "Middle-Senior" },
  { label: Seniority.SEN, value: "Senior" },
  { label: Seniority.LEAD, value: "Team/Tech Lead" },
  { label: Seniority.ARCH, value: "Architect" },
  { label: Seniority.C_LEVEL, value: "C-level" },
];

export const JOB_SOURCE = {
  [JobSource.Djinni]: "Djinni.co",
  [JobSource.ThemuseApi]: "Themuse.com",
  [JobSource.Indeed]: "Indeed.co",
  [JobSource.Workable]: "Workable.com",
  [JobSource.JustJoinIt]: "Justjoin.it",
  [JobSource.AdzunaApi]: "Adzuna.com",
  [JobSource.CleverstaffApi]: "Cleverstaff.net",
};

export const RECOMMENDED_BONUS = {
  [Seniority.NOT_SPECIFIED]: { min: 0, default: "" },
  [Seniority.JUN]: { min: 0, default: "50" },
  [Seniority.JUN_MID]: { min: 0, default: "100" },
  [Seniority.MID]: { min: 0, default: "600" },
  [Seniority.MIN_SEN]: { min: 0, default: "800" },
  [Seniority.SEN]: { min: 0, default: "1000" },
  [Seniority.LEAD]: { min: 0, default: "1200" },
  [Seniority.ARCH]: { min: 0, default: "1500" },
  [Seniority.C_LEVEL]: { min: 0, default: "2000" },
};

export const RECOMMENDED_BONUS_FOR_RECRUITER = {
  [Seniority.NOT_SPECIFIED]: "",
  [Seniority.JUN]: "",
  [Seniority.JUN_MID]: "",
  [Seniority.MID]: "300",
  [Seniority.MIN_SEN]: "400",
  [Seniority.SEN]: "500",
  [Seniority.LEAD]: "600",
  [Seniority.ARCH]: "700",
  [Seniority.C_LEVEL]: "800",
};

export type Locality =
  | "en"
  | "ua"
  | "es"
  | "de"
  | "uk"
  | "ca"
  | "pa"
  | "nl"
  | "br"
  | "mx"
  | "us"
  | "gb";

export const ALLOWED_LOCALITIES: Locality[] = [
  "en",
  "ua",
  "es",
  "de",
  "pa",
  "uk",
  "ca",
  "nl",
  "br",
  "mx",
  "us",
  "gb",
];

export const DEFAULT_LOCALITY: Locality = "en";

export const isOnePage = process.env.REACT_APP_ONEPAGE === "true";
