import { ClassNameMap } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

export type ClassNames = "root";

const LAYOUT_MAX_WIDTH = 1440;

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: LAYOUT_MAX_WIDTH,
      margin: "0 auto",
    },
  })
);
