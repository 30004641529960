import { formatBonus } from "@/common/utils/priceUtils";

const vacancyCard = Object.freeze({
  bonus: (bonus: number) => `Your bonus ${formatBonus(bonus, "")}`,
  bonusTooltip: (bonus: number | null, vacancyBonusPercent: number) => {
    const bonusType = "referral/";
    const roleType = "candidate from your recommendation";
    const salaryType = "the candidate";

    return `You will receive a ${bonusType}sign-on bonus ${
      vacancyBonusPercent > 0
        ? `equal to ${vacancyBonusPercent}% of ${salaryType} salary`
        : ""
    }${
      bonus === null
        ? ""
        : ". For this vacancy it is up to " + formatBonus(bonus, "")
    }  if company will hire ${roleType}`;
  },
  bonusValue: (percent: number) => `${percent}%\xa0salary`,
  noBonusTooltip:
    "There is no bonus for recommending a candidate for this vacancy",
  noBonus: "No bonus",
  uploadCv: "Send\xa0CV",
  message: "Message",
  messageError: "Can't message right now. Please, try again later",
  updatedAt: "Published",
  telegramButton: (companyName: string) =>
    "The latest information about new vacancies of the \n" +
    companyName +
    " you can find in our Telegram",
  chipDescription: "+{{value}} more",
});

export default vacancyCard;

export type DVacancyCardType = Record<keyof typeof vacancyCard, any>;
