import { Dictionary } from "@/i18n/dictionary.types";
import registrationDialogUA from "@/i18n/dictionary/ua/registrationDialog";
import registrationDialogEN, {
  DRegistrationDialogType,
} from "@/i18n/dictionary/en/registrationDialog";
import { t } from "@/i18n/t";

const REGISTRATION_DIALOG: Dictionary<DRegistrationDialogType> = {
  ua: registrationDialogUA,
  en: registrationDialogEN,
};

export const D_REGISTRATION_DIALOG = t(REGISTRATION_DIALOG);
