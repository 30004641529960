import { PROJECT_NAME } from "@/config";

const footer = Object.freeze({
  copyright: `© ${new Date().getFullYear()} ${PROJECT_NAME}`,
  aboutUs: "Про нас",
  vacancies: "Вакансії",
  candidates: "Кандидати",
  userAgreement: "Угода користувача",
  contactUs: "Зв'язатися з нами",
  pricing: "Ціноутворення",
  telegram: "Наш телеграм",
  privacyPolicy: "Політика конфіденційності",
  faq: "Поширені питання",
  bannerTitle: "Майбутнє рекрутингу",
});

export default footer;
