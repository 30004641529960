import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";

export const useStyles = makeStyles()((theme: AppTheme) => ({
  root: {
    minWidth: 300,
    [theme.breakpoints.up("md")]: {
      minWidth: 500,
    },
  },
  dialogContent: {
    maxWidth: "100%",
    width: "100%",
  },
  closeIconAbsolute: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      position: "static",
    },
  },
  dialog: {
    maxWidth: 600,
  },
  dialogIllustrationLeft: {
    maxWidth: 842,
  },
  rootCenter: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    width: "100%",
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  titleCenter: {
    textAlign: "center",
  },
  subtitle: {
    color: "#697077",
    fontSize: theme.typography.pxToRem(15),
    marginTop: theme.spacing(1),
  },
  subtitleCenter: {
    textAlign: "center",
  },
  centerActions: {
    display: "flex",
    justifyContent: "center",
  },
  illustrationContainer: {
    padding: theme.spacing(4),
    backgroundColor: "#F2F4F8",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));
