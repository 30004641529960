let timeout: number;

export const scrollToInputWithError = (
  formRef: HTMLFormElement | null,
  errors: Record<string, string>
) => {
  if (!formRef) {
    return;
  }

  for (const key in errors) {
    if (!!errors[key]) {
      const input: HTMLInputElement | null = formRef.querySelector(
        `[name=${key}]`
      );
      const inputContainer =
        input?.closest(".MuiGrid-root") ||
        input?.closest(".MuiFormControl-root");

      const scrollToError = () => {
        inputContainer
          ? inputContainer.scrollIntoView({ behavior: "smooth" })
          : input?.scrollIntoView({ behavior: "smooth" });
      };

      clearTimeout(timeout);
      timeout = window.setTimeout(scrollToError, 100);

      return;
    }
  }

  formRef.scrollIntoView();
};
