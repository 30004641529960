import * as Types from "../../generated/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type ProfileDropdownUserDataQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type ProfileDropdownUserDataQuery = {
  __typename?: "Query";
  getUser: {
    __typename?: "User";
    avatar?: string | null;
    email?: string | null;
    fullName: string;
    id: string;
    referralCode?: string | null;
  };
};

export const ProfileDropdownUserDataDocument = gql`
  query ProfileDropdownUserData {
    getUser {
      avatar
      email
      fullName
      id
      referralCode
    }
  }
`;

/**
 * __useProfileDropdownUserDataQuery__
 *
 * To run a query within a React component, call `useProfileDropdownUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileDropdownUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileDropdownUserDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileDropdownUserDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProfileDropdownUserDataQuery,
    ProfileDropdownUserDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProfileDropdownUserDataQuery,
    ProfileDropdownUserDataQueryVariables
  >(ProfileDropdownUserDataDocument, options);
}
export function useProfileDropdownUserDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProfileDropdownUserDataQuery,
    ProfileDropdownUserDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProfileDropdownUserDataQuery,
    ProfileDropdownUserDataQueryVariables
  >(ProfileDropdownUserDataDocument, options);
}
export type ProfileDropdownUserDataQueryHookResult = ReturnType<
  typeof useProfileDropdownUserDataQuery
>;
export type ProfileDropdownUserDataLazyQueryHookResult = ReturnType<
  typeof useProfileDropdownUserDataLazyQuery
>;
export type ProfileDropdownUserDataQueryResult = Apollo.QueryResult<
  ProfileDropdownUserDataQuery,
  ProfileDropdownUserDataQueryVariables
>;
