import { HEADER_HEIGHT } from "@/layout/common/components/Header/styles";
import { useCallback, useEffect, useRef, useState } from "react";
import Props from "./Props";
import { AppRoute, getRouteWithSlash } from "@/router/AppRoute";

export function useFilterComponent({
  isHideFilter,
  isUsePrivateLayout,
}: Props) {
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const currScrollPosition = useRef(0);
  const isMyCandidates =
    window.location.pathname ===
    getRouteWithSlash(AppRoute.RECOMMENDATOR_CANDIDATES);

  const setPosition = useCallback((position: string, top: number | null) => {
    if (!containerRef.current) {
      return;
    }

    const currTop = parseFloat(containerRef.current.style.top);
    const currPosition = containerRef.current.style.position;

    if (currPosition === position && (currTop === top || isNaN(currTop))) {
      return;
    }

    containerRef.current.style.position = position;
    containerRef.current.style.top = top !== null ? `${top}px` : "";
  }, []);

  const setStickyContainer = useCallback(() => {
    if (!containerRef.current) {
      return;
    }

    const scrollPositionY = window.scrollY;
    const prevScrollDifference = scrollPositionY - currScrollPosition.current;
    const minScroll = 10;

    if (prevScrollDifference < minScroll && prevScrollDifference > -minScroll) {
      return;
    }

    const isScrollTop = scrollPositionY < currScrollPosition.current;
    const stickyContainerHeight =
      containerRef.current.getBoundingClientRect().height;
    const VH = window.innerHeight;
    const containerPositionY = containerRef.current.getBoundingClientRect().y;
    const isStickyContainerLarge = stickyContainerHeight > VH;
    const isTop = containerPositionY >= 0;
    const isBottom =
      stickyContainerHeight + containerPositionY <= VH && !isScrollTop;
    const isPositionRelative = !isBottom && !(isTop && isScrollTop);
    currScrollPosition.current = scrollPositionY;

    if (isPositionRelative) {
      const top = isScrollTop
        ? scrollPositionY +
          containerRef.current.getBoundingClientRect().top -
          HEADER_HEIGHT
        : null;

      setPosition("relative", top);
    }

    if (isTop) {
      if (isStickyContainerLarge && !isScrollTop) {
        return;
      }

      const top = isUsePrivateLayout ? HEADER_HEIGHT : 0;

      setPosition("sticky", top);
    }

    if (isStickyContainerLarge && isBottom) {
      const top = VH - stickyContainerHeight;

      setPosition("sticky", top);
    }
  }, [setPosition, isUsePrivateLayout]);

  useEffect(() => {
    if (isHideFilter || isMyCandidates) {
      return;
    }

    const handleScroll = () => {
      setStickyContainer();
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [isHideFilter, isMyCandidates, setStickyContainer]);

  const showFilterModal = () => {
    setFilterModalVisible(true);
  };

  const hideFilterModal = () => {
    setFilterModalVisible(false);
  };

  return {
    filterModalVisible,
    showFilterModal,
    hideFilterModal,
    containerRef,
    isMyCandidates,
  };
}
