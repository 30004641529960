import { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";
import ChipsContainer from "../../../../../../ChipsContainer";
import Flex from "../../../../../../Flex";
import Button from "../../../../../../Button";
import { ReactComponent as CloseIcon } from "../../../../../../../icons/close.svg";
import { Box, Collapse, IconButton, Input, Typography } from "@mui/material";
import { useFilterBody } from "./useFilterBody";
import { D_JOBS_LIST } from "@/i18n/dictionary/jobsList.dictionary";

const FilterBody: FC<Props> = (props: Props) => {
  const { activeChips, onChangeFilter, chips, title, ...rest } = props;
  const {
    toggled,
    toggle,
    needShowMore,
    needAutocomplete,
    handleQueryChange,
    query,
    clearQuery,
    filteredChips,
  } = useFilterBody(props);
  const collapsedSize = 100;
  const { classes } = useStyles();

  const renderChipsContainer = () => {
    const container = filteredChips.length ? (
      <ChipsContainer
        className={classes.chipsContainer}
        chips={filteredChips}
        activeChips={activeChips}
        onChangeFilter={onChangeFilter}
        {...rest}
      />
    ) : (
      <Typography
        className={classes.emptyText}
        variant={"body2"}
        color={"textSecondary"}>
        {D_JOBS_LIST.emptyFilters(title, query)}
      </Typography>
    );

    return needShowMore ? (
      <Collapse in={toggled} collapsedSize={collapsedSize}>
        {container}
      </Collapse>
    ) : (
      container
    );
  };

  return (
    <Flex direction={"column"}>
      {needAutocomplete && (
        <Box className={classes.inputWrapper}>
          <Input
            className={classes.input}
            value={query}
            placeholder={D_JOBS_LIST.autocompletePlaceholder}
            onChange={handleQueryChange}
            inputProps={{
              type: "skill",
            }}
          />
          {query.length > 0 && (
            <IconButton
              className={classes.clearQuery}
              size={"small"}
              onClick={clearQuery}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      )}
      {renderChipsContainer()}
      {needShowMore && (
        <Box>
          <Button onClick={toggle} variant={"text"}>
            {toggled ? D_JOBS_LIST.showLess : D_JOBS_LIST.showMore}
          </Button>
        </Box>
      )}
    </Flex>
  );
};

export default FilterBody;
