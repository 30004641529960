import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";

export const useStyles = makeStyles()((theme: AppTheme) => ({
  listItemText: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 700,
  },
  listItemIcon: {
    marginTop: -2,
    marginRight: 0,
    minWidth: 35,
    transform: "scale(0.8)",
  },
  listItem: {
    margin: theme.spacing(0, -2),
    width: `calc(100% + ${theme.spacing(4)})`,
  },
}));
