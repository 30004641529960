import { FC, memo } from "react";
import Flex from "@/common/components/Flex";
import IconString from "@/common/components/IconString";
import { Paper } from "@/common/components/Paper";
import { ReactComponent as LocationIcon } from "@/common/icons/location.svg";
import { formatLocations } from "@/common/utils/locationUtils";
import { Hidden, Typography } from "@mui/material";
import clsx from "clsx";
import Props from "./Props";
import JobPublished from "./components/JobPublished";
import { useStyles } from "./styles";

const JobCard: FC<Props> = (props: Props) => {
  const { job, className } = props;
  const { classes } = useStyles();

  return (
    <a href={job.url} target="_blank" rel="noopener noreferrer">
      <Paper className={clsx(classes.root, className)}>
        <Flex direction={"column"} className={classes.mainInfoSection}>
          <Flex alignVertical={"center"}>
            <Typography className={classes.companyName}>
              {job.company}
            </Typography>
          </Flex>
          <Flex className={classes.vacancyNameRow} alignVertical={"center"}>
            <Typography className={classes.vacancyName}>{job.title}</Typography>
          </Flex>
          <Flex className={classes.secondContainer}>
            <Flex direction={"column"}>
              <IconString
                icon={<LocationIcon />}
                string={formatLocations(
                  [job.country, job.state, job.city],
                  true,
                  3
                )}
              />
            </Flex>
            <Hidden smDown>
              <JobPublished
                updatedAt={+new Date(job.date)}
                className={classes.publishDate}
              />
            </Hidden>
          </Flex>
        </Flex>
      </Paper>
    </a>
  );
};

export default memo(JobCard);
