import React, { FC } from "react";

import { useStyles } from "./CookiesBanner.styles";
import { Typography } from "@mui/material";
import { useCookiesBanner } from "./useCookiesBanner";
import Button from "@/common/components/Button";
import Flex from "@/common/components/Flex";
import { Paper } from "@/common/components/Paper";
import { D_JOBS_LIST } from "@/i18n/dictionary/jobsList.dictionary";

const CookiesBanner: FC = () => {
  const { classes } = useStyles();
  const { visible, acceptCookies } = useCookiesBanner();

  return visible ? (
    <Paper className={classes.root}>
      <Flex
        alignVertical={"center"}
        alignHorizontal={"center"}
        direction={"column"}
        className={classes.container}>
        <Typography variant={"body2"} color={"textSecondary"}>
          {D_JOBS_LIST.message}
        </Typography>
        <Button
          className={classes.acceptButton}
          variant={"outlined"}
          onClick={acceptCookies}>
          {D_JOBS_LIST.acceptButton}
        </Button>
      </Flex>
    </Paper>
  ) : null;
};

export default CookiesBanner;
