import { FC } from "react";
import AppNavLink from "../AppNavLink";
import { AppRoute, getRouteWithSlash } from "@/router/AppRoute";
import { BreadcrumbsProps } from "./Breadcrumbs.types";
import { D_BREADCRUMBS } from "@/i18n/dictionary/breadcrumbs.dictionary";
import { useStyles } from "./Breadcrumbs.styles";

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ crumbs }) => {
  const { classes } = useStyles();

  return (
    <ul className={classes.root}>
      <li className={classes.link}>
        <AppNavLink
          className={classes.linkItem}
          to={getRouteWithSlash(AppRoute.HOME)}>
          {D_BREADCRUMBS.home}
        </AppNavLink>
      </li>
      {crumbs.map(({ title, url }) => (
        <li key={title} className={classes.link}>
          {url ? (
            <AppNavLink
              className={classes.linkItem}
              to={getRouteWithSlash(url)}>
              {title}
            </AppNavLink>
          ) : (
            title
          )}
        </li>
      ))}
    </ul>
  );
};
