const vacancyStatusDialog = Object.freeze({
  title: "Change the status of the vacancy",
  errorUpdateVacancyStatus:
    "An error occurred on update vacancy status. Please, try again later",
  successUpdateVacancyStatus: "Vacancy status has been updated successfully",
  statusSelectLabel: "Choose status",
  reasonSelectLabel: "Choose reason",
  formFieldComment: "Comment",
  formFieldReason: "Reason",
  CANDIDATE_HIRED: "Candidate successfully hired",
  CLOSED_BY_COMPANY: "Vacancy has been closed by company",
});

export default vacancyStatusDialog;

export type DVacancyStatusDialogType = Record<
  keyof typeof vacancyStatusDialog,
  string
>;
