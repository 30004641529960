const header = Object.freeze({
  offlineMode:
    "Estás usando la aplicación en modo offline. Algunas funcionalidades y datos pueden no estar accesibles o no ser actuales",
  publishVacancy: "Publicar Vacante",
  publishVacancyPermissions:
    "Por favor, inicia sesión o registra el perfil de tu empresa para publicar vacantes",
  publishCandidate: "Publicar Candidato",
  publishVacancySubtitle:
    "Rellena el formulario a continuación para registrar una empresa y publicar una vacante. " +
    "Si ya estás registrado, ",
  createCompany: "Crear Empresa",
  subscribeForVacancies: "Suscribirse a Vacantes",
  subscribeForCandidates: "Suscribirse a Candidatos",
  joinTelegram: "Unirse a Telegram",
  aboutUs: "Acerca de Nosotros",
  vacancies: "Empleos",
  faq: "Preguntas Frecuentes",
  perks: "Beneficios de Matchit",
  candidates: "Candidatos",
  login: "Iniciar sesión",
  register: "Registrar",
  companies: "Empresas",
  logout: "Cerrar sesión",
  dashboard: "Tablero",
  allVacancies: "Todas las vacantes",
  newRecommendationsCountTooltip: "Cantidad de nuevas recomendaciones",
  createCompanyAlertInfo:
    "Estás conectado a tu cuenta personal. Para usar esta función, necesitas crear un perfil de empresa",
});

export default header;
