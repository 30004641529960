import { Dictionary } from "@/i18n/dictionary.types";
import headerUA from "@/i18n/dictionary/ua/header";
import headerES from "@/i18n/dictionary/es/header";
import headerEN, { DHeaderType } from "@/i18n/dictionary/en/header";
import { t } from "@/i18n/t";

const HEADER: Dictionary<DHeaderType> = {
  ua: headerUA,
  en: headerEN,
  es: headerES,
};

export const D_HEADER = t(HEADER);
