const jobsList = Object.freeze({
  applyFilters: "Resultaten tonen",
  emptyFilters: (title: string, query: string) =>
    `${title} zoals "${query}" niet gevonden...`,
  autocompletePlaceholder: "Begin met typen...",
  title: "Filter",
  clear: "Wissen",
  yearLabel: "jaar",
  yearsLabel: "jaren",
  skillsTitle: "Vaardigheden",
  paidPlansTitle: "Type Vacature",
  companiesTitle: "Bedrijven",
  sliderApply: "OK",
  englishLevelTitle: "Engels Niveau",
  seniorityTitle: "Ervaringsniveau",
  locationTitle: "Locatie",
  countryTitle: "Land",
  stateTitle: "Regio/Staat",
  cityTitle: "Stad",
  typeTitle: "Type Werk",
  categoryTitle: "Categorie",
  experienceTitle: "Ervaring",
  budgetTitle: "Salaris",
  experienceMin: "Min",
  experienceMax: "Max",
  showMore: "Meer tonen",
  showLess: "Minder tonen",
  searchPlaceholder: "Begin met typen...",
  clearAll: "Alles wissen",
  found: "Gevonden",
  withFilters: (itemsName: string) => `${itemsName} met filters:`,
  withoutFilters: (itemsName: string) => itemsName,
  loadMore: "Meer laden",
  vacancies: "actieve vacatures",
  recommendations: "aanbevelingen",
  items: "items",
  companies: "bedrijven",
  candidates: "actieve kandidaten",
  jobs: "actieve banen",
  bannerTitle: "De toekomst van werving",
  emptyTitle: "Geen banen gevonden",
  emptySubtitle:
    "Er zijn momenteel geen banen die aan je filters voldoen." +
    " Probeer je filter aan te passen",
  pageTitle: "MATCHIT.ME Vacaturebord",
  description: `Zet de volgende stap in je carrière met AI-gebaseerde tools om je profiel te matchen met relevante banen. Elk niveau, elke locatie - alleen de beste aanbiedingen op de markt`,
  keywords:
    "Matchit, Djinni, SkyWorker, LinkedIn, vacature, bonus, IT, baan, baan zoeken",
  acceptButton: "Accepteren",
  message:
    'We gebruiken browsercookies voor enkele vereisten van de applicatie. Door op de knop "Accepteren" te klikken, ga je akkoord met het gebruik van cookies',
});

export default jobsList;
