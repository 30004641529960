import { PageLink } from "@/layout/common/components/Sidebar/components/SidebarNavigation/PageLink";
import { AppRoute } from "@/router/AppRoute";
import { D_MAIN_NAV } from "@/i18n/dictionary/mainNav.dictionary";

export const sidebarLinks: PageLink[] = [
  {
    path: AppRoute.HOME,
    title: D_MAIN_NAV.home,
    order: 10,
    exact: true,
  },
  {
    path: AppRoute.VACANCIES_LIST,
    title: D_MAIN_NAV.vacancies,
    order: 40,
  },
  {
    path: AppRoute.CANDIDATES_LIST,
    title: D_MAIN_NAV.candidates,
    order: 50,
  },
  {
    path: AppRoute.COMPANIES_LIST,
    title: D_MAIN_NAV.companies,
    order: 60,
  },
  {
    path: AppRoute.EXTERNAL,
    external: "https://www.matchit.me/matchit-perks",
    title: D_MAIN_NAV.perks,
    order: 70,
  },
];
