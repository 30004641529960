import { useCallback, useEffect, useMemo } from "react";
import { ChipItem } from "@/common/components/ChipsContainer/Props";
import { FILTER_ITEMS_LIMIT as limit } from "@/config";
import { useJobsTypesQuery } from "@/common/components/Filter/components/JobsTypeFilter/JobsTypeFilter.operations.generated";
import JobsTypeFilterProps from "@/common/components/Filter/components/JobsTypeFilter/JobsTypeFilter.types";

export function useJobsTypeFilter(props: JobsTypeFilterProps) {
  const { applyFilter, filter, clearFilter } = props.context;
  const {
    data: typesData,
    fetchMore,
    refetch: refetchTypes,
  } = useJobsTypesQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      offset: 0,
      limit,
      query: "",
    },
  });

  const typesTotalCount = typesData?.jobsTypes.count || 0;

  const allTypes = useMemo(
    () => typesData?.jobsTypes.entities || [],
    [typesData?.jobsTypes.entities]
  );

  const activeTypes = filter.jobType;

  const refetchWithQuery = useCallback(
    (query: string) => {
      refetchTypes({ offset: 0, limit, query });
    },
    [refetchTypes]
  );

  const handleChangeTypeFilter = useCallback(
    (type: ChipItem) => {
      applyFilter("jobType", type);
    },
    [applyFilter]
  );

  const handleClear = useCallback(() => {
    clearFilter("jobType");
  }, [clearFilter]);

  useEffect(() => {
    refetchWithQuery("");
  }, [refetchWithQuery]);

  const loadMore = useCallback(
    (query: string) => {
      fetchMore({
        variables: {
          offset: allTypes.length,
          limit,
          query,
        },
      });
    },
    [allTypes.length, fetchMore]
  );

  return {
    allTypes,
    activeTypes,
    handleChangeTypeFilter,
    handleClear,
    clearDisabled: !activeTypes.length,
    loadMore,
    typesTotalCount,
    handleQueryChange: refetchWithQuery,
  };
}
