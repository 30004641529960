import React, { FC, useMemo } from "react";

import { useStyles } from "./styles";
import StyledSelectPicker from "../StyledSelectPicker";
import { useLanguagePicker } from "./useLanguagePicker";

const LanguagePicker: FC = () => {
  const classes = useStyles();
  const { options, onChangeLocality, value } = useLanguagePicker();

  const InputProps = useMemo(
    () => ({
      classes: {
        root: classes.input,
      },
    }),
    [classes.input]
  );

  const SelectProps = useMemo(
    () => ({
      classes: {
        root: classes.select,
        icon: classes.selectIcon,
      },
    }),
    [classes.select, classes.selectIcon]
  );

  return (
    <StyledSelectPicker
      options={options}
      variant={"outlined"}
      value={value}
      className={classes.root}
      InputProps={InputProps}
      SelectProps={SelectProps}
      onChange={onChangeLocality}
    />
  );
};

export default LanguagePicker;
