import { FC, lazy, ReactNode, Suspense } from "react";
import VacanciesGrid from "./components/JobsGrid";
import JobsGrid from "./components/JobsGrid";
import { useJobs } from "./useJobs";
import Flex from "@/common/components/Flex";
import { useStyles } from "./styles";
import ActiveFilters from "@/common/components/ActiveFilters";
import SearchField from "@/common/components/SearchField";
import Head from "@/common/components/Head";
import EmptySearchResult from "@/common/components/EmptySearchResult";
import InfiniteScroll from "@/common/components/InfiniteScroll";
import PublicLayout from "@/layout/components/PublicLayout";
import Loader from "@/common/components/loaders/Loader";
import { Breadcrumbs } from "@/common/components/Breadcrumbs";
import { D_JOBS_LIST } from "@/i18n/dictionary/jobsList.dictionary";
import { ScrollToTop } from "@/common/components/ScrollToTop";
import { LoadMoreButton } from "@/common/components/LoadMoreButton";
import JobsFilter from "@/pages/public/Jobs/components/JobsFilter";
import { defaultJobsFilter } from "@/pages/public/Jobs/components/JobsFilter/components/JobsFilterContextProvider/defaultJobsFilter";
import { isOnePage } from "@/config";
import clsx from "clsx";

const SocialBanner = lazy(() => import("@/common/components/SocialBanner"));

const suspense = (children: ReactNode) => (
  <Suspense fallback={<Loader />}>{children}</Suspense>
);

const Jobs: FC = () => {
  const {
    vacancies,
    loading,
    jobsQty,
    vacanciesFetched,
    handleLoadMoreVacancies,
    handleChangeSearchQuery,
    context,
    jobsCrumbs,
    isShowLoadMoreButton,
  } = useJobs();
  const { classes } = useStyles();

  return (
    <PublicLayout minimal={isOnePage}>
      {!isOnePage && <Breadcrumbs crumbs={jobsCrumbs} />}
      <ScrollToTop />
      <Flex className={classes.root}>
        <Head
          title={D_JOBS_LIST.pageTitle}
          description={D_JOBS_LIST.description}
          keywords={D_JOBS_LIST.keywords}
        />
        {suspense(<JobsFilter />)}
        <Flex
          direction={"column"}
          className={clsx(
            classes.container,
            isOnePage && classes.onepageContainer
          )}>
          <Flex alignVertical={"center"} className={classes.actionsContainer}>
            <SearchField
              className={classes.searchField}
              onChange={handleChangeSearchQuery}
            />
          </Flex>
          <ActiveFilters
            className={classes.activeFilters}
            itemsName={"jobs"}
            itemsQty={jobsQty}
            context={context}
            defaultFilter={defaultJobsFilter}
          />
          <InfiniteScroll
            isLoading={loading}
            length={vacancies.length}
            onBottomHit={handleLoadMoreVacancies}
            contentLoader={<VacanciesGrid jobs={[]} loading={loading} />}>
            <JobsGrid jobs={vacancies} loading={loading} />
          </InfiniteScroll>
          <Flex alignHorizontal={"center"}>
            {jobsQty === vacancies.length && !loading && vacanciesFetched ? (
              vacancies.length ? null : (
                <EmptySearchResult
                  title={D_JOBS_LIST.emptyTitle}
                  subtitle={D_JOBS_LIST.emptySubtitle}
                  withoutButton
                />
              )
            ) : (
              isShowLoadMoreButton && (
                <LoadMoreButton onClick={handleLoadMoreVacancies} />
              )
            )}
          </Flex>
          {suspense(<SocialBanner className={classes.banner} />)}
        </Flex>
      </Flex>
    </PublicLayout>
  );
};

export default Jobs;
