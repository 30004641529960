import React, { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import { useDropdown } from "./useDropdown";
import clsx from "clsx";

const Dropdown: FC<Props> = (props: Props) => {
  const { icon, children, className, classes, ...rest } = props;
  const { visible, toggleTooltip, handleClose } = useDropdown(props);
  const { classes: defaultClasses } = useStyles();

  return (
    <div
      className={clsx(defaultClasses.root, className)}
      onClick={toggleTooltip}
      {...rest}>
      <div
        className={clsx(defaultClasses.iconContainer, classes?.iconContainer)}>
        {icon}
      </div>
      {visible && (
        <ClickAwayListener onClickAway={handleClose}>
          {children}
        </ClickAwayListener>
      )}
    </div>
  );
};

export default Dropdown;
