import { ClassNameMap } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { AppTheme } from "@/theme";

export type ClassNames = "root" | "logoText";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: AppTheme) =>
    createStyles({
      root: {},
      logoText: {
        fontWeight: 600,
        color: "#314B66",
        fontSize: 18,
        marginLeft: theme.spacing(1),
      },
    })
);
