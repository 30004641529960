import { Dictionary } from "@/i18n/dictionary.types";
import recommendatorProfileUA from "@/i18n/dictionary/ua/recommendatorProfile";
import recommendatorProfileEN, {
  DRecommendatorProfileType,
} from "@/i18n/dictionary/en/recommendatorProfile";
import { t } from "@/i18n/t";

const RECOMMENDATOR_PROFILE: Dictionary<DRecommendatorProfileType> = {
  ua: recommendatorProfileUA,
  en: recommendatorProfileEN,
};

export const D_RECOMMENDATOR_PROFILE = t(RECOMMENDATOR_PROFILE);
