import React, { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";
import clsx from "clsx";

const ContentWrapper: FC<Props> = (props: Props) => {
  const { children, className, ...rest } = props;

  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {children}
    </div>
  );
};

export default ContentWrapper;
