import { FC } from "react";

import { ReactComponent as NewRecommendationsIcon } from "@/common/icons/new-recommendations.svg";
import { useNewRecommendationsCount } from "./useNewRecommendationsCount";
import { IconButton, Tooltip } from "@mui/material";
import { useStyles } from "./NewRecommendationsCount.styles";
import { D_HEADER } from "@/i18n/dictionary/header.dictionary";

export const NewRecommendationsCount: FC = () => {
  const { newRecommendationsCount, isCounterVisible, goToRecommendationsPage } =
    useNewRecommendationsCount();
  const { classes } = useStyles();

  return (
    <Tooltip title={D_HEADER.newRecommendationsCountTooltip}>
      <IconButton
        className={classes.root}
        size={"small"}
        onClick={goToRecommendationsPage}>
        {isCounterVisible && (
          <span className={classes.counter}>{newRecommendationsCount}</span>
        )}
        <NewRecommendationsIcon />
      </IconButton>
    </Tooltip>
  );
};
