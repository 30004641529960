import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";

export const useStyles = makeStyles()((theme: AppTheme) => ({
  root: {
    zIndex: 3,
  },
  textField: {
    backgroundColor: "#F8F8F9",
    marginTop: 0,
    zIndex: 3,
  },
  notchedOutline: {
    borderColor: "#E4E7EF",
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
  input: {
    padding: "12px",
  },
}));
