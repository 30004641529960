import { AppRoute, getRouteWithSlash } from "@/router/AppRoute";
import { useNewRecommendationsCountQuery } from "./NewRecommendationsCount.operations.generated";
import { useHistory } from "react-router-dom";
import { useIsAdmin } from "@/auth/hooks/useIsAdmin";
import { useSearchParams } from "@/common/hooks/useSearchParams";
import { RecommendationStatus } from "@/common/models/RecommendationStatus";
import { formatRecommendationStatus } from "@/common/utils/searchEnginneUtils";
import { SearchKeys } from "@/common/models/SearchKeys";

export function useNewRecommendationsCount() {
  const { data } = useNewRecommendationsCountQuery({
    fetchPolicy: "cache-and-network",
  });
  const history = useHistory();
  const isAdmin = useIsAdmin();
  const { updateSearch } = useSearchParams();
  const newRecommendationsCount = data?.newRecommendationsCount || 0;

  const parsStatusNew = {
    id: RecommendationStatus.NEW,
    name: formatRecommendationStatus(RecommendationStatus.NEW),
  };

  const refreshSearch = () => {
    const searchKey = SearchKeys.STATUS;

    updateSearch(searchKey);
    updateSearch(searchKey, parsStatusNew);
  };

  const goToRecommendationsPage = () => {
    history.push(
      getRouteWithSlash(
        isAdmin
          ? AppRoute.ADMIN_RECOMMENDATIONS
          : AppRoute.COMPANY_RECOMMENDATIONS
      )
    );
    newRecommendationsCount && refreshSearch();
  };

  return {
    goToRecommendationsPage,
    newRecommendationsCount,
    isCounterVisible: newRecommendationsCount > 0,
  };
}
