import React, { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";
import Typography from "@mui/material/Typography";
import Button from "../Button";
import Flex from "../Flex";
import clsx from "clsx";

const EmptySearchResult: FC<Props> = (props: Props) => {
  const {
    title,
    subtitle,
    action,
    actionButtonText,
    actionButton,
    className,
    small,
    withoutButton,
  } = props;
  const classes = useStyles();

  return (
    <Flex
      direction={"column"}
      alignVertical={"center"}
      alignHorizontal={"center"}
      className={clsx(classes.root, className)}>
      <img
        alt={"Empty search result"}
        src={"/images/empty-search-result.svg"}
        className={clsx(
          classes.imageContainer,
          small && classes.imageContainerSmall
        )}
      />
      <Typography
        variant={"h2"}
        className={clsx(classes.title, small && classes.titleSmall)}>
        {title}
      </Typography>
      <Typography
        className={clsx(classes.subtitle, small && classes.subtitleSmall)}>
        {subtitle}
      </Typography>
      {!withoutButton
        ? actionButton || (
            <Button
              variant={"contained"}
              onClick={action}
              className={classes.button}>
              {actionButtonText}
            </Button>
          )
        : null}
    </Flex>
  );
};

export default EmptySearchResult;
