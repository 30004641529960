import { lazy, Suspense, useCallback } from "react";
import Loader from "@/common/components/loaders/Loader";

type DialogName =
  | "subscribe"
  | "forgotPassword"
  | "vacancyCreate"
  | "rejectRecommendations"
  | "login"
  | "registerDialog"
  | "feedback"
  | "comment"
  | "profileSwitch"
  | "applyJob"
  | "companyWithParsedVacancies"
  | "proposeBonus"
  | "problemReport"
  | "uploadCvByRecruiter"
  | "uploadCvByCandidate"
  | "uploadCvWithRef"
  | "unlockCandidate"
  | "matchedJob";

type LazyDialog = Record<DialogName, () => Promise<any>>;

const LAZY_DIALOGS: LazyDialog = {
  login: () => import("../../auth/components/LoginDialog"),
  forgotPassword: () => import("@/sections/ForgotPasswordDialog"),
  subscribe: () => import("../components/SubscribeDialog"),
  vacancyCreate: () => import("../../sections/VacancyCreateDialog"),
  rejectRecommendations: () =>
    import("../components/RejectRecommendationsDialog"),
  feedback: () => import("../components/AIFeedbackDialog"),
  comment: () => import("../components/CommentDialog"),
  profileSwitch: () => import("../components/ProfileSwitchDialog"),
  applyJob: () =>
    import("@/pages/public/JobView/components/ApplyJobDialog/ApplyJobDialog"),
  matchedJob: () =>
    import(
      "@/pages/public/JobView/components/MatchedJobDialog/MatchedJobDialog"
    ),
  companyWithParsedVacancies: () =>
    import("../components/CompanyWithParsedVacanciesDialog"),
  proposeBonus: () => import("../components/ProposeBonusDialog"),
  problemReport: () => import("../components/ProblemReportDialog"),
  uploadCvByRecruiter: () =>
    import("@/sections/UploadCvDialog/UploadCvByRecruiterDialog"),
  uploadCvByCandidate: () =>
    import("@/sections/UploadCvDialog/UploadCvByCandidateDialog"),
  uploadCvWithRef: () =>
    import("@/sections/UploadCvDialog/UploadCvWithRefDialog"),
  unlockCandidate: () => import("../components/UnlockCandidateDialog"),
  registerDialog: () => import("../components/RegistrationDialog"),
};

type UseRenderDialogProps<T> = {
  visible: boolean;
  dialogName: DialogName;
  dialogProps: T;
};

export function useRenderDialog<T>({
  dialogProps,
  dialogName,
  visible,
}: UseRenderDialogProps<T>) {
  const renderDialog = useCallback(() => {
    const Dialog = lazy(LAZY_DIALOGS[dialogName]);

    return (
      <Suspense fallback={<Loader />}>
        <Dialog {...dialogProps} />
      </Suspense>
    );
  }, [dialogName, dialogProps]);

  return useCallback(
    () => (visible ? renderDialog() : null),
    [visible, renderDialog]
  );
}
