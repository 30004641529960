import Props from "./Props";
import { useForm } from "../../hooks/useForm";
import { defaultForm, Field, schema } from "./form";
import { ChangeEvent, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { COMPANY_CONNECT, CompanyConnectData, CompanyConnectVars } from "./gql";
import { pushGTMEvent } from "../../utils/gtmUtils";
import { showAlert } from "@/common/utils/customEvents";
import { useScrollToErrorRef } from "@/common/hooks/useScrollToErrorRef";
import { D_COMPANY_CONNECT } from "@/i18n/dictionary/companyConnect.dictionary";

export function useCompanyConnectDialog(props: Props) {
  const { onClose, comment } = props;
  const { childRef, scrollToError } = useScrollToErrorRef();

  const { form, setAndValidate, validateForm } = useForm(schema, defaultForm);
  const [sendCompanyConnectRequest, { loading }] = useMutation<
    CompanyConnectData,
    CompanyConnectVars
  >(COMPANY_CONNECT);

  useEffect(() => {
    if (comment !== undefined) {
      setAndValidate("comment", comment);
    }
  }, [comment, setAndValidate]);

  const acceptAction = async () => {
    if (!validateForm()) {
      scrollToError();

      return;
    }

    try {
      await sendCompanyConnectRequest({
        variables: {
          ...form.values,
          referralCode: localStorage.getItem("referralCode") || undefined,
        },
      });
      showAlert(D_COMPANY_CONNECT.messageSuccess, "success");
      pushGTMEvent({ event: "publish_vacancy_form_submit_success" });
    } catch (e) {
      showAlert(D_COMPANY_CONNECT.messageError, "error");
      pushGTMEvent({ event: "publish_vacancy_form_submit_error" });
    } finally {
      onClose();
    }
  };

  const handleChangeFormField = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name: key, value } = event.target;

    setAndValidate(key as Field, value);
  };

  return { acceptAction, form, handleChangeFormField, loading, childRef };
}
