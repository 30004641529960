import { Dictionary } from "@/i18n/dictionary.types";
import mainNavUA from "@/i18n/dictionary/ua/mainNav";
import mainNavEN, { DMainNavType } from "@/i18n/dictionary/en/mainNav";
import { t } from "@/i18n/t";

const MAIN_NAV: Dictionary<DMainNavType> = {
  ua: mainNavUA,
  en: mainNavEN,
};

export const D_MAIN_NAV = t(MAIN_NAV);
