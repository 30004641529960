import React, { FC } from "react";

import { ProfileDropdownActionsListItemProps } from "./ProfileDropdownActionsListItem.types";
import { useStyles } from "./ProfileDropdownActionsListItem.styles";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import NewLabel from "@/common/components/NewLabel";

const ProfileDropdownActionsListItem: FC<
  ProfileDropdownActionsListItemProps
> = (props) => {
  const { actionIcon, actionName, actionCallback, isNew } = props;
  const { classes } = useStyles();

  return (
    <ListItemButton
      className={classes.listItem}
      key={actionName}
      onClick={actionCallback}>
      <ListItemIcon className={classes.listItemIcon}>{actionIcon}</ListItemIcon>
      <ListItemText
        classes={{ primary: classes.listItemText }}
        primary={actionName}
      />
      {isNew && <NewLabel />}
    </ListItemButton>
  );
};

export default ProfileDropdownActionsListItem;
