import { gql } from "@apollo/client";
import { User } from "@/common/models/User";

export interface GetUserCompanyIdData {
  getUser: User;
}

export const GET_USER_COMPANY_ID = gql`
  query GetUser {
    getUser {
      companyId
      id
    }
  }
`;
