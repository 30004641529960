import { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";
import Flex from "@/common/components/Flex";
import { AppRoute, getRouteWithSlash } from "../../../../router/AppRoute";
import { Link, Typography } from "@mui/material";
import AppNavLink from "@/common/components/AppNavLink";
import { wrapContent } from "@/common/utils/reactComponentUtils";
import { useFooter } from "./useFooter";
import { D_FOOTER } from "@/i18n/dictionary/footer.dictionary";
import { getFaqLink } from "@/common/utils/navUtils";

const Footer: FC<Props> = (props: Props) => {
  const { classes } = useStyles();
  const { fullWidth } = props;
  const { localityLinkName, localityUrl } = useFooter();

  return (
    <footer className={classes.root}>
      {wrapContent(
        <Flex alignHorizontal={"space-between"} className={classes.content}>
          <Flex className={classes.linksContainer}>
            <AppNavLink
              className={classes.link}
              variant={"secondary"}
              to={getRouteWithSlash(AppRoute.VACANCIES_LIST)}>
              {D_FOOTER.vacancies}
            </AppNavLink>
            <AppNavLink
              className={classes.link}
              variant={"secondary"}
              to={getRouteWithSlash(AppRoute.CANDIDATES_LIST)}>
              {D_FOOTER.candidates}
            </AppNavLink>
            <Link
              className={classes.link}
              href={getRouteWithSlash(AppRoute.PRIVACY_POLICY, true)}
              target={"_blank"}
              rel={"noreferrer"}>
              {D_FOOTER.privacyPolicy}
            </Link>
            <Link
              className={classes.link}
              href={getRouteWithSlash(AppRoute.USER_AGREEMENT, true)}
              target={"_blank"}
              rel={"noreferrer"}>
              {D_FOOTER.userAgreement}
            </Link>
            <Link
              className={classes.link}
              href={getFaqLink()}
              target={"_blank"}
              rel={"noreferrer"}>
              {D_FOOTER.faq}
            </Link>
            {/* <AppNavLink
              className={classes.link}
              variant={"secondary"}
              to={getRouteWithSlash(AppRoute.PRICING)}>
              {D_FOOTER.pricing}
            </AppNavLink>*/}
            <Link
              className={classes.link}
              href={process.env.REACT_APP_TELEGRAM_URL}
              target={"_blank"}
              rel={"noreferrer"}>
              {D_FOOTER.telegram}
            </Link>
            <Link className={classes.link} href={localityUrl}>
              {localityLinkName}
            </Link>
          </Flex>
          <Typography className={classes.copyright}>
            {D_FOOTER.copyright}
          </Typography>
        </Flex>,
        fullWidth
      )}
    </footer>
  );
};

export default Footer;
