import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";

export const useStyles = makeStyles()((theme: AppTheme) => ({
  root: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
  secondary: {
    color: "#747474",
    fontSize: theme.typography.pxToRem(14),
  },
  primary: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
    color: "#458CFF",
  },
}));
