import * as Types from "../../../../../generated/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type JobsCompaniesQueryVariables = Types.Exact<{
  limit: Types.Scalars["Float"];
  offset: Types.Scalars["Float"];
  query: Types.Scalars["String"];
}>;

export type JobsCompaniesQuery = {
  __typename?: "Query";
  jobsCompanies: {
    __typename?: "EntitiesWithCountForSelect";
    count: number;
    entities: Array<{ __typename?: "Entity"; id: string; name: string }>;
  };
};

export const JobsCompaniesDocument = gql`
  query JobsCompanies($limit: Float!, $offset: Float!, $query: String!) {
    jobsCompanies(limit: $limit, offset: $offset, query: $query) {
      entities {
        id
        name
      }
      count
    }
  }
`;

/**
 * __useJobsCompaniesQuery__
 *
 * To run a query within a React component, call `useJobsCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobsCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobsCompaniesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useJobsCompaniesQuery(
  baseOptions: Apollo.QueryHookOptions<
    JobsCompaniesQuery,
    JobsCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<JobsCompaniesQuery, JobsCompaniesQueryVariables>(
    JobsCompaniesDocument,
    options
  );
}
export function useJobsCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobsCompaniesQuery,
    JobsCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<JobsCompaniesQuery, JobsCompaniesQueryVariables>(
    JobsCompaniesDocument,
    options
  );
}
export type JobsCompaniesQueryHookResult = ReturnType<
  typeof useJobsCompaniesQuery
>;
export type JobsCompaniesLazyQueryHookResult = ReturnType<
  typeof useJobsCompaniesLazyQuery
>;
export type JobsCompaniesQueryResult = Apollo.QueryResult<
  JobsCompaniesQuery,
  JobsCompaniesQueryVariables
>;
