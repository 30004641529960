import * as Types from "../../../../../generated/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type JobsTypesQueryVariables = Types.Exact<{
  limit: Types.Scalars["Float"];
  offset: Types.Scalars["Float"];
  query: Types.Scalars["String"];
}>;

export type JobsTypesQuery = {
  __typename?: "Query";
  jobsTypes: {
    __typename?: "EntitiesWithCountForSelect";
    count: number;
    entities: Array<{ __typename?: "Entity"; id: string; name: string }>;
  };
};

export const JobsTypesDocument = gql`
  query JobsTypes($limit: Float!, $offset: Float!, $query: String!) {
    jobsTypes(limit: $limit, offset: $offset, query: $query) {
      entities {
        id
        name
      }
      count
    }
  }
`;

/**
 * __useJobsTypesQuery__
 *
 * To run a query within a React component, call `useJobsTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobsTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobsTypesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useJobsTypesQuery(
  baseOptions: Apollo.QueryHookOptions<JobsTypesQuery, JobsTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<JobsTypesQuery, JobsTypesQueryVariables>(
    JobsTypesDocument,
    options
  );
}
export function useJobsTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobsTypesQuery,
    JobsTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<JobsTypesQuery, JobsTypesQueryVariables>(
    JobsTypesDocument,
    options
  );
}
export type JobsTypesQueryHookResult = ReturnType<typeof useJobsTypesQuery>;
export type JobsTypesLazyQueryHookResult = ReturnType<
  typeof useJobsTypesLazyQuery
>;
export type JobsTypesQueryResult = Apollo.QueryResult<
  JobsTypesQuery,
  JobsTypesQueryVariables
>;
