import { FC } from "react";

import JobsStateFilterProps from "./JobsStateFilter.types";
import { useJobsStateFilter } from "./useJobsStateFilter";
import FilterContainer from "../../common/components/FilterContainer";
import FilterBodyPaginated from "@/common/components/Filter/common/components/FilterContainer/components/FilterBodyPaginated";
import { D_JOBS_LIST } from "@/i18n/dictionary/jobsList.dictionary";

const StateFilter: FC<JobsStateFilterProps> = (props: JobsStateFilterProps) => {
  const { className, ...rest } = props;
  const {
    handleChangeStateFilter,
    allStates,
    activeStates,
    statesTotalCount,
    handleClear,
    loadMore,
    handleQueryChange,
    clearDisabled,
  } = useJobsStateFilter(props);

  return (
    <FilterContainer
      title={D_JOBS_LIST.stateTitle}
      clearDisabled={clearDisabled}
      onClear={handleClear}
      chips={allStates}
      totalChipsQty={statesTotalCount}
      activeChips={activeStates}
      onChangeFilter={handleChangeStateFilter}
      {...rest}>
      <FilterBodyPaginated
        title={D_JOBS_LIST.stateTitle}
        chips={allStates}
        activeChips={activeStates}
        onChangeFilter={handleChangeStateFilter}
        loadMore={loadMore}
        onQueryChange={handleQueryChange}
        totalChipsQty={statesTotalCount}
      />
    </FilterContainer>
  );
};

export default StateFilter;
