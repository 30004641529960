import { useCallback } from "react";
import { AppRoute, getRouteWithSlash } from "@/router/AppRoute";
import { useHistory } from "react-router-dom";
import { useNewChatMessagesCountQuery } from "@/pages/chat/components/ChatMessagesIconButton/ChatMessagesIconButton.operations.generated";

export function useChatMessagesIconButton() {
  const history = useHistory();
  const { data } = useNewChatMessagesCountQuery({
    fetchPolicy: "cache-and-network",
  });
  const newMessagesCount = data?.newMessagesCount || 0;

  const goToChatPage = useCallback(() => {
    history.push(getRouteWithSlash(AppRoute.CHAT));
  }, [history]);

  return {
    goToChatPage,
    newMessagesCount,
    isCounterVisible: newMessagesCount > 0,
  };
}
