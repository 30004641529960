const header = Object.freeze({
  offlineMode:
    "You are using the app in offline mode. Some functionality and data may be not accessible or not actual",
  publishVacancy: "Publish Vacancy",
  publishVacancyPermissions:
    "Please sign in or register your company profile to publish vacancies",
  publishCandidate: "Publish Candidate",
  publishVacancySubtitle:
    "Fill out the form below to register a company and post a vacancy. " +
    "If already registered, ",
  createCompany: "Create Company",
  subscribeForVacancies: "Subscribe For Vacancies",
  subscribeForCandidates: "Subscribe For Candidates",
  joinTelegram: "Join Telegram",
  aboutUs: "About Us",
  vacancies: "Jobs",
  faq: "FAQ",
  perks: "Matchit perks",
  candidates: "Candidates",
  login: "Login",
  register: "Register",
  companies: "Companies",
  logout: "Logout",
  dashboard: "Dashboard",
  allVacancies: "All vacancies",
  newRecommendationsCountTooltip: "New recommendations count",
  createCompanyAlertInfo:
    "You are logged in to your personal account. To use this function, you need to create a company profile",
});

export default header;

export type DHeaderType = Record<keyof typeof header, string>;
