import { scrollToInputWithError } from "@/common/utils/formUtils";
import { FormEvent, useImperativeHandle, useRef } from "react";
import { FormProps } from "./Form.types";

export const useForm = (props: FormProps, ref: React.ForwardedRef<unknown>) => {
  const { formErrors } = props;
  const formRef = useRef<HTMLFormElement | null>(null);

  useImperativeHandle(ref, () => ({
    scrollToInputWithError: () =>
      scrollToInputWithError(formRef.current, formErrors),
  }));

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (props.onSubmit) {
      props.onSubmit();
    }
  };

  return { formRef, handleSubmit };
};
