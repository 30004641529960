import * as Types from "../../../../../generated/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type JobsCountriesQueryVariables = Types.Exact<{
  limit: Types.Scalars["Float"];
  offset: Types.Scalars["Float"];
  query: Types.Scalars["String"];
}>;

export type JobsCountriesQuery = {
  __typename?: "Query";
  jobsCountries: {
    __typename?: "EntitiesWithCountForSelect";
    count: number;
    entities: Array<{ __typename?: "Entity"; id: string; name: string }>;
  };
};

export const JobsCountriesDocument = gql`
  query JobsCountries($limit: Float!, $offset: Float!, $query: String!) {
    jobsCountries(limit: $limit, offset: $offset, query: $query) {
      entities {
        id
        name
      }
      count
    }
  }
`;

/**
 * __useJobsCountriesQuery__
 *
 * To run a query within a React component, call `useJobsCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobsCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobsCountriesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useJobsCountriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    JobsCountriesQuery,
    JobsCountriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<JobsCountriesQuery, JobsCountriesQueryVariables>(
    JobsCountriesDocument,
    options
  );
}
export function useJobsCountriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobsCountriesQuery,
    JobsCountriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<JobsCountriesQuery, JobsCountriesQueryVariables>(
    JobsCountriesDocument,
    options
  );
}
export type JobsCountriesQueryHookResult = ReturnType<
  typeof useJobsCountriesQuery
>;
export type JobsCountriesLazyQueryHookResult = ReturnType<
  typeof useJobsCountriesLazyQuery
>;
export type JobsCountriesQueryResult = Apollo.QueryResult<
  JobsCountriesQuery,
  JobsCountriesQueryVariables
>;
