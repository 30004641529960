const recommendationRejectionReasonShort = Object.freeze({
  CANT_CONTACT: "Контакти",
  CANDIDATE_REFUSED: "Відхилено",
  CANDIDATE_ALREADY_REVIEWED: "Розглянута",
  VACANCY_NOT_ACTUAL: "Неактуальна",
  CANDIDATE_NOT_MEET_REQUIREMENTS: "Вимоги",
  CANDIDATE_REFUSED_AFTER_INTERVIEW: "Співбесіда",
  CANDIDATE_REFUSED_AFTER_OFFER: "Офер",
  CANDIDATE_ACCEPT_ANOTHER_OFFER: "Інший Офер",
});

export default recommendationRejectionReasonShort;
