import { FC } from "react";
import Button from "../Button";
import { LoadMoreButtonProps } from "./LoadMoreButton.types";
import { useStyles } from "./LoadMoreButton.styles";
import { D_JOBS_LIST } from "@/i18n/dictionary/jobsList.dictionary";

export const LoadMoreButton: FC<LoadMoreButtonProps> = ({ onClick }) => {
  const { classes } = useStyles();

  return (
    <Button onClick={onClick} variant={"contained"} className={classes.button}>
      {D_JOBS_LIST.loadMore}
    </Button>
  );
};
