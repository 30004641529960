import { useCallback, useEffect, useMemo } from "react";
import { ChipItem } from "@/common/components/ChipsContainer/Props";
import { FILTER_ITEMS_LIMIT as limit } from "@/config";
import { useJobsStatesQuery } from "@/common/components/Filter/components/JobsStateFilter/JobsStateFilter.operations.generated";
import JobsStateFilterProps from "@/common/components/Filter/components/JobsStateFilter/JobsStateFilter.types";

export function useJobsStateFilter(props: JobsStateFilterProps) {
  const { applyFilter, filter, clearFilter } = props.context;
  const {
    data: statesData,
    fetchMore,
    refetch: refetchStates,
  } = useJobsStatesQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      offset: 0,
      limit,
      query: "",
    },
  });

  const statesTotalCount = statesData?.jobsStates.count || 0;

  const allStates = useMemo(
    () => statesData?.jobsStates.entities || [],
    [statesData?.jobsStates.entities]
  );

  const activeStates = filter.state;

  const refetchWithQuery = useCallback(
    (query: string) => {
      refetchStates({ offset: 0, limit, query });
    },
    [refetchStates]
  );

  const handleChangeStateFilter = useCallback(
    (state: ChipItem) => {
      applyFilter("state", state);
    },
    [applyFilter]
  );

  const handleClear = useCallback(() => {
    clearFilter("state");
  }, [clearFilter]);

  useEffect(() => {
    refetchWithQuery("");
  }, [refetchWithQuery]);

  const loadMore = useCallback(
    (query: string) => {
      fetchMore({
        variables: {
          offset: allStates.length,
          limit,
          query,
        },
      });
    },
    [allStates.length, fetchMore]
  );

  return {
    allStates,
    activeStates,
    handleChangeStateFilter,
    handleClear,
    clearDisabled: !activeStates.length,
    loadMore,
    statesTotalCount,
    handleQueryChange: refetchWithQuery,
  };
}
