import React, { FC } from "react";

import { useStyles } from "./styles";
import { Typography } from "@mui/material";

const NewLabel: FC = () => {
  const classes = useStyles();

  return <Typography className={classes.root}>NEW</Typography>;
};

export default NewLabel;
