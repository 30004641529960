import { useCallback, useState } from "react";

import { validate, ValidationRules } from "../utils/validation";

export type BaseFormField = string;

export interface UseFormTypes<S, F> {
  form: F;
  setAndValidate: (key: keyof S, value: string) => void;
  validateForm: () => boolean;
  setFormValue: (key: keyof S, value: string) => void;
  setFormValues: (
    values: Partial<{ [key in keyof S]: string }>,
    skipNonEmpty?: boolean
  ) => void;
  clearFormError: (key: keyof S) => void;
  resetField: (key: keyof S) => void;
  resetForm: () => void;
}

export interface BaseForm {
  values: Record<BaseFormField, string>;
  errors: Record<BaseFormField, string>;
  isValid: boolean;
}

export interface Form<T extends BaseFormField> extends BaseForm {
  values: Record<T, string>;
  errors: Record<T, string>;
  isValid: boolean;
}

export type BaseFormSchema = Record<string, ValidationRules>;

export type FormSchema<T extends BaseFormField> = Record<T, ValidationRules>;

export function useForm<S extends BaseFormSchema, F extends BaseForm>(
  schema: S,
  defaultForm: F
): UseFormTypes<S, F> {
  const [form, setForm] = useState(defaultForm);

  const setValue = useCallback(
    (prevForm: F, key: keyof S, value: string): F => {
      return {
        ...prevForm,
        values: {
          ...prevForm.values,
          [key]: value,
        },
      };
    },
    []
  );

  const setValues = useCallback(
    (
      prevForm: F,
      values: Partial<{ [key in keyof S]: string }>,
      skipNonEmpty?: boolean
    ): F => {
      const newValues = { ...values };

      if (skipNonEmpty) {
        for (const key in newValues) {
          if (!newValues[key]) {
            delete newValues[key];
          }
        }
      }

      return {
        ...prevForm,
        values: {
          ...prevForm.values,
          ...newValues,
        },
      };
    },
    []
  );

  const validateField = useCallback(
    (key: keyof S, value: string, newForm: F): F => {
      const error: string = validate(key, value, schema, newForm);

      newForm = {
        ...newForm,
        isValid: false,
        errors: {
          ...newForm.errors,
          [key]: error,
        },
      };

      newForm.isValid = !Object.values(newForm.errors).some(
        (err: string) => !!err
      );

      return newForm;
    },
    [schema]
  );

  const validateForm = useCallback((): boolean => {
    const newForm = { ...form };
    const values = form.values;

    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        newForm.errors[key] = validate(key, values[key], schema, newForm);
      }
    }

    newForm.isValid = !Object.values(newForm.errors).some(
      (err: string) => !!err
    );

    setForm(newForm);

    return newForm.isValid;
  }, [schema, form]);

  const setAndValidate = useCallback(
    (key: keyof S, value: string) => {
      setForm((prevForm) => {
        if (
          key === "password" &&
          "repeatPassword" in prevForm.values &&
          !!prevForm.values.repeatPassword
        ) {
          return validateField(
            "repeatPassword",
            prevForm.values.repeatPassword,
            setValue(prevForm, key, value)
          );
        }

        return validateField(key, value, setValue(prevForm, key, value));
      });
    },
    [setValue, validateField]
  );

  const setFormValue = useCallback(
    (key: keyof S, value: string): void => {
      setForm((prevForm) => setValue(prevForm, key, value));
    },
    [setValue]
  );

  const setFormValues = useCallback(
    (values: Partial<{ [key in keyof S]: string }>, skipNonEmpty?: boolean) => {
      setForm((prevForm) => setValues(prevForm, values, skipNonEmpty));
    },
    [setValues]
  );

  const clearFormError = useCallback((key: keyof S) => {
    setForm((prevForm) => {
      const newForm = {
        ...prevForm,
        errors: { ...prevForm.errors, [key]: "" },
      };

      newForm.isValid = !Object.values(newForm.errors).some(
        (err: string) => !!err
      );

      return newForm;
    });
  }, []);

  const resetForm = useCallback(() => {
    setForm(defaultForm);
  }, [defaultForm]);

  const resetField = useCallback(
    (key: keyof S) => {
      setForm((prevForm) => ({
        ...prevForm,
        values: {
          ...prevForm.values,
          [key]: defaultForm.values[key as string],
        },
        errors: {
          ...prevForm.errors,
          [key]: defaultForm.errors[key as string],
        },
        isValid: defaultForm.isValid,
      }));
    },
    [defaultForm]
  );

  return {
    form,
    setAndValidate,
    validateForm,
    setFormValue,
    setFormValues,
    clearFormError,
    resetForm,
    resetField,
  };
}
