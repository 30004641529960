import { makeStyles } from "tss-react/mui";

export type ClassNames = "root" | "title";

const CLICKABLE_SIZE = 44;

export const useStyles = makeStyles<{ textMobileOnly: boolean }>()(
  (theme, { textMobileOnly }) => ({
    root: {
      position: "relative",
      display: "flex",
      margin: textMobileOnly ? theme.spacing(0.5, 0) : undefined,
      borderRadius: 6,
      color: "#93A1AF",
      alignItems: "center",
      padding: textMobileOnly ? undefined : theme.spacing(1.5, 2),
      transition: "all 0.3s",
      [theme.breakpoints.down("lg")]: {
        padding: textMobileOnly ? theme.spacing(2) : undefined,
        "& .icon": {
          display: "none",
        },
      },
      [theme.breakpoints.up("lg")]: {
        height: textMobileOnly ? CLICKABLE_SIZE : undefined,
        width: textMobileOnly ? CLICKABLE_SIZE : undefined,
        justifyContent: textMobileOnly ? "center" : "flex-start",
      },

      "&.active": {
        backgroundColor: "#458CFF",

        "& > p": {
          color: "#FFFFFF",
        },

        "& .icon-active": {
          display: "flex",
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },

        "& .icon": {
          display: "none",
        },
      },

      "& .icon-active": {
        display: "none",
      },

      "& .icon-hover": {
        display: "none",
      },

      "&:hover": {
        backgroundColor: "rgba(69, 140, 255, .1)",
      },

      "&:hover .icon-hover": {
        display: "flex",
        [theme.breakpoints.down("lg")]: {
          display: "none",
        },
      },

      "&:hover .icon": {
        display: "none",
      },

      "&:hover .icon-active": {
        display: "none",
      },

      "&:hover > p": {
        color: "#458CFF",
      },

      "&:last-of-type": {
        [theme.breakpoints.up("lg")]: {
          marginTop: textMobileOnly ? "auto" : undefined,
        },
      },
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
      color: "#0A0A0A",
    },
  })
);
