import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { FilterBodyPaginatedProps } from "./FilterBodyPaginated.types";
import { useDebounce } from "@/common/hooks/useDebounce";

export function useFilterBodyPaginated(props: FilterBodyPaginatedProps) {
  const { chips, totalChipsQty, onQueryChange, loadMore, activeChips } = props;
  const needShowMore = totalChipsQty > chips.length;
  const [query, setQuery] = useState("");
  const onQueryChangeWithDelay = useDebounce(onQueryChange, 500);
  const activeChipsQty = useRef(activeChips.length);

  const handleQueryChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newQuery = event.target.value;

    setQuery(newQuery);
    onQueryChangeWithDelay(newQuery);
  };

  const handleLoadMore = () => {
    loadMore(query);
  };

  const clearQuery = useCallback(() => {
    setQuery("");
    onQueryChange("");
  }, [onQueryChange]);

  useEffect(() => {
    const setActiveChipsQty = () => {
      activeChipsQty.current = activeChips.length;
    };

    if (activeChipsQty.current >= activeChips.length) {
      setActiveChipsQty();

      return;
    }

    setActiveChipsQty();
    clearQuery();
  }, [activeChips.length, clearQuery]);

  return {
    needShowMore,
    handleQueryChange,
    query,
    clearQuery,
    handleLoadMore,
  };
}
