import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";

export const useStyles = makeStyles()((theme: AppTheme) => ({
  root: {
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(-1),
    color: "rgba(0, 0, 0, 0.57)",
    listStyle: "none",
  },
  linkItem: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.primary,

    "&:hover": {
      color: "#458CFF",
    },
  },
  link: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(13),
    display: "inline-block",
    maxWidth: "50%",
    color: "inherit",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",

    "&:not(:last-of-type)": {
      "&::after": {
        content: '"/"',
        padding: "5px",
      },
    },
  },
}));
