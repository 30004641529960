const mainNav = Object.freeze({
  home: "Home",
  about: "About Us",
  faq: "FAQ",
  telegram: "Telegram",
  candidates: "Candidates",
  vacancies: "Vacancies",
  companies: "Companies",
  pricing: "Pricing",
  perks: "Matchit perks",
});

export default mainNav;

export type DMainNavType = Record<keyof typeof mainNav, string>;
