import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";

export const useStyles = makeStyles()((theme: AppTheme) => ({
  root: {},
  listItemText: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 700,
  },
  listItem: {
    margin: theme.spacing(0),
  },
  listItemIcon: {
    marginLeft: "auto !important",
    minWidth: 0,
  },
  iconContainer: {
    cursor: "pointer",
  },
  paper: {
    margin: theme.spacing(-0.5, 0, 0),
    borderTop: "2px solid #3F82EF",
  },
  background: {
    position: "absolute",
    top: 64,
    left: 0,
    right: 0,
    bottom: -64,
    zIndex: 11,
    background: "rgba(0,0,0,.5)",
  },
}));
