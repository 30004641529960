import { ClassNameMap } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { AppTheme } from "@/theme";

export type ClassNames = "root";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: AppTheme) =>
    createStyles({
      root: {
        position: "absolute",
        top: 1,
        right: 3,
        color: "#458CFF",
        fontWeight: 900,
        fontSize: theme.typography.pxToRem(8),
      },
    })
);
