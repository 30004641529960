import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";

export const useStyles = makeStyles()((theme: AppTheme) => ({
  root: {},
  gridItem: {
    overflow: "hidden",
  },
  rootMobile: {
    margin: 0,
  },
  placeholder: {
    padding: theme.spacing(3),
  },
}));
