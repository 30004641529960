const jobsList = Object.freeze({
  applyFilters: "Ergebnisse anzeigen",
  emptyFilters: (title: string, query: string) =>
    `${title} wie "${query}" wurde nicht gefunden...`,
  autocompletePlaceholder: "Beginnen Sie zu tippen...",
  title: "Filter",
  clear: "Löschen",
  yearLabel: "Jahr",
  yearsLabel: "Jahre",
  skillsTitle: "Fähigkeiten",
  paidPlansTitle: "Stellentyp",
  companiesTitle: "Unternehmen",
  sliderApply: "OK",
  englishLevelTitle: "Englischniveau",
  seniorityTitle: "Erfahrung",
  locationTitle: "Standort",
  countryTitle: "Land",
  stateTitle: "Region/Bundesland",
  cityTitle: "Stadt",
  typeTitle: "Jobtyp",
  categoryTitle: "Kategorie",
  experienceTitle: "Erfahrung",
  budgetTitle: "Gehalt",
  experienceMin: "Min.",
  experienceMax: "Max.",
  showMore: "Mehr anzeigen",
  showLess: "Weniger anzeigen",
  searchPlaceholder: "Beginnen Sie zu tippen...",
  clearAll: "Alle löschen",
  found: "Gefunden",
  withFilters: (itemsName: string) => `${itemsName} mit Filtern:`,
  withoutFilters: (itemsName: string) => itemsName,
  loadMore: "Mehr laden",
  vacancies: "aktive Stellenangebote",
  recommendations: "Empfehlungen",
  items: "Elemente",
  companies: "Unternehmen",
  candidates: "aktive Kandidaten",
  jobs: "aktive Jobs",
  bannerTitle: "Die Zukunft der Rekrutierung",
  emptyTitle: "Keine Jobs gefunden",
  emptySubtitle:
    "Es gibt derzeit keine Jobs, die zu Ihren Filtern passen." +
    " Bitte versuchen Sie, Ihre Filter zu ändern.",
  pageTitle: "MATCHIT.ME Jobbörse",
  description: `Machen Sie den nächsten Schritt in Ihrer Karriere mit KI-basierten Tools, die Ihr Profil mit relevanten Jobs abgleichen. Jedes Niveau, jeder Standort – nur die besten Angebote auf dem Markt.`,
  keywords:
    "Matchit, Djinni, SkyWorker, LinkedIn, Stelle, Bonus, IT, Job, Jobsuche",
  acceptButton: "Akzeptieren",
  message:
    'Wir verwenden Browser-Cookies für bestimmte Anforderungen der Anwendung. Mit einem Klick auf die Schaltfläche "Akzeptieren" stimmen Sie der Verwendung von Cookies zu',
});

export default jobsList;
