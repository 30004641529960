import { makeStyles } from "tss-react/mui";

export type ClassNames = "root";

export const useStyles = makeStyles<{ textVisibilityMobileOnly: boolean }>()(
  (theme, { textVisibilityMobileOnly }) => ({
    root: {
      display: "flex",
      height: "100%",
      marginTop: theme.spacing(3),
      [theme.breakpoints.up("lg")]: {
        marginTop: textVisibilityMobileOnly ? theme.spacing(10) : undefined,
      },
    },
  })
);
