import React, { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";
import { sortByOrder } from "@/common/utils/objectArrayUtils";
import SidebarNavigationLink from "../SidebarNavigationLink";
import Flex from "@/common/components/Flex";
import { getRouteWithSlash } from "../../../../../../router/AppRoute";
import clsx from "clsx";

const SidebarNavigation: FC<Props> = (props: Props) => {
  const { links, toggleSidebar, textVisibility, className } = props;
  const { classes } = useStyles({
    textVisibilityMobileOnly: textVisibility !== "always",
  });

  return (
    <Flex className={clsx(classes.root, className)} direction={"column"}>
      {links
        .sort(sortByOrder)
        .map(
          ({
            path,
            external,
            icon,
            iconActive,
            iconHover,
            title,
            isNew,
            exact,
          }) => (
            <SidebarNavigationLink
              key={path}
              textVisibility={textVisibility}
              to={getRouteWithSlash(path)}
              external={external}
              icon={icon}
              iconActive={iconActive}
              iconHover={iconHover}
              onClick={toggleSidebar}
              exact={exact}
              title={title}
              isNew={isNew}
            />
          )
        )}
    </Flex>
  );
};

export default SidebarNavigation;
