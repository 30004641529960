import { FC } from "react";
import { DividerProps } from "./Divider.types";
import clsx from "clsx";
import { useStyles } from "./Divider.styles";

export const Divider: FC<DividerProps> = ({ className, vertical }) => {
  const { classes } = useStyles();

  return (
    <hr
      className={clsx(classes.root, vertical && classes.vertical, className)}
    />
  );
};
