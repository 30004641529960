import { FC } from "react";

import JobsCityFilterProps from "./JobsCityFilter.types";
import { useJobsCityFilter } from "./useJobsCityFilter";
import FilterContainer from "../../common/components/FilterContainer";
import FilterBodyPaginated from "@/common/components/Filter/common/components/FilterContainer/components/FilterBodyPaginated";
import { D_JOBS_LIST } from "@/i18n/dictionary/jobsList.dictionary";

const JobsCityFilter: FC<JobsCityFilterProps> = (
  props: JobsCityFilterProps
) => {
  const { className, ...rest } = props;
  const {
    handleChangeCityFilter,
    allCities,
    activeCities,
    citiesTotalCount,
    handleClear,
    loadMore,
    handleQueryChange,
    clearDisabled,
  } = useJobsCityFilter(props);

  return (
    <FilterContainer
      title={D_JOBS_LIST.cityTitle}
      clearDisabled={clearDisabled}
      onClear={handleClear}
      chips={allCities}
      totalChipsQty={citiesTotalCount}
      activeChips={activeCities}
      onChangeFilter={handleChangeCityFilter}
      {...rest}>
      <FilterBodyPaginated
        title={D_JOBS_LIST.cityTitle}
        chips={allCities}
        activeChips={activeCities}
        onChangeFilter={handleChangeCityFilter}
        loadMore={loadMore}
        onQueryChange={handleQueryChange}
        totalChipsQty={citiesTotalCount}
      />
    </FilterContainer>
  );
};

export default JobsCityFilter;
