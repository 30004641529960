const recommendationStatusDialog = Object.freeze({
  title: "Change the status of the recommendation",
  errorUpdateRecommendationStatus:
    "An error occurred on update recommendation status. Please, try again later",
  successUpdateRecommendationStatus:
    "Recommendation status has been updated successfully",
  statusSelectLabel: "Choose status",
  reasonSelectLabel: "Choose rejection reason",
  formFieldComment: "Comment",
  formFieldTimezoneCompany: "Company time zone",
  formFieldTimezoneCandidate: "Candidate time zone",
  formFieldReason: "Reason",
  formFieldInterviewDate: "Interview date at your local time",
  formFieldSalary: "Candidate salary",
  CANT_CONTACT: "Can't contact candidate",
  CANDIDATE_REFUSED: "Candidate refused vacancy",
  CANDIDATE_NOT_MEET_REQUIREMENTS: "Candidate does not meet the requirements",
  CANDIDATE_REFUSED_AFTER_INTERVIEW:
    "Candidate refused vacancy after interview",
  CANDIDATE_REFUSED_AFTER_OFFER: "Candidate refused vacancy after offer",
  CANDIDATE_ACCEPT_ANOTHER_OFFER: "Candidate accepted another offer",
  CANDIDATE_ALREADY_REVIEWED: "Candidate has already been considered",
  VACANCY_NOT_ACTUAL: "Vacancy is no longer actual",
  CANDIDATE_RECOMMENDED_BY_ANOTHER_RECRUITER:
    "Candidate recommended by another recruiter",
  CANDIDATE_LOCATION_NOT_MATCH_VACANCY:
    "Candidate location don't match the vacancy",
  CANDIDATE_EXPERIENCE_NOT_MEET_VACANCY:
    "Candidate experience don't meet the requirements",
  CANDIDATE_OFTEN_CHANGES_JOB: "Candidate too often changes job",
  CANDIDATE_SALARY_NOT_MATCH_BUDGET:
    "Candidate's salary is outside the vacancy budget",
});

export default recommendationStatusDialog;

export type DRecommendationStatusDialogType = Record<
  keyof typeof recommendationStatusDialog,
  string
>;
