import { useCallback, useEffect, useMemo } from "react";
import { ChipItem } from "@/common/components/ChipsContainer/Props";
import { FILTER_ITEMS_LIMIT as limit } from "@/config";
import { useJobsCitiesQuery } from "@/common/components/Filter/components/JobsCityFilter/JobsCityFilter.operations.generated";
import JobsCityFilterProps from "@/common/components/Filter/components/JobsCityFilter/JobsCityFilter.types";

export function useJobsCityFilter(props: JobsCityFilterProps) {
  const { applyFilter, filter, clearFilter } = props.context;
  const {
    data: citiesData,
    fetchMore,
    refetch: refetchCities,
  } = useJobsCitiesQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      offset: 0,
      limit,
      query: "",
    },
  });

  const citiesTotalCount = citiesData?.jobsCities.count || 0;

  const allCities = useMemo(
    () => citiesData?.jobsCities.entities || [],
    [citiesData?.jobsCities.entities]
  );

  const activeCities = filter.city;

  const refetchWithQuery = useCallback(
    (query: string) => {
      refetchCities({ offset: 0, limit, query });
    },
    [refetchCities]
  );

  const handleChangeCityFilter = useCallback(
    (city: ChipItem) => {
      applyFilter("city", city);
    },
    [applyFilter]
  );

  const handleClear = useCallback(() => {
    clearFilter("city");
  }, [clearFilter]);

  useEffect(() => {
    refetchWithQuery("");
  }, [refetchWithQuery]);

  const loadMore = useCallback(
    (query: string) => {
      fetchMore({
        variables: {
          offset: allCities.length,
          limit,
          query,
        },
      });
    },
    [allCities.length, fetchMore]
  );

  return {
    allCities,
    activeCities,
    handleChangeCityFilter,
    handleClear,
    clearDisabled: !activeCities.length,
    loadMore,
    citiesTotalCount,
    handleQueryChange: refetchWithQuery,
  };
}
