import * as Types from "../../../generated/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type JobCardFragment = {
  __typename?: "Job";
  referencenumber: string;
  title: string;
  company: string;
  city?: string | null;
  state: string;
  date: string;
};

export type JobQueryVariables = Types.Exact<{
  referencenumber: Types.Scalars["String"];
}>;

export type JobQuery = {
  __typename?: "Query";
  job: {
    __typename?: "Job";
    title: string;
    category?: string | null;
    city?: string | null;
    company: string;
    country: string;
    state: string;
    descriptionShort?: string | null;
    description: string;
    date: string;
    postalcode?: number | null;
  };
};

export type SimilarJobsQueryVariables = Types.Exact<{
  referencenumber: Types.Scalars["String"];
}>;

export type SimilarJobsQuery = {
  __typename?: "Query";
  similarJobs: Array<{
    __typename?: "Job";
    referencenumber: string;
    title: string;
    company: string;
    city?: string | null;
    state: string;
    date: string;
  }>;
};

export const JobCardFragmentDoc = gql`
  fragment JobCard on Job {
    referencenumber
    title
    company
    city
    state
    date
  }
`;
export const JobDocument = gql`
  query Job($referencenumber: String!) {
    job(referencenumber: $referencenumber) {
      title
      category
      city
      company
      country
      state
      descriptionShort
      description
      date
      postalcode
    }
  }
`;

/**
 * __useJobQuery__
 *
 * To run a query within a React component, call `useJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobQuery({
 *   variables: {
 *      referencenumber: // value for 'referencenumber'
 *   },
 * });
 */
export function useJobQuery(
  baseOptions: Apollo.QueryHookOptions<JobQuery, JobQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<JobQuery, JobQueryVariables>(JobDocument, options);
}
export function useJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JobQuery, JobQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<JobQuery, JobQueryVariables>(JobDocument, options);
}
export type JobQueryHookResult = ReturnType<typeof useJobQuery>;
export type JobLazyQueryHookResult = ReturnType<typeof useJobLazyQuery>;
export type JobQueryResult = Apollo.QueryResult<JobQuery, JobQueryVariables>;
export const SimilarJobsDocument = gql`
  query SimilarJobs($referencenumber: String!) {
    similarJobs(referencenumber: $referencenumber) {
      ...JobCard
    }
  }
  ${JobCardFragmentDoc}
`;

/**
 * __useSimilarJobsQuery__
 *
 * To run a query within a React component, call `useSimilarJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimilarJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimilarJobsQuery({
 *   variables: {
 *      referencenumber: // value for 'referencenumber'
 *   },
 * });
 */
export function useSimilarJobsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SimilarJobsQuery,
    SimilarJobsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SimilarJobsQuery, SimilarJobsQueryVariables>(
    SimilarJobsDocument,
    options
  );
}
export function useSimilarJobsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SimilarJobsQuery,
    SimilarJobsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SimilarJobsQuery, SimilarJobsQueryVariables>(
    SimilarJobsDocument,
    options
  );
}
export type SimilarJobsQueryHookResult = ReturnType<typeof useSimilarJobsQuery>;
export type SimilarJobsLazyQueryHookResult = ReturnType<
  typeof useSimilarJobsLazyQuery
>;
export type SimilarJobsQueryResult = Apollo.QueryResult<
  SimilarJobsQuery,
  SimilarJobsQueryVariables
>;
