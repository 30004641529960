import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";

export const useStyles = makeStyles()((theme: AppTheme) => ({
  root: {},
  chipsContainer: {
    margin: theme.spacing(0, -0.5),
  },
  input: {
    margin: theme.spacing(0, 0, 1),
    width: "100%",
    paddingRight: theme.spacing(4),
  },
  title: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 600,
  },
  clearButton: {
    marginLeft: "auto",
    paddingLeft: theme.spacing(1),
  },
  clearQuery: {
    position: "absolute",
    right: 0,
  },
  inputWrapper: {
    position: "relative",
    width: "100%",
  },
  emptyText: {
    fontWeight: 400,
  },
}));
