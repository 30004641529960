import React, { FC, useCallback } from "react";
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Slide,
} from "@mui/material";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";

import StyledDialogProps from "./StyledDialogProps";
import { useStyles } from "./styles";
import { useDialog } from "./useDialog";
import Button from "../Button";
import dictionary from "@/i18n/dictionary";
import Flex from "../Flex";
import clsx from "clsx";
import { ModalProps } from "@mui/material/Modal";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Dialog: FC<StyledDialogProps> = (props: StyledDialogProps) => {
  const {
    title,
    subTitle,
    onClose,
    children,
    acceptAction,
    acceptText,
    acceptActionDisabled,
    cancelText,
    cancelAction,
    cancelActionDisabled,
    loading,
    hideCancelAction,
    centerContent,
    illustration,
    illustrationPosition,
    disableBackdropClick,
    classes: origClasses,
    slide,
    fullScreen,
    ...rest
  } = props;
  const { close } = useDialog(props);
  const { classes } = useStyles();
  const withIllustrationLeft = illustrationPosition === "left";
  const withIllustrationTop = illustrationPosition === "top";
  const isIllustration = !!illustration;

  const handleClose: ModalProps["onClose"] = useCallback(
    (event: any, reason: "backdropClick" | "escapeKeyDown") => {
      if (disableBackdropClick && reason === "backdropClick") {
        return;
      }

      if (cancelAction) {
        cancelAction();
      }

      if (onClose) {
        onClose(event, reason);
      }
    },
    [disableBackdropClick, onClose, cancelAction]
  );

  const renderDialogInfo = () => (
    <>
      <DialogTitle>
        <Flex alignVertical={"center"} alignHorizontal={"space-between"}>
          <Typography
            className={clsx(
              classes.title,
              centerContent && classes.titleCenter
            )}>
            {title}
          </Typography>
          <IconButton
            size={"small"}
            onClick={cancelAction || close}
            className={
              withIllustrationTop ? classes.closeIconAbsolute : undefined
            }>
            <CloseIcon />
          </IconButton>
        </Flex>
        {subTitle && (
          <Typography
            component={"div"}
            className={clsx(
              classes.subtitle,
              centerContent && classes.subtitleCenter
            )}>
            {subTitle}
          </Typography>
        )}
      </DialogTitle>
      {children && (
        <DialogContent
          dividers={!isIllustration}
          className={clsx(classes.root, centerContent && classes.rootCenter)}>
          {children}
        </DialogContent>
      )}
      <DialogActions
        className={clsx(centerContent ? classes.centerActions : "")}>
        {!hideCancelAction && (
          <Button
            variant={"outlined"}
            onClick={cancelAction || close}
            disabled={cancelActionDisabled}>
            {cancelText || dictionary.dialog.cancel}
          </Button>
        )}
        {!!acceptAction && (
          <Button
            variant={"contained"}
            type={"submit"}
            onClick={acceptAction}
            loading={loading}
            disabled={acceptActionDisabled}>
            {acceptText || dictionary.dialog.accept}
          </Button>
        )}
      </DialogActions>
    </>
  );

  return (
    <MuiDialog
      onClose={handleClose}
      fullScreen={fullScreen}
      scroll={"paper"}
      TransitionComponent={slide && Transition}
      classes={{
        paper: clsx(
          classes.dialog,
          withIllustrationLeft && classes.dialogIllustrationLeft
        ),
        ...origClasses,
      }}
      {...rest}>
      {isIllustration ? (
        <Flex direction={withIllustrationTop ? "column" : "row"}>
          <div className={classes.illustrationContainer}>{illustration}</div>
          <div className={classes.dialogContent}>{renderDialogInfo()}</div>
        </Flex>
      ) : (
        renderDialogInfo()
      )}
    </MuiDialog>
  );
};

export default Dialog;
