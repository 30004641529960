import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";

export const useStyles = makeStyles()((theme: AppTheme) => ({
  root: {},
  chip: {
    margin: theme.spacing(0.5),
  },
  chipOutlinedActive: {
    borderColor: "#458CFF",
    "& > span": {
      color: "#458CFF !important",
    },
  },
}));
