import { FC } from "react";

import JobsCountryFilterProps from "./JobsCountryFilter.types";
import { useJobsCountryFilter } from "./useJobsCountryFilter";
import FilterContainer from "../../common/components/FilterContainer";
import FilterBodyPaginated from "@/common/components/Filter/common/components/FilterContainer/components/FilterBodyPaginated";
import { D_JOBS_LIST } from "@/i18n/dictionary/jobsList.dictionary";

const LocationFilter: FC<JobsCountryFilterProps> = (
  props: JobsCountryFilterProps
) => {
  const { className, ...rest } = props;
  const {
    handleChangeCountryFilter,
    allCountries,
    activeCountries,
    countriesTotalCount,
    handleClear,
    loadMore,
    handleQueryChange,
    clearDisabled,
  } = useJobsCountryFilter(props);

  return (
    <FilterContainer
      title={D_JOBS_LIST.countryTitle}
      clearDisabled={clearDisabled}
      onClear={handleClear}
      chips={allCountries}
      totalChipsQty={countriesTotalCount}
      activeChips={activeCountries}
      onChangeFilter={handleChangeCountryFilter}
      {...rest}>
      <FilterBodyPaginated
        title={D_JOBS_LIST.countryTitle}
        chips={allCountries}
        activeChips={activeCountries}
        onChangeFilter={handleChangeCountryFilter}
        loadMore={loadMore}
        onQueryChange={handleQueryChange}
        totalChipsQty={countriesTotalCount}
      />
    </FilterContainer>
  );
};

export default LocationFilter;
