const jobsList = Object.freeze({
  applyFilters: "Показати результати",
  emptyFilters: (title: string, query: string) =>
    `${title} схожі на "${query}" не знайдені...`,
  autocompletePlaceholder: "Почніть вводити...",
  title: "Фільтр",
  clear: "Очистити",
  yearLabel: "рік",
  yearsLabel: "роки",
  skillsTitle: "Навички",
  paidPlansTitle: "Тип Вакансії",
  companiesTitle: "Компанії",
  sliderApply: "OK",
  englishLevelTitle: "Рівень Англійської",
  seniorityTitle: "Рівень",
  locationTitle: "Локація",
  countryTitle: "Країна",
  categoryTitle: "Категорія",
  stateTitle: "Регіон/Штат",
  cityTitle: "Місто",
  typeTitle: "Тип Зайнятості",
  experienceTitle: "Досвід",
  budgetTitle: "Зарплата",
  experienceMin: "Мін",
  experienceMax: "Макс",
  showMore: "Показати більше",
  showLess: "Показати менше",
  searchPlaceholder: "Почніть вводити...",
  clearAll: "Очистити всі",
  found: "Знайдено",
  withFilters: (itemsName: string) => `${itemsName} з фільтрами:`,
  withoutFilters: (itemsName: string) => itemsName,
  loadMore: "Завантажити ще",
  vacancies: "активних вакансій",
  recommendations: "рекомендацій",
  items: "результатів",
  companies: "активних компаній",
  candidates: "кандидатів",
  jobs: "вакансій",
  bannerTitle: "Майбутнє рекрутингу",
  emptyTitle: "Вакансій не знайдено",
  emptySubtitle:
    "На даний момент для ваших фільтрів немає вакансій." +
    "Спробуйте очистити фільтр або натисніть кнопку нижче, і ми повідомимо" +
    "вас, коли відповідна вакансія з'явиться на платформі",
  subscribeForVacancies: "Підписатися на нові вакансії",
  pageTitle: "MATCHIT.ME - Робота в IT",
  description:
    "Зробіть наступний крок у своїй кар’єрі за допомогою інструментів на основі штучного інтелекту, які співставлять ваш профіль із відповідними вакансіями. Будь-який рівень, будь-яка локація - тільки найкращі пропозиції на ринку",
  keywords:
    "Matchit, Djinni, SkyWorker, LinkedIn, вакансія, бонус, IT, робота," +
    "пошук роботи, вакансії, рекрутер, кандидат, програмування",
  acceptButton: "Погоджуюсь",
  message:
    "Ми використовуємо файли cookies браузера для деяких потреб програми. Натиснувши кнопку «Погоджуюсь», ви підтверджуєте використання файлів cookies",
});

export default jobsList;
