import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";

export const useStyles = makeStyles()((theme: AppTheme) => ({
  root: {
    marginRight: theme.spacing(1),
    position: "relative",
  },
  counter: {
    height: 16,
    width: 16,
    lineHeight: "12px",
    position: "absolute",
    top: 0,
    right: 0,
    fontSize: 10,
    fontWeight: 600,
    color: "white",
    background: theme.palette.primary.main,
    animation: "ripple-counter 1.5s infinite ease-in-out",
    borderRadius: "50%",
    padding: 2,
    "@keyframes ripple-counter": {
      "0%": {
        transform: "scale(0.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(1.2)",
        opacity: 0,
      },
    },
  },
}));
