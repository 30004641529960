import { useState } from "react";
import { AppTheme } from "@/theme";
import { useMediaQuery, useTheme } from "@mui/material";

export function usePublicLayout() {
  const theme: AppTheme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const [openSidebar, setOpenSidebar] = useState(false);

  const toggleSidebar = () => {
    setOpenSidebar((prevState) => !prevState);
  };

  return {
    toggleSidebar,
    openSidebar,
    isDesktop,
  };
}
