import { usePreventAutoScroll } from "@/common/hooks/usePreventAutoScroll";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Pager } from "@/common/models/Pager";
import uniqBy from "lodash.uniqby";
import { VacancyOrderType } from "@/common/models/VacancyOrderType";
import { Crumb } from "@/common/components/Breadcrumbs/Breadcrumbs.types";
import { D_BREADCRUMBS } from "@/i18n/dictionary/breadcrumbs.dictionary";
import {
  JobsListCardFragment,
  useJobsQtyQuery,
  useJobsQuery,
} from "./Jobs.operations.generated";
import { JobsFilterContext } from "@/pages/public/Jobs/components/JobsFilter/components/JobsFilterContextProvider/JobsFilterContext";
import { mapFilterClickableToString } from "@/common/components/Filter/Filter.utils";
import { JobsQtyFilter } from "@/generated/types";

const jobsCrumbs: Crumb[] = [{ title: D_BREADCRUMBS.jobs }];
const DEFAULT_LIMIT = 10;

export function useJobs() {
  usePreventAutoScroll();
  const context = useContext(JobsFilterContext);
  const [vacancies, setVacancies] = useState<JobsListCardFragment[]>([]);
  const { filter, applyFilter } = context;
  const [pager, setPager] = useState<Pager>({ page: 1, limit: DEFAULT_LIMIT });
  const isLoadingJobs = useRef(false);
  const hasMoreData = useRef(true);
  const jobsQtyFilter: JobsQtyFilter = useMemo(
    () => ({
      query: filter.query,
      city: filter.city.map(mapFilterClickableToString),
      company: filter.company.map(mapFilterClickableToString),
      category: filter.category.map(mapFilterClickableToString),
      country: filter.country.map(mapFilterClickableToString),
      state: filter.state.map(mapFilterClickableToString),
      jobType: filter.jobType.map(mapFilterClickableToString),
    }),
    [
      filter.category,
      filter.city,
      filter.company,
      filter.country,
      filter.jobType,
      filter.query,
      filter.state,
    ]
  );
  const jobsQueryVariables = useMemo(
    () => ({ filter: { ...jobsQtyFilter, ...pager } }),
    [jobsQtyFilter, pager]
  );

  const { data, loading, called, fetchMore } = useJobsQuery({
    variables: jobsQueryVariables,
    fetchPolicy: "cache-and-network",
  });

  const { data: jobsQtyData } = useJobsQtyQuery({
    variables: {
      filter: jobsQtyFilter,
    },
    fetchPolicy: "cache-and-network",
  });

  const fetchVacanciesData = useCallback(() => {
    hasMoreData.current = !(
      data &&
      (!data.jobs.length || data.jobs.length < DEFAULT_LIMIT)
    );

    isLoadingJobs.current = false;
    fetchMore({
      variables: jobsQueryVariables,
    });
  }, [data, fetchMore, jobsQueryVariables]);

  const handleLoadMoreVacancies = useCallback(() => {
    if (isLoadingJobs.current) {
      return;
    }

    setPager((prev) => ({ ...prev, page: prev.page + 1 }));
    isLoadingJobs.current = true;
    fetchVacanciesData();
  }, [fetchVacanciesData]);

  useEffect(() => {
    setPager({ page: 1, limit: DEFAULT_LIMIT });
  }, [filter]);

  useEffect(() => {
    const newVacancies = data?.jobs;

    setVacancies((prevVacancies) => {
      if (!newVacancies) {
        return prevVacancies;
      }

      const result =
        pager.page > 1
          ? [...prevVacancies, ...newVacancies]
          : [...newVacancies];

      return uniqBy(result, ({ referencenumber }) => referencenumber);
    });
  }, [data?.jobs, pager.page]);

  const handleChangeSearchQuery = useCallback(
    (query: string) => {
      applyFilter("query", query);
    },
    [applyFilter]
  );

  const onChangeOrder = (order: VacancyOrderType) => {
    applyFilter("order", order);
  };

  return {
    vacancies,
    loading,
    jobsQty: jobsQtyData?.jobsQty,
    handleLoadMoreVacancies,
    handleChangeSearchQuery,
    context,
    filter,
    order: filter.order,
    onChangeOrder,
    vacanciesFetched: called,
    jobsCrumbs,
    isShowLoadMoreButton: !isLoadingJobs.current && hasMoreData.current,
  };
}
