import { FC } from "react";
import { ReactComponent as Arrow } from "../../icons/arrow-right-circle-hover.svg";
import { useStyles } from "./ScrollToTop.styles";
import { ScrollToTopProps } from "./ScrollToTop.types";
import { useScrollToTop } from "./useScrollToTop";
import clsx from "clsx";
import Flex from "../Flex";

export const ScrollToTop: FC<ScrollToTopProps> = ({ className }) => {
  const { classes } = useStyles();
  const { handleClickButton, isVisible } = useScrollToTop();

  return (
    <Flex
      className={clsx(
        classes.root,
        isVisible && classes.visible,
        className?.root
      )}>
      <Arrow
        onClick={handleClickButton}
        style={{ fill: "currentColor" }}
        className={clsx(classes.icon, className?.icon)}
      />
    </Flex>
  );
};
