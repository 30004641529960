import { Dictionary } from "@/i18n/dictionary.types";
import recommendationStatusDialogUA from "@/i18n/dictionary/ua/recommendationStatusDialog";
import recommendationStatusDialogEN, {
  DRecommendationStatusDialogType,
} from "@/i18n/dictionary/en/recommendationStatusDialog";
import { t } from "@/i18n/t";

const RECOMMENDATION_STATUS_DIALOG: Dictionary<DRecommendationStatusDialogType> =
  {
    ua: recommendationStatusDialogUA,
    en: recommendationStatusDialogEN,
  };

export const D_RECOMMENDATION_STATUS_DIALOG = t(RECOMMENDATION_STATUS_DIALOG);
