import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";

export const FOOTER_HEIGHT = 53;
export const FOOTER_HEIGHT_MOBILE = 449;

export const useStyles = makeStyles()((theme: AppTheme) => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      background: "rgba(227,227,227,0.2)",
      padding: theme.spacing(0),
    },
  },
  link: {
    fontWeight: theme.typography.fontWeightRegular,
    "&:not(:first-of-type)": {
      [theme.breakpoints.up("md")]: {
        margin: theme.spacing(0, 2),
      },
    },
    color: "#747474 !important",
    fontSize: "14px !important", // less priority on prod build
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
      borderBottom: "1px solid rgba(211,211,211,0.4)",
      "&:after": {
        display: "flex",
        content: "'›'",
        justifyContent: "center",
        height: "100%",
        width: 20,
      },
      "&:hover, &:active, &:focus": {
        textDecoration: "none",
        background: "rgba(211,211,211,0.4)",
      },
    },
  },
  copyright: {
    fontSize: "14px !important", // less priority on prod build
    color: "#747474",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
      textAlign: "center",
    },
  },
  linksContainer: {
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  content: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
}));
