import { useState } from "react";

export function useFilterContainer() {
  const [toggled, setToggled] = useState(false);

  const toggle = () => {
    setToggled((prev) => !prev);
  };

  return { toggled, toggle };
}
