import React, { lazy, Suspense, useCallback } from "react";
import Loader from "@/common/components/loaders/Loader";
import Props from "@/sections/CompanyEditDialog/Props";

export function useRenderCompanyEditDialog(props: Props) {
  const renderDialog = useCallback(() => {
    const CompanyEditDialog = lazy(
      () => import("@/sections/CompanyEditDialog")
    );

    return (
      <Suspense fallback={<Loader />}>
        <CompanyEditDialog {...props} />
      </Suspense>
    );
  }, [props]);

  return useCallback(
    () => (props.visible ? renderDialog() : null),
    [props.visible, renderDialog]
  );
}
