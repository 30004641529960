import { Dictionary } from "@/i18n/dictionary.types";
import jobsListUA from "@/i18n/dictionary/ua/jobsList";
import jobsListES from "@/i18n/dictionary/es/jobsList";
import jobsListDE from "@/i18n/dictionary/de/jobsList";
import jobsListNL from "@/i18n/dictionary/nl/jobsList";
import jobsListBR from "@/i18n/dictionary/br/jobsList";
import jobsListEN, { DJobsListType } from "@/i18n/dictionary/en/jobsList";
import { t } from "@/i18n/t";

const JOBS_LIST: Dictionary<DJobsListType> = {
  ua: jobsListUA,
  en: jobsListEN,
  es: jobsListES,
  mx: jobsListES,
  pa: jobsListES,
  de: jobsListDE,
  nl: jobsListNL,
  br: jobsListBR,
};

export const D_JOBS_LIST = t(JOBS_LIST);
