import { Dictionary } from "@/i18n/dictionary.types";
import breadcrumbsUA from "@/i18n/dictionary/ua/breadcrumbs";
import breadcrumbsES from "@/i18n/dictionary/es/breadcrumbs";
import breadcrumbsEN, {
  DBreadcrumbsType,
} from "@/i18n/dictionary/en/breadcrumbs";
import { t } from "@/i18n/t";

const BREADCRUMBS: Dictionary<DBreadcrumbsType> = {
  ua: breadcrumbsUA,
  en: breadcrumbsEN,
  es: breadcrumbsES,
};

export const D_BREADCRUMBS = t(BREADCRUMBS);
