import { FC } from "react";

import JobsTypeFilterProps from "./JobsTypeFilter.types";
import { useJobsTypeFilter } from "./useJobsTypeFilter";
import FilterContainer from "../../common/components/FilterContainer";
import FilterBodyPaginated from "@/common/components/Filter/common/components/FilterContainer/components/FilterBodyPaginated";
import { D_JOBS_LIST } from "@/i18n/dictionary/jobsList.dictionary";

const JobsTypeFilter: FC<JobsTypeFilterProps> = (
  props: JobsTypeFilterProps
) => {
  const { className, ...rest } = props;
  const {
    handleChangeTypeFilter,
    allTypes,
    activeTypes,
    typesTotalCount,
    handleClear,
    loadMore,
    handleQueryChange,
    clearDisabled,
  } = useJobsTypeFilter(props);

  return (
    <FilterContainer
      title={D_JOBS_LIST.typeTitle}
      clearDisabled={clearDisabled}
      onClear={handleClear}
      chips={allTypes}
      totalChipsQty={typesTotalCount}
      activeChips={activeTypes}
      onChangeFilter={handleChangeTypeFilter}
      {...rest}>
      <FilterBodyPaginated
        title={D_JOBS_LIST.typeTitle}
        chips={allTypes}
        activeChips={activeTypes}
        onChangeFilter={handleChangeTypeFilter}
        loadMore={loadMore}
        onQueryChange={handleQueryChange}
        totalChipsQty={typesTotalCount}
      />
    </FilterContainer>
  );
};

export default JobsTypeFilter;
