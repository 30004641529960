import { UserLevel } from "@/common/models/UserLevel";

const es = Object.freeze({
  autocomplete: {
    title: "Matches",
    section: (entity: string, qty: number) => `Found ${qty} ${entity}`,
  },
  userLevel: {
    levelDescription: (level: UserLevel, percent: number, amount?: number) =>
      `Your current level on Matchit is "${level}". On this level you can get bonus up to ${percent}% of the candidate's salary ${
        amount ? "and additional $" + amount + " fixed reward" : ""
      } from each recommendation! More recommendations - Higher level - Bigger bonus!💰`,
  },
  relatedVacancies: {
    title: "Vacancies that may interest you",
  },
  senioritySelectPicker: {
    label: "Seniority",
    placeholder: "Select Seniority",
  },
  cookiesBanner: {
    acceptButton: "Accept",
    message:
      'We use browser cookies for some needs of the application. By clicking the "Accept" button, you have approved the use of cookies',
  },
  privacyPolicy: {
    pageTitle: "Privacy Policy",
  },
  contactUs: {
    pageTitle: "Contact us",
  },
  aboutUs: {
    pageTitle: "About Us",
  },
  telegram: {
    pageTitle: "Telegram",
  },
  userAgreement: {
    pageTitle: "User Agreement",
  },
  warning: {
    recommendedCandidateForVacancy:
      "The probability of a successful hire is 95% higher and the bonus is twice as large if the candidate is informed that he is recommended",
  },
  recommendatorWallet: {
    pageTitle: "My wallet",
  },
  recommendatorReporting: {
    pageTitle: "Reporting",
  },
  underConstruction: {
    title: "This page is under construction",
    description: "Please, check back soon...",
  },
  experience: {
    year: "year",
    years: "years",
  },
  dialog: {
    accept: "Accept",
    cancel: "Cancel",
  },
  adminLogin: {
    login: "Login",
  },
  dropzone: {
    description: "Drag & drop your file here",
    descriptionOnPhone: "Press to upload CV",
  },
  confirmationDialog: {
    title: "Are you sure?",
    acceptButtonText: "Confirm",
  },
  citySelect: {
    placeholder: "Start typing...",
    label: "Locations",
  },
  companySelect: {
    placeholder: "Company",
    label: "Company",
  },
  companyTypeSelect: {
    placeholder: "Company Type",
    label: "Company Type",
  },
  englishLevelSelectPicker: {
    label: "English level",
    placeholder: "Please select required english level",
  },
  plan: {
    pro: "Pro vacancies it's about balance between the size of the bonus and quick processing of the candidate. The Matchit team will make sure that you always receive quality feedback based on your recommendations.",
    proCandidate:
      "Pro vacancies it's about balance between the size of the bonus and quick processing of the candidate. The Matchit team will make sure that you always receive quality feedback based on your applications.",
    premium:
      "Premium vacancies are fully moderated by the Matchit team. Your candidates recommendations are reviewed the fastest, highest quality feedback, and the largest hiring bonus.",
    premiumCandidate:
      "Premium vacancies are fully moderated by the Matchit team. Your applications are reviewed the fastest, highest quality feedback, and the largest hiring bonus.",
  },
  companiesFiler: {
    statusTitle: "Status",
  },
  searchField: {
    placeholder: "Comience a escribir...",
  },
  recommendatorRecommendations: {
    pageTitle: "My recommendations",
    pageTitleCandidate: "Job applications",
    foundRecommendations: (recommendationsQty: number) =>
      `Found ${recommendationsQty} recommendations`,
  },
  englishLevel: {
    notSpecified: "Any level",
  },
});

export default es;
