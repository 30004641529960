const breadcrumbs = Object.freeze({
  home: "Home",
  vacancies: "Vacancies",
  jobs: "Jobs",
  companies: "Companies",
});

export default breadcrumbs;

export type DBreadcrumbsType = Record<keyof typeof breadcrumbs, string>;
