import { Dictionary } from "@/i18n/dictionary.types";
import footerUA from "@/i18n/dictionary/ua/footer";
import footerEN, { DFooterType } from "@/i18n/dictionary/en/footer";
import { t } from "@/i18n/t";

const FOOTER: Dictionary<DFooterType> = {
  ua: footerUA,
  en: footerEN,
};

export const D_FOOTER = t(FOOTER);
