import { useIsAdmin } from "@/auth/hooks/useIsAdmin";
import { useEffect, useState } from "react";
import Props from "./Props";
import { useHistory } from "react-router-dom";
import { AppRoute, getRouteWithSlash } from "@/router/AppRoute";
import { useIsAuthorised } from "@/auth/hooks/useIsAuthorised";

export function useDropdown(props: Props) {
  const { onToggle, isUsePrivateLayout, goDashboard } = props;
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const isAuthorized = useIsAuthorised();
  const isAdmin = useIsAdmin();

  const toggleTooltip = () => {
    if (isUsePrivateLayout) {
      return history.push(getRouteWithSlash(AppRoute.USER_PROFILE_SETTINGS));
    }

    if (isAuthorized && !isAdmin && goDashboard) {
      return goDashboard();
    }

    setVisible((prev) => !prev);
  };

  const handleClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (onToggle) {
      onToggle(visible);
    }
  }, [visible, onToggle]);

  return {
    visible,
    toggleTooltip,
    handleClose,
  };
}
