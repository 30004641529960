import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";

export const useStyles = makeStyles()((theme: AppTheme) => ({
  button: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    margin: theme.spacing(3, "auto"),
    minHeight: 48,
  },
}));
