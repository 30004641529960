import { ClassNameMap } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { AppTheme } from "@/theme";

export type ClassNames = "updatedAt";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: AppTheme) =>
    createStyles({
      updatedAt: {
        color: "#93A1AF",
        fontSize: theme.typography.pxToRem(14),
      },
    })
);
