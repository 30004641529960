import StyledDialogProps from "./StyledDialogProps";

export function useDialog(props: StyledDialogProps) {
  const { onClose } = props;

  const close = () => {
    if (onClose) {
      onClose({}, "escapeKeyDown");
    }
  };

  return { close };
}
