import React, { FC } from "react";

import Props from "./Props";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import isEqual from "lodash.isequal";
import { useStyles } from "./styles";

const StyledSelectPicker: FC<Props> = (props) => {
  const { options, SelectProps, orderSelect, ...rest } = props;
  const { classes } = useStyles();

  const renderOptions = () => {
    return options.map(({ label, value }) => (
      <MenuItem key={value} value={value}>
        {label}
      </MenuItem>
    ));
  };
  return (
    <TextField
      fullWidth
      select
      SelectProps={{
        ...SelectProps,
        classes: orderSelect && { select: classes.root },
      }}
      {...rest}>
      {renderOptions()}
    </TextField>
  );
};

export default React.memo(StyledSelectPicker, isEqual);
