import React, { FC } from "react";

import Props from "./Props";
import { Helmet } from "react-helmet";

const Head: FC<Props> = (props: Props) => {
  const { description, children, title, keywords, helmetProps } = props;

  return (
    <Helmet {...helmetProps}>
      <link rel="canonical" href={window.location.href} />
      {keywords ? <meta name="keywords" content={keywords} /> : null}
      {title ? (
        <meta name="og:title" property="og:title" content={title} />
      ) : null}
      {description ? <meta name="description" content={description} /> : null}
      {description ? (
        <meta
          name="og:description"
          property="og:description"
          content={description}
        />
      ) : null}
      <title>{title}</title>
      {children}
    </Helmet>
  );
};

export default Head;
