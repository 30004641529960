import { Dictionary } from "@/i18n/dictionary.types";
import companiesTableUA from "@/i18n/dictionary/ua/companiesTable";
import companiesTableEN, {
  DCompaniesTableType,
} from "@/i18n/dictionary/en/companiesTable";
import { t } from "@/i18n/t";

const COMPANIES_TABLE: Dictionary<DCompaniesTableType> = {
  ua: companiesTableUA,
  en: companiesTableEN,
};

export const D_COMPANIES_TABLE = t(COMPANIES_TABLE);
