import { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";
import { ReactComponent as PublishIcon } from "../../icons/header/paper-plus-white.svg";
import Button from "../Button";
import { Hidden } from "@mui/material";
import CompanyConnectDialog from "../CompanyConnectDialog";
import { useButtonPublishVacancy } from "./useButtonPublishVacancy";
import { useIsVacancyAdmin } from "@/auth/hooks/useIsVacancyAdmin";
import { D_HEADER } from "@/i18n/dictionary/header.dictionary";

const ButtonPublishVacancy: FC<Props> = (props: Props) => {
  const { className, onClick, ...rest } = props;
  const { classes } = useStyles();
  const isAdmin = useIsVacancyAdmin();
  const { handleHideDialog, handleShowDialog, dialogVisible } =
    useButtonPublishVacancy();

  return (
    <>
      <Hidden mdDown>
        <Button
          startIcon={<PublishIcon />}
          variant={"contained"}
          className={classes.root}
          onClick={isAdmin ? onClick : handleShowDialog}
          {...rest}>
          {D_HEADER.publishVacancy}
        </Button>
      </Hidden>
      <CompanyConnectDialog
        onClose={handleHideDialog}
        visible={dialogVisible}
      />
    </>
  );
};

export default ButtonPublishVacancy;
