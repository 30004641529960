const recommendationRejectionReasonShort = Object.freeze({
  CANT_CONTACT: "Contacts",
  CANDIDATE_REFUSED: "Refused",
  CANDIDATE_ALREADY_REVIEWED: "Reviewed",
  VACANCY_NOT_ACTUAL: "Not actual",
  CANDIDATE_NOT_MEET_REQUIREMENTS: "Requirements",
  CANDIDATE_REFUSED_AFTER_INTERVIEW: "Interview",
  CANDIDATE_REFUSED_AFTER_OFFER: "Offer",
  CANDIDATE_ACCEPT_ANOTHER_OFFER: "Another offer",
});

export default recommendationRejectionReasonShort;

export type DRecommendationRejectionReasonShortType = Record<
  keyof typeof recommendationRejectionReasonShort,
  string
>;
