const companiesTable = Object.freeze({
  edit: "Редагувати компанію",
  delete: "Видалити компанію",
  deleteCompanyMessage:
    "Ви дійсно хочете видалити цю компанію? Усі пов’язані вакансії та рекомендації також будуть назавжди видалені",
  deleteCompanyError: "Компанію не видалено",
  deleteCompanySuccess: "Компанію успішно видалено",
  deleteCompanyErrorNoCompany: "Будь ласка, виберіть компанію для видалення",
  logo: "",
  email: "Електронна пошта",
  createdAt: "Створено",
  updatedAt: "Оновлено",
  name: "Назва",
  actions: "",
  ACTIVE: "Активна",
  DISABLED: "Неактивна",
  emptyTitle: "Компанії не знайдено",
  emptySubtitle: "На даний момент для ваших фільтрів немає компанії.",
  clearFiltersButton: "Очистити всі фільтри",
});

export default companiesTable;
