const jobsList = Object.freeze({
  applyFilters: "Show results",
  emptyFilters: (title: string, query: string) =>
    `${title} like "${query}" didn't found...`,
  autocompletePlaceholder: "Start typing...",
  title: "Filter",
  clear: "Clear",
  yearLabel: "year",
  yearsLabel: "years",
  skillsTitle: "Skills",
  paidPlansTitle: "Vacancy Type",
  companiesTitle: "Companies",
  sliderApply: "OK",
  englishLevelTitle: "English Level",
  seniorityTitle: "Seniority",
  locationTitle: "Location",
  countryTitle: "Country",
  stateTitle: "Region/State",
  cityTitle: "City",
  typeTitle: "Job Type",
  categoryTitle: "Category",
  experienceTitle: "Experience",
  budgetTitle: "Salary",
  experienceMin: "Min",
  experienceMax: "Max",
  showMore: "Show more",
  showLess: "Show less",
  searchPlaceholder: "Start typing...",
  clearAll: "Clear all",
  found: "Found",
  withFilters: (itemsName: string) => `${itemsName} with filters:`,
  withoutFilters: (itemsName: string) => itemsName,
  loadMore: "Load more",
  vacancies: "active vacancies",
  recommendations: "recommendations",
  items: "items",
  companies: "companies",
  candidates: "active candidates",
  jobs: "active jobs",
  bannerTitle: "The future of the recruitment",
  emptyTitle: "Jobs are not found",
  emptySubtitle:
    "There are no any jobs matched for your filters at the moment." +
    " Please try to change your filter",
  pageTitle: "MATCHIT.ME Job Board",
  description: `Make the next step in your career with AI-based tools for matching your profile with relevant jobs. Any level, any location - only the best offers on the market`,
  keywords:
    "Matchit, Djinni, SkyWorker, LinkedIn, vacancy, bonus,IT, job, job search",
  acceptButton: "Accept",
  message:
    'We use browser cookies for some needs of the application. By clicking the "Accept" button, you have approved the use of cookies',
});

export default jobsList;

export type DJobsListType = Record<keyof typeof jobsList, any>;
