import React, { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";
import { Drawer, IconButton } from "@mui/material";
import clsx from "clsx";
import Logo from "@/common/components/Logo";
import MenuOpen from "@mui/icons-material/MenuOpen";
import Flex from "@/common/components/Flex";
import AppNavLink from "@/common/components/AppNavLink";
import { AppRoute, getRouteWithSlash } from "../../../../router/AppRoute";
import { useIsRecommender } from "@/auth/hooks/useIsRecommender";
import { useIsCompany } from "@/auth/hooks/useIsCompany";

const Sidebar: FC<Props> = (props: Props) => {
  const {
    open,
    variant,
    className,
    toggleSidebar,
    children,
    isDesktop,
    anchor,
    ...rest
  } = props;
  const isRecommender = useIsRecommender();
  const isCompany = useIsCompany();

  const classes = useStyles(anchor !== "right");

  const getLinkForLogo = () => {
    if (isRecommender) {
      return getRouteWithSlash(AppRoute.RECOMMENDATOR_DASHBOARD);
    }
    
    if (isCompany) {
      return getRouteWithSlash(AppRoute.COMPANY_RECOMMENDATIONS);
    }

    return getRouteWithSlash(AppRoute.HOME);
  };

  return (
    <aside>
      <Drawer
        anchor={anchor}
        ModalProps={{
          className: classes.modal,
        }}
        classes={{
          paper: clsx({
            [classes.drawer]: true,
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        onBackdropClick={toggleSidebar}
        className={classes.root}
        open={open}
        variant={variant}
        {...rest}>
        {anchor !== "right" && isDesktop ? (
          <AppNavLink to={getLinkForLogo()}>
            <Logo className={classes.logo} size={42} />
          </AppNavLink>
        ) : (
          <Flex>
            <IconButton onClick={toggleSidebar}>
              <MenuOpen />
            </IconButton>
            <AppNavLink
              aria-label={"Matchit"}
              to={getRouteWithSlash(AppRoute.HOME)}>
              <Logo className={classes.logo} withName />
            </AppNavLink>
          </Flex>
        )}
        {children}
      </Drawer>
    </aside>
  );
};

export default Sidebar;
