import { useCallback, useEffect, useMemo } from "react";
import { ChipItem } from "@/common/components/ChipsContainer/Props";
import { FILTER_ITEMS_LIMIT as limit } from "@/config";
import { useJobsCountriesQuery } from "@/common/components/Filter/components/JobsCountryFilter/JobsCountryFilter.operations.generated";
import JobsCountryFilterProps from "@/common/components/Filter/components/JobsCountryFilter/JobsCountryFilter.types";

export function useJobsCountryFilter(props: JobsCountryFilterProps) {
  const { applyFilter, filter, clearFilter } = props.context;
  const {
    data: countriesData,
    fetchMore,
    refetch: refetchCountries,
  } = useJobsCountriesQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      offset: 0,
      limit,
      query: "",
    },
  });

  const countriesTotalCount = countriesData?.jobsCountries.count || 0;

  const allCountries = useMemo(
    () => countriesData?.jobsCountries.entities || [],
    [countriesData?.jobsCountries.entities]
  );

  const activeCountries = filter.country;

  const refetchWithQuery = useCallback(
    (query: string) => {
      refetchCountries({ offset: 0, limit, query });
    },
    [refetchCountries]
  );

  const handleChangeCountryFilter = useCallback(
    (country: ChipItem) => {
      applyFilter("country", country);
    },
    [applyFilter]
  );

  const handleClear = useCallback(() => {
    clearFilter("country");
  }, [clearFilter]);

  useEffect(() => {
    refetchWithQuery("");
  }, [refetchWithQuery]);

  const loadMore = useCallback(
    (query: string) => {
      fetchMore({
        variables: {
          offset: allCountries.length,
          limit,
          query,
        },
      });
    },
    [allCountries.length, fetchMore]
  );

  return {
    allCountries,
    activeCountries,
    handleChangeCountryFilter,
    handleClear,
    clearDisabled: !activeCountries.length,
    loadMore,
    countriesTotalCount,
    handleQueryChange: refetchWithQuery,
  };
}
