const jobsList = Object.freeze({
  applyFilters: "Mostrar resultados",
  emptyFilters: (title: string, query: string) =>
    `${title} como "${query}" no se encontró...`,
  autocompletePlaceholder: "Empieza a escribir...",
  title: "Filtro",
  clear: "Borrar",
  yearLabel: "año",
  yearsLabel: "años",
  skillsTitle: "Habilidades",
  paidPlansTitle: "Tipo de Vacante",
  companiesTitle: "Empresas",
  sliderApply: "OK",
  englishLevelTitle: "Nivel de Inglés",
  seniorityTitle: "Antigüedad",
  locationTitle: "Ubicación",
  countryTitle: "País",
  stateTitle: "Región/Estado",
  cityTitle: "Ciudad",
  typeTitle: "Tipo de trabajo",
  categoryTitle: "Categoría",
  experienceTitle: "Experiencia",
  budgetTitle: "Salario",
  experienceMin: "Mín",
  experienceMax: "Máx",
  showMore: "Mostrar más",
  showLess: "Mostrar menos",
  searchPlaceholder: "Empieza a escribir...",
  clearAll: "Borrar todo",
  found: "Encontrado",
  withFilters: (itemsName: string) => `${itemsName} con filtros:`,
  withoutFilters: (itemsName: string) => itemsName,
  loadMore: "Cargar más",
  vacancies: "vacantes activas",
  recommendations: "recomendaciones",
  items: "artículos",
  companies: "empresas",
  candidates: "candidatos activos",
  jobs: "trabajos activos",
  bannerTitle: "El futuro del reclutamiento",
  emptyTitle: "No se encontraron trabajos",
  emptySubtitle:
    "No hay trabajos que coincidan con tus filtros en este momento. Por favor, intenta cambiar tus filtros",
  pageTitle: "MATCHIT.ME Bolsa de Trabajo",
  description: `Da el siguiente paso en tu carrera con herramientas basadas en IA para vincular tu perfil con trabajos relevantes. Cualquier nivel, cualquier ubicación: solo las mejores ofertas del mercado`,
  keywords:
    "Matchit, Djinni, SkyWorker, LinkedIn, vacante, bono, TI, trabajo, búsqueda de trabajo",
  acceptButton: "Aceptar",
  message:
    'Usamos cookies del navegador para algunas necesidades de la aplicación. Al hacer clic en el botón "Aceptar", usted aprueba el uso de cookies',
});

export default jobsList;
