const recommendationStatus = Object.freeze({
  NEW: "Новий",
  WAITING: "Очікування",
  REVIEW: "Розгляд",
  REJECTED: "Відхилено",
  PRE_SCREENING: "Пре-скрінінг",
  ARRANGING_INTERVIEW: "Організація Співбесіди",
  INTERVIEW: "Співбесіда",
  FINAL_INTERVIEW: "Фінальна Співбесіда",
  TEST_TASK: "Тестове Завдання",
  OFFER: "Офер",
  HIRED: "Найнято",
});

export default recommendationStatus;
