import React, { FC, PropsWithChildren } from "react";
import { JobsFilterContext } from "./JobsFilterContext";
import { defaultJobsFilter } from "./defaultJobsFilter";
import { JobsFilter } from "./models/JobsFilter";
import { useFilter } from "@/common/components/Filter/useFilter";

const JobsFilterContextProvider: FC<
  PropsWithChildren<{ defaultFilters?: Partial<JobsFilter> }>
> = ({ children, defaultFilters }) => {
  const context = useFilter({ ...defaultJobsFilter, ...defaultFilters });

  return (
    <JobsFilterContext.Provider value={context}>
      {children}
    </JobsFilterContext.Provider>
  );
};

export default JobsFilterContextProvider;
