import { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";
import Logo from "@/common/components/Logo";
import Flex from "@/common/components/Flex";
import { Box, Hidden, IconButton } from "@mui/material";
import { useHeader } from "./useHeader";
import clsx from "clsx";
import { wrapContent } from "@/common/utils/reactComponentUtils";
import { Menu } from "@mui/icons-material";
import ProfileDropdown from "../../../../sections/ProfileDropdown/ProfileDropdown";
import { ReactComponent as LogoutIconIcon } from "@/common/icons/profileDropdown/logout.svg";
import { ReactComponent as SettingsIcon } from "@/common/icons/profileDropdown/setting.svg";
import { ReactComponent as ProfileIconActive } from "@/common/icons/header/profile-active.svg";
import { ReactComponent as ProfileIcon } from "@/common/icons/header/profile.svg";
import { useIsAdmin } from "@/auth/hooks/useIsAdmin";
import DropdownList from "@/common/components/DropdownList";
import { DropdownListItem } from "@/common/components/DropdownList/Props";
import LanguagePicker from "@/common/components/LanguagePicker";
import AppNavLink from "@/common/components/AppNavLink";
import { AppRoute, getRouteWithSlash } from "@/router/AppRoute";
import { useIsReferral } from "@/auth/hooks/useIsReferral";
import DefaultHeaderActions from "./components/DefaultHeaderActions";
import ChatMessagesIconButton from "@/pages/chat/components/ChatMessagesIconButton";
import { useIsChatUser } from "@/common/hooks/useIsChatUser";
import { ProfileDropdownAction } from "@/sections/ProfileDropdown/ProfileDropdownContent/ProfileDropdownActionsList/ProfileDropdownActionsListItem/ProfileDropdownActionsListItem.types";
import { NewRecommendationsCount } from "@/common/components/NewRecommendationsCount";
import { useIsVacancyAdmin } from "@/auth/hooks/useIsVacancyAdmin";
import { useNetwork } from "@/common/hooks/useNetwork";
import { Divider } from "@/common/components/Divider";
import { D_HEADER } from "@/i18n/dictionary/header.dictionary";
import { D_PROFILE_DROPDOWN } from "@/i18n/dictionary/profileDropdown.dictionary";
import ButtonPublishVacancy from "@/common/components/ButtonPublishVacancy";

const Header: FC<Props> = (props: Props) => {
  const isAdmin = useIsAdmin();
  const isVacancyAdmin = useIsVacancyAdmin();
  const isReferral = useIsReferral();
  const isChatUser = useIsChatUser();
  const { isOnline } = useNetwork();
  const isOfflineMode = !isOnline;
  const {
    toggleSidebar,
    className,
    additionalActions,
    fullWidth,
    withBurger,
    burgerVisible,
    burgerPlacement,
    isUsePrivateLayout,
  } = props;
  const {
    handlePressLogoutButton,
    handlePressSettingsButton,
    showLoginDialog,
    isAuthorized,
    pagePathName,
    showCompanyEditDialog,
    companyId,
    showRegisterDialog,
    isCompany,
    renderVacancyCreateDialog,
    renderCompanyEditDialog,
    companyWithParsedVacanciesDialog,
    showPublishVacancyDialog,
    renderRegisterDialog,
  } = useHeader();

  const { classes } = useStyles();

  const profileDropdownActions: ProfileDropdownAction[] = [
    {
      actionName: D_PROFILE_DROPDOWN.profileSettings,
      actionIcon: <SettingsIcon />,
      actionCallback: handlePressSettingsButton,
    },
    {
      actionName: D_PROFILE_DROPDOWN.logout,
      actionIcon: <LogoutIconIcon />,
      actionCallback: handlePressLogoutButton,
    },
  ];

  if (isCompany) {
    profileDropdownActions.unshift({
      actionName: D_PROFILE_DROPDOWN.companyProfileSettings,
      actionIcon: <SettingsIcon />,
      actionCallback: showCompanyEditDialog,
    });
  }

  if (isAdmin || isReferral) {
    profileDropdownActions.shift();
  }

  const getAuthList = (): DropdownListItem[] => [
    {
      name: D_HEADER.login,
      action: () => showLoginDialog(AppRoute.RECOMMENDATOR_SIGN_UP),
    },
    {
      name: D_HEADER.register,
      action: () => showRegisterDialog(),
    },
  ];
  const withBanner =
    pagePathName === getRouteWithSlash(AppRoute.VACANCIES_LIST);

  return (
    <>
      {isOfflineMode && (
        <Flex className={classes.offlineMode}>
          <Box className={classes.offlineModeContent}>
            {D_HEADER.offlineMode}
          </Box>
        </Flex>
      )}
      {withBanner && (
        <div
          className="elfsight-app-dbaa5e56-01a2-4ecd-bda8-fb8f9dbf1119"
          data-elfsight-app-lazy></div>
      )}
      <header
        className={clsx(
          isOfflineMode && classes.rootOfflineMode,
          classes.root,
          className
        )}
        id={"header"}>
        {wrapContent(
          <Flex
            className={classes.headerContent}
            alignHorizontal={"space-between"}
            alignVertical={"center"}>
            <Hidden
              lgUp={
                burgerPlacement === "right" ||
                burgerVisible !== "always" ||
                !withBurger
              }
              lgDown={burgerPlacement === "right" || !withBurger}>
              <IconButton
                onClick={toggleSidebar}
                className={classes.menuBurger}
                id="menu_burger_button">
                <Menu />
              </IconButton>
            </Hidden>
            {(!withBurger || burgerPlacement === "right") && (
              <AppNavLink
                aria-label={"Matchit"}
                to={getRouteWithSlash(AppRoute.HOME)}
                className={classes.logo}>
                <Logo withName />
              </AppNavLink>
            )}
            <Flex className={classes.container} alignVertical={"center"}>
              {/* "header_actions_container" Uses with portals. do not remove it! */}
              <Hidden smDown>
                <Box
                  className={classes.pageTitleContainer}
                  id={"header_title_container"}
                />
              </Hidden>
              {!isUsePrivateLayout && (
                <Flex
                  alignVertical={"center"}
                  className={classes.mainActions}
                  id={"header_actions_container"}>
                  {additionalActions}
                  <DefaultHeaderActions exclude={[AppRoute.COMPANIES_LIST]} />
                </Flex>
              )}
              {isCompany && isUsePrivateLayout && (
                <ButtonPublishVacancy onClick={showPublishVacancyDialog} />
              )}

              {isVacancyAdmin && <NewRecommendationsCount />}

              {isAuthorized ? (
                <>
                  {(isUsePrivateLayout || isAdmin) && (
                    <>{isChatUser && <ChatMessagesIconButton />}</>
                  )}
                  <LanguagePicker />
                  <ProfileDropdown
                    isUsePrivateLayout={isUsePrivateLayout}
                    actions={profileDropdownActions}
                  />
                </>
              ) : (
                <>
                  <Hidden mdDown>
                    <Flex
                      className={classes.authContainer}
                      alignVertical={"center"}>
                      <AppNavLink
                        to={"#"}
                        onClick={() =>
                          showLoginDialog(AppRoute.RECOMMENDATOR_SIGN_UP)
                        }
                        className={clsx(classes.login, classes.link)}>
                        {D_HEADER.login}
                      </AppNavLink>

                      <Divider vertical className={classes.divider} />
                      <AppNavLink
                        onClick={showRegisterDialog}
                        to={getRouteWithSlash(AppRoute.RECOMMENDATOR_SIGN_UP)}
                        className={clsx(classes.register, classes.link)}>
                        {D_HEADER.register}
                      </AppNavLink>
                    </Flex>
                  </Hidden>
                  <Hidden mdUp>
                    <DropdownList
                      list={getAuthList()}
                      icon={<ProfileIcon className={classes.profileIcon} />}
                      activeIcon={
                        <ProfileIconActive className={classes.profileIcon} />
                      }
                    />
                  </Hidden>
                  <LanguagePicker />
                </>
              )}
            </Flex>
            {(!isAuthorized || isAdmin) && (
              <Hidden
                mdUp={
                  burgerPlacement === "left" ||
                  burgerVisible !== "always" ||
                  !withBurger
                }
                mdDown={burgerPlacement === "left" || !withBurger}>
                <IconButton
                  onClick={toggleSidebar}
                  className={classes.menuBurger}
                  id="menu_burger_button">
                  <Menu />
                </IconButton>
              </Hidden>
            )}
          </Flex>,
          fullWidth,
          true
        )}
        {companyId && renderCompanyEditDialog()}
        {renderRegisterDialog()}
        {renderVacancyCreateDialog()}
        {companyWithParsedVacanciesDialog()}
      </header>
    </>
  );
};

export default Header;
