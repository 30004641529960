import { FC } from "react";
import { useStyles } from "./styles";
import { Hidden } from "@mui/material";
import AppNavLink from "@/common/components/AppNavLink";
import { AppRoute, getRouteWithSlash } from "@/router/AppRoute";
import Flex from "@/common/components/Flex";
import { D_HEADER } from "@/i18n/dictionary/header.dictionary";
import { useIsCandidate } from "@/auth/hooks/useIsCandidate";
import {
  DefaultHeaderActionsProps,
  HeaderRoutsType,
} from "./DefaultHeaderActions.types";
import { useIsCompany } from "@/auth/hooks/useIsCompany";

const headerRouts: HeaderRoutsType = [
  AppRoute.VACANCIES_LIST,
  AppRoute.CANDIDATES_LIST,
  AppRoute.COMPANIES_LIST,
];

const excludeRoutsForCompany = [
  AppRoute.COMPANY_VACANCIES,
  AppRoute.COMPANY_RECOMMENDATIONS,
  AppRoute.CHAT,
];

const DefaultHeaderActions: FC<DefaultHeaderActionsProps> = ({ exclude }) => {
  const isCandidate = useIsCandidate();
  const isCompany = useIsCompany();
  const { classes } = useStyles();
  const { pathname } = location;
  const isHideHeaderActions =
    isCompany &&
    excludeRoutsForCompany.some((route) => pathname.includes(route));

  if (isHideHeaderActions) {
    return null;
  }

  return (
    <Hidden mdDown>
      <Flex className={classes.root}>
        <AppNavLink
          className={classes.link}
          external="https://www.matchit.me/matchit-perks"
          target={"_blank"}
          to={"#"}>
          {D_HEADER.perks}
        </AppNavLink>
        {headerRouts.map((route) => {
          if (
            (isCandidate && route === AppRoute.CANDIDATES_LIST) ||
            exclude?.includes(route)
          ) {
            return null;
          }

          return (
            <AppNavLink
              key={route}
              className={classes.link}
              to={getRouteWithSlash(route)}>
              {D_HEADER[route]}
            </AppNavLink>
          );
        })}
      </Flex>
    </Hidden>
  );
};

export default DefaultHeaderActions;
