import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";

export const useStyles = makeStyles()((theme: AppTheme) => ({
  root: {
    width: "100%",
  },
  container: {
    position: "relative",
  },
  onepageContainer: {
    marginTop: theme.spacing(2),
  },
  button: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    margin: theme.spacing(3, "auto"),
    minHeight: 48,
  },
  orderSelect: {
    minWidth: 150,
    marginTop: 0,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "auto",
      marginLeft: theme.spacing(2),
    },
  },
  actionsContainer: {
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0.7, 0),
      flexDirection: "column",
      alignItems: "flex-start !important",
      gap: 3,
    },
  },
  activeFilters: {},
  banner: {
    marginTop: theme.spacing(2),
  },
  vacancyButton: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: "11px 24px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  searchField: {
    flexGrow: 1,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));
