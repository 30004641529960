import { FC, memo } from "react";
import { Box, Chip, Tooltip } from "@mui/material";

import Props from "./Props";
import { useStyles } from "./styles";
import clsx from "clsx";
import { cropText, getStringWithValue } from "@/common/utils/textUtils";
import { useIsMobile } from "@/common/hooks/useIsMobile";
import { D_VACANCY_CARD } from "@/i18n/dictionary/vacancyCard.dictionary";

const ChipsContainer: FC<Props> = (props: Props) => {
  const {
    chips,
    activeChips,
    onChangeFilter,
    activeChipsVariant,
    maxChips,
    maxChipsOnMobile,
    onCard,
    customClasses,
    noCrop,
    ...rest
  } = props;
  const { classes } = useStyles();
  const isMobile = useIsMobile();
  const DEFAULT_MAX_CHIPS =
    maxChips && isMobile ? maxChipsOnMobile || maxChips - 1 : maxChips;

  if (!chips.length) {
    return null;
  }

  const renderChips = () =>
    chips.map((chip, i) => {
      if (!chip.name) {
        return null;
      }

      if (
        DEFAULT_MAX_CHIPS &&
        chips.length > DEFAULT_MAX_CHIPS + 1 &&
        i === DEFAULT_MAX_CHIPS
      ) {
        const restChipsQty = chips.length - DEFAULT_MAX_CHIPS;
        const chipDescription = getStringWithValue(
          D_VACANCY_CARD.chipDescription,
          restChipsQty
        );

        return (
          <Chip
            className={clsx(classes.chip, customClasses?.chip)}
            variant={"outlined"}
            key={chip.id}
            label={chipDescription}
          />
        );
      }

      if (DEFAULT_MAX_CHIPS && i > DEFAULT_MAX_CHIPS) {
        return null;
      }

      const isActive =
        activeChips?.map(({ id }) => id).includes(chip.id) || false;
      const secondaryActiveStyle =
        isActive && activeChipsVariant === "outlined";
      const maxCrop =
        DEFAULT_MAX_CHIPS && chips.length > DEFAULT_MAX_CHIPS ? 8 : 11;
      const isLongChip = chip.name.length > maxCrop && !noCrop;
      const isShowTooltip = chip.name.length > 40;

      const handleClick = () => {
        onChangeFilter && onChangeFilter(chip);
      };

      const renderChip = () => (
        <Chip
          className={clsx(
            classes.chip,
            secondaryActiveStyle && classes.chipOutlinedActive,
            customClasses?.chip
          )}
          variant={isActive ? activeChipsVariant || "filled" : "outlined"}
          onClick={!onCard ? handleClick : undefined}
          key={chip.id}
          label={isLongChip ? cropText(chip.name, maxCrop) : chip.name}
        />
      );

      return isLongChip || isShowTooltip ? (
        <Tooltip placement="top" title={chip.name}>
          {renderChip()}
        </Tooltip>
      ) : (
        renderChip()
      );
    });

  return <Box {...rest}>{renderChips()}</Box>;
};

export default memo(ChipsContainer);
