import { getStringWithValue } from "./textUtils";
import { D_VACANCY_CARD } from "@/i18n/dictionary/vacancyCard.dictionary";

export const formatLocations = (
  locations?: null | Array<string | null | undefined | { name: string }>,
  withSpaces?: boolean,
  limit?: number
): string => {
  if (!locations || !locations?.length) {
    return "";
  }
  const locationsCopy = [...locations];
  let restDescription = "";

  if (limit && locationsCopy.length > limit) {
    const restLocationsQty = locationsCopy.length - limit;
    locationsCopy.length = limit;

    restDescription =
      " " +
      getStringWithValue(D_VACANCY_CARD.chipDescription, restLocationsQty);
  }

  return (
    locationsCopy
      .map((location) =>
        typeof location === "string" ? location : location?.name || ""
      )
      .join(withSpaces ? ", " : ",") + restDescription || ""
  );
};

export const splitLocations = (locations?: string | null): string[] => {
  if (!locations || !locations?.length) {
    return [];
  }

  return locations.split(",");
};
