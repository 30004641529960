import { Form, FormSchema } from "../../hooks/useForm";
import { D_COMPANY_CONNECT } from "@/i18n/dictionary/companyConnect.dictionary";

export type Field = "email" | "comment";

export const schema: FormSchema<Field> = {
  email: {
    type: "email",
    required: true,
    length: {
      max: 128,
    },
    fieldName: D_COMPANY_CONNECT.email,
  },
  comment: {
    length: {
      max: 1024,
    },
    fieldName: D_COMPANY_CONNECT.comment,
  },
};

export const defaultForm: Form<Field> = {
  errors: {
    email: "",
    comment: "",
  },
  values: {
    email: "",
    comment: "",
  },
  isValid: true,
};
