import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";

export const useStyles = makeStyles()((theme: AppTheme) => ({
  root: {
    flexShrink: 0,
    borderWidth: 0,
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, 0.12)",
    borderBottomWidth: "thin",
  },
  vertical: {
    height: "100%",
    borderRightWidth: "thin",
  },
}));
