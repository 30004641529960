import React from "react";
import { IJobsFilterContext } from "./models/IJobsFilterContext";
import { defaultJobsFilter } from "./defaultJobsFilter";

export const JobsFilterContext = React.createContext<IJobsFilterContext>({
  filter: defaultJobsFilter,
  applyFilter: () => undefined,
  applyFilters: () => undefined,
  clearFilter: () => undefined,
  reset: () => undefined,
});
