const registrationDialog = Object.freeze({
  title: "Створити акаунт",
  subTitle: "Оберіть тип аккаунта котрий бажаєте створити",
  personalAccountTitle: "Особистий профіль",
  personalAccountDescription:
    "Оберіть, якщо шукаєте роботу або хочете рекомендувати кандидатів на вакансії та отримувати за це бонуси",
  companyAccountTitle: "Профіль компанії",
  companyAccountDescription:
    "Оберіть, якщо бажаєте публікувати вакансії та отримувати рекомендації кандидатів з усього світу",
});

export default registrationDialog;
