import { Dictionary } from "@/i18n/dictionary.types";
import companiesGridUA from "@/i18n/dictionary/ua/companiesGrid";
import companiesGridEN, {
  DCompaniesGridType,
} from "@/i18n/dictionary/en/companiesGrid";
import { t } from "@/i18n/t";

const COMPANIES_GRID: Dictionary<DCompaniesGridType> = {
  ua: companiesGridUA,
  en: companiesGridEN,
};

export const D_COMPANIES_GRID = t(COMPANIES_GRID);
