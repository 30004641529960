import { useContext } from "react";
import { AuthContext } from "../components/AuthContextProvider";
import { Role } from "@/generated/types";

export function useIsVacancyAdmin() {
  const { accessToken, role } = useContext(AuthContext);

  return (
    accessToken !== undefined && (role === Role.Company || role === Role.Admin)
  );
}
