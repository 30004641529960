import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";

const DEFAULT_AVATAR_SIZE = 40;
const DEFAULT_LOGO_SIZE = 52;

export const useStyles = makeStyles()((theme: AppTheme) => ({
  rootAvatar: {
    width: DEFAULT_AVATAR_SIZE,
    height: DEFAULT_AVATAR_SIZE,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    borderRadius: "50%",
    overflow: "hidden",
  },
  rootLogo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: DEFAULT_LOGO_SIZE,
    height: DEFAULT_LOGO_SIZE,
    borderRadius: 8,
    overflow: "hidden",
  },
  logo: {
    width: DEFAULT_LOGO_SIZE,
    height: "auto",
  },
  withChildren: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#bdbdbd",
    color: "#fff",
    fontSize: "1.25rem",
  },
}));
