import React, { FC } from "react";

import { useStyles } from "./ChatMessagesIconButton.styles";
import { ReactComponent as MessageIcon } from "@/common/icons/message-grey.svg";
import { ReactComponent as ChatIcon } from "@/common/icons/chat-black.svg";
import { IconButton } from "@mui/material";
import { useChatMessagesIconButton } from "@/pages/chat/components/ChatMessagesIconButton/useChatMessagesIconButton";
import { ChatMessagesIconButtonProps } from "./ChatMessagesIconButton.types";
import clsx from "clsx";

const ChatMessagesIconButton: FC<ChatMessagesIconButtonProps> = (props) => {
  const { isUsePrivateLayout, className } = props;
  const { goToChatPage, newMessagesCount, isCounterVisible } =
    useChatMessagesIconButton();

  const { classes } = useStyles();

  return (
    <IconButton
      className={clsx(classes.root, className)}
      onClick={goToChatPage}
      size={"small"}>
      {isCounterVisible && (
        <span className={classes.counter}>{newMessagesCount}</span>
      )}
      {isUsePrivateLayout ? (
        <ChatIcon className={classes.chatIcon} />
      ) : (
        <MessageIcon />
      )}
    </IconButton>
  );
};

export default ChatMessagesIconButton;
