import { useEffect, useRef } from "react";
import Props from "./Props";

export const useInfiniteScroll = (props: Props) => {
  const { onBottomHit, length } = props;
  const hasMoreData = useRef(true);
  const contentRef = useRef<HTMLDivElement>(null);

  function isBottom(ref: React.RefObject<HTMLDivElement>) {
    if (!ref.current) {
      return false;
    }
    return ref.current.getBoundingClientRect().bottom <= window.innerHeight;
  }

  useEffect(() => {
    if (length) {
      hasMoreData.current = true;
    }
  }, [length]);

  useEffect(() => {
    const onScroll = () => {
      if (isBottom(contentRef) && hasMoreData.current) {
        hasMoreData.current = false;
        onBottomHit();
      }
    };

    document.addEventListener("scroll", onScroll);
    return () => document.removeEventListener("scroll", onScroll);
  }, [onBottomHit]);

  return {
    contentRef,
  };
};
