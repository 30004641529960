import { useRef } from "react";

export const useScrollToErrorRef = () => {
  const childRef = useRef<null | { scrollToInputWithError: () => void }>(null);

  const scrollToError = () => {
    if (childRef.current) {
      childRef.current.scrollToInputWithError();
    }
  };

  return {
    childRef,
    scrollToError,
  };
};
