import * as Types from "../../../generated/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type ParsedCompanyQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ParsedCompanyQuery = {
  __typename?: "Query";
  parsedCompany?: {
    __typename?: "ParsedCompanyData";
    name: string;
    vacancies: number;
    recommendations: number;
    jobSource?: Types.JobSource | null;
  } | null;
};

export type LinkParsedCompanyMutationVariables = Types.Exact<{
  linkedin: Types.Scalars["String"];
}>;

export type LinkParsedCompanyMutation = {
  __typename?: "Mutation";
  linkParsedCompany: boolean;
};

export const ParsedCompanyDocument = gql`
  query ParsedCompany {
    parsedCompany {
      name
      vacancies
      recommendations
      jobSource
    }
  }
`;

/**
 * __useParsedCompanyQuery__
 *
 * To run a query within a React component, call `useParsedCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useParsedCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParsedCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useParsedCompanyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ParsedCompanyQuery,
    ParsedCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ParsedCompanyQuery, ParsedCompanyQueryVariables>(
    ParsedCompanyDocument,
    options
  );
}
export function useParsedCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ParsedCompanyQuery,
    ParsedCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ParsedCompanyQuery, ParsedCompanyQueryVariables>(
    ParsedCompanyDocument,
    options
  );
}
export type ParsedCompanyQueryHookResult = ReturnType<
  typeof useParsedCompanyQuery
>;
export type ParsedCompanyLazyQueryHookResult = ReturnType<
  typeof useParsedCompanyLazyQuery
>;
export type ParsedCompanyQueryResult = Apollo.QueryResult<
  ParsedCompanyQuery,
  ParsedCompanyQueryVariables
>;
export const LinkParsedCompanyDocument = gql`
  mutation LinkParsedCompany($linkedin: String!) {
    linkParsedCompany(linkedin: $linkedin)
  }
`;
export type LinkParsedCompanyMutationFn = Apollo.MutationFunction<
  LinkParsedCompanyMutation,
  LinkParsedCompanyMutationVariables
>;

/**
 * __useLinkParsedCompanyMutation__
 *
 * To run a mutation, you first call `useLinkParsedCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkParsedCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkParsedCompanyMutation, { data, loading, error }] = useLinkParsedCompanyMutation({
 *   variables: {
 *      linkedin: // value for 'linkedin'
 *   },
 * });
 */
export function useLinkParsedCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LinkParsedCompanyMutation,
    LinkParsedCompanyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LinkParsedCompanyMutation,
    LinkParsedCompanyMutationVariables
  >(LinkParsedCompanyDocument, options);
}
export type LinkParsedCompanyMutationHookResult = ReturnType<
  typeof useLinkParsedCompanyMutation
>;
export type LinkParsedCompanyMutationResult =
  Apollo.MutationResult<LinkParsedCompanyMutation>;
export type LinkParsedCompanyMutationOptions = Apollo.BaseMutationOptions<
  LinkParsedCompanyMutation,
  LinkParsedCompanyMutationVariables
>;
