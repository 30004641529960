import { SelectOptionItem } from "../common/components/StyledSelectPicker/Props";
import { formatEnglishLevel } from "../common/utils/searchEnginneUtils";
import { EnglishLevel } from "../common/models/EnglishLevel";

export const ENGLISH_LEVEL_OPTIONS: SelectOptionItem[] = [
  { label: formatEnglishLevel(EnglishLevel.ELEMENTARY), value: "0" },
  { label: formatEnglishLevel(EnglishLevel.PRE_INTERMEDIATE), value: "1" },
  { label: formatEnglishLevel(EnglishLevel.INTERMEDIATE), value: "2" },
  { label: formatEnglishLevel(EnglishLevel.UPPER_INTERMEDIATE), value: "3" },
  { label: formatEnglishLevel(EnglishLevel.ADVANCED), value: "4" },
  { label: formatEnglishLevel(EnglishLevel.NATIVE), value: "5" },
];

export const MIN_ENGLISH_LEVEL_OPTIONS: SelectOptionItem[] = [
  { label: formatEnglishLevel(EnglishLevel.ELEMENTARY), value: "0" },
  { label: formatEnglishLevel(EnglishLevel.PRE_INTERMEDIATE), value: "1" },
  { label: formatEnglishLevel(EnglishLevel.INTERMEDIATE), value: "2" },
  { label: formatEnglishLevel(EnglishLevel.UPPER_INTERMEDIATE), value: "3" },
  { label: formatEnglishLevel(EnglishLevel.ADVANCED), value: "4" },
  { label: formatEnglishLevel(EnglishLevel.NATIVE), value: "5" },
  { label: formatEnglishLevel(EnglishLevel.NOT_SPECIFIED), value: "6" },
];
