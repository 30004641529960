import React, { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";
import { Collapse, Typography } from "@mui/material";
import { useFilterContainer } from "./useFilterContainer";
import FilterHeader from "./components/FilterHeader";
import Flex from "../../../../Flex";
import FilterBody from "./components/FilterBody";
import clsx from "clsx";

const FilterContainer: FC<Props> = (props: Props) => {
  const {
    children,
    title,
    clearDisabled,
    onClear,
    chips,
    totalChipsQty,
    activeChips,
    onChangeFilter,
    className,
    ...rest
  } = props;
  const { toggled, toggle } = useFilterContainer();

  const classes = useStyles();

  return (
    <Flex
      direction={"column"}
      className={clsx(classes.root, className)}
      {...rest}>
      <Flex
        onClick={toggle}
        alignVertical={"center"}
        className={classes.headerContainer}>
        <Typography className={classes.sign}>{toggled ? "-" : "+"}</Typography>
        <FilterHeader
          className={classes.header}
          title={title}
          clearDisabled={clearDisabled}
          count={totalChipsQty}
          countActive={activeChips.length}
          onClear={onClear}
        />
      </Flex>
      <Collapse in={toggled}>
        {children || (
          <FilterBody
            title={title}
            chips={chips}
            activeChips={activeChips}
            onChangeFilter={onChangeFilter}
          />
        )}
      </Collapse>
    </Flex>
  );
};

export default FilterContainer;
