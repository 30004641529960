const recommendationStatus = Object.freeze({
  NEW: "New",
  WAITING: "Waiting",
  REVIEW: "Review",
  REJECTED: "Rejected",
  INTERVIEW: "Interview",
  PRE_SCREENING: "Pre-screening",
  ARRANGING_INTERVIEW: "Arranging Interview",
  FINAL_INTERVIEW: "Final Interview",
  TEST_TASK: "Test Task",
  OFFER: "Offer",
  HIRED: "Hired",
});

export default recommendationStatus;

export type DRecommendationStatusType = Record<
  keyof typeof recommendationStatus,
  string
>;
