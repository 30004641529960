import { useCallback, useContext, useMemo } from "react";
import { ReactComponent as DashboardIcon } from "@/common/icons/profileDropdown/dashboard.svg";
import { ReactComponent as HomeIcon } from "@/common/icons/profileDropdown/home.svg";
import { AppRoute, getRouteWithSlash } from "@/router/AppRoute";
import { useHistory } from "react-router-dom";
import { AuthContext } from "@/auth/components/AuthContextProvider";
import { useClipboard } from "@/common/hooks/useClipboard";
import { Role } from "@/generated/types";
import { formatReferralLink } from "@/common/utils/navUtils";
import { useProfileDropdownUserDataQuery } from "@/sections/ProfileDropdown/ProfileDropdown.operations.generated";
import { ProfileDropdownAction } from "@/sections/ProfileDropdown/ProfileDropdownContent/ProfileDropdownActionsList/ProfileDropdownActionsListItem/ProfileDropdownActionsListItem.types";
import { D_RECOMMENDATOR_PROFILE } from "@/i18n/dictionary/recommendatorProfile.dictionary";
import { D_PROFILE_DROPDOWN } from "@/i18n/dictionary/profileDropdown.dictionary";

export function useProfileDropdown() {
  const { role } = useContext(AuthContext);
  const { copyToClipboard } = useClipboard();
  const history = useHistory();
  const { data, previousData, loading } = useProfileDropdownUserDataQuery({
    fetchPolicy: "cache-and-network",
  });
  const user = data?.getUser || previousData?.getUser;

  const handleClickCopyReferralCode = useCallback(() => {
    if (data?.getUser.referralCode) {
      const referralCode = data.getUser.referralCode;

      copyToClipboard(
        formatReferralLink(referralCode),
        D_RECOMMENDATOR_PROFILE.referralCodeCopyMessage(referralCode)
      );
    }
  }, [copyToClipboard, data?.getUser.referralCode]);

  const goHome = useCallback(() => {
    history.push({ pathname: getRouteWithSlash(AppRoute.HOME) });
  }, [history]);

  const goDashboard = useCallback(() => {
    let route: AppRoute = AppRoute.ADMIN_RECOMMENDATIONS;

    switch (role) {
      case Role.Admin:
        route = AppRoute.ADMIN_RECOMMENDATIONS;
        break;
      case Role.Candidate:
        route = AppRoute.CANDIDATE_DASHBOARD;
        break;
      case Role.Recruiter:
        route = AppRoute.RECOMMENDATOR_DASHBOARD;
        break;
      case Role.Company:
        route = AppRoute.COMPANY_RECOMMENDATIONS;
        break;
      case Role.Referral:
        route = AppRoute.REFERRAL_DASHBOARD;
        break;
      case Role.Sales:
        route = AppRoute.SALES_DASHBOARD;
        break;
    }

    history.push(getRouteWithSlash(route));
  }, [history, role]);

  const mainActions: ProfileDropdownAction[] = useMemo(
    () => [
      {
        actionIcon: <HomeIcon />,
        actionName: D_PROFILE_DROPDOWN.home,
        actionCallback: goHome,
      },
      {
        actionIcon: <DashboardIcon />,
        actionName: D_PROFILE_DROPDOWN.dashboard,
        actionCallback: goDashboard,
      },
    ],
    [goDashboard, goHome]
  );

  return {
    userName: user?.fullName || "",
    avatar: user?.avatar,
    userEmail: user?.email || "",
    referralCode: user?.referralCode || "",
    loading,
    handleClickCopyReferralCode,
    goHome,
    goDashboard,
    mainActions,
  };
}
