import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isTodayApi from "dayjs/plugin/isToday";
import timezone from "dayjs/plugin/timezone";

import isYesterdayApi from "dayjs/plugin/isYesterday";
import { DateIOFormats } from "@date-io/core/IUtils";
import { getLocality } from "./navUtils";

require("dayjs/locale/uk");

dayjs.extend(isYesterdayApi);
dayjs.extend(isTodayApi);

dayjs.extend(utc);
dayjs.extend(timezone);

export function formatDate(timestamp: number) {
  return dayjs(new Date(timestamp)).format("D\xa0MMM");
}

export function formatDateToISO(date: string, timeOnly?: "timeOnly") {
  return timeOnly
    ? dayjs(date).format("HH:mm")
    : dayjs(date).format("YYYY-MM-DD");
}

export function getTimeWithDelay(date: string, delay: number) {
  const formattedTime = dayjs(date).format("HH:mm").split(":");
  const hoursWithDelay = (+formattedTime[0] + delay).toString();
  const isHoursFormatHH = hoursWithDelay.length === 2;
  formattedTime[0] = isHoursFormatHH ? hoursWithDelay : `0${hoursWithDelay}`;

  return formattedTime.join(":");
}

export const formatDateToChatMessageDate = (date: string) => {
  const currLanguage = getLocality();

  if (currLanguage === "ua") {
    dayjs.locale("uk");
  }

  const dateMoment = dayjs(date);

  if (dateMoment.isToday()) {
    return dateMoment.format("HH:mm");
  } else if (dateMoment.isYesterday()) {
    return `Yesterday, ${dateMoment.format("HH:mm")}`;
  }

  return dateMoment.format("MMM DD, HH:mm");
};

export const formatDateToChatMessagePreviewDate = (date: string) => {
  const dateMoment = dayjs(date);

  if (dateMoment.isToday()) {
    return dateMoment.format("HH:mm");
  } else if (dateMoment.isSame(dayjs(), "year")) {
    return dateMoment.format("MMM DD");
  }

  return dateMoment.format("MM.DD.YY");
};

export const defaultDateFormats: Partial<DateIOFormats> = {
  keyboardDate: "DD.MM.YYYY",
};

export function formatDateToProfileDialog(timestamp: number) {
  const currLanguage = getLocality();

  if (currLanguage === "ua") {
    dayjs.locale("uk");
  }

  return dayjs(new Date(timestamp)).format("DD MMMM YYYY");
}

export const getStartDate = () =>
  dayjs().utc().add(-11, "months").startOf("month").toDate().toString();

export const getEndDate = () => dayjs().utc().endOf("month").toString();

export const getTimezone = () => dayjs.tz.guess();
