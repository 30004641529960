const registrationDialog = Object.freeze({
  title: "Register an account",
  subTitle: "Choose the type of account you want to create",
  personalAccountTitle: "Personal profile",
  personalAccountDescription:
    "Choose this if you looking for a job or want to recommend candidates for vacancies and receive bonuses for it",
  companyAccountTitle: "Company profile",
  companyAccountDescription:
    "Choose this if you want to post jobs and receive recommendations of candidates from around the world",
});

export default registrationDialog;

export type DRegistrationDialogType = Record<
  keyof typeof registrationDialog,
  string
>;
