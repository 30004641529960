import { Dictionary } from "@/i18n/dictionary.types";
import vacancyCardUA from "@/i18n/dictionary/ua/vacancyCard";
import vacancyCardEN, {
  DVacancyCardType,
} from "@/i18n/dictionary/en/vacancyCard";
import { t } from "@/i18n/t";

const VACANCY_CARD: Dictionary<DVacancyCardType> = {
  ua: vacancyCardUA,
  en: vacancyCardEN,
};

export const D_VACANCY_CARD = t(VACANCY_CARD);
