import React, { FC } from "react";
import List from "@mui/material/List";
import ProfileDropdownActionsListItem from "@/sections/ProfileDropdown/ProfileDropdownContent/ProfileDropdownActionsList/ProfileDropdownActionsListItem";
import { ProfileDropdownActionsListProps } from "@/sections/ProfileDropdown/ProfileDropdownContent/ProfileDropdownActionsList/ProfileDropdownActionsList.types";

const ProfileDropdownActionsList: FC<ProfileDropdownActionsListProps> = (
  props
) => {
  const { mainActions, additionalActions } = props;

  return (
    <List>
      {[...mainActions, ...additionalActions].map((action) => (
        <ProfileDropdownActionsListItem key={action.actionName} {...action} />
      ))}
    </List>
  );
};

export default ProfileDropdownActionsList;
