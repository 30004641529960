import { useEffect, useRef } from "react";

export const useDebounce = (
  callback: (...args: any[]) => void,
  wait: number
) => {
  const argsRef = useRef();
  const timeout = useRef();

  function cleanup() {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }

  useEffect(() => cleanup, []);
  // @ts-ignore
  return (...args: any[]) => {
    // @ts-ignore
    argsRef.current = args;

    cleanup();

    // @ts-ignore
    timeout.current = setTimeout(() => {
      if (argsRef.current) {
        // @ts-ignore
        callback(...argsRef.current);
      }
    }, wait);
  };
};
