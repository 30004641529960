import { forwardRef } from "react";
import { FormProps } from "./Form.types";
import { useForm } from "./useForm";

export const Form = forwardRef((props: FormProps, ref) => {
  const { formRef, handleSubmit } = useForm(props, ref);
  const { className, children, disabled } = props;

  return (
    <form onSubmit={handleSubmit} className={className} ref={formRef}>
      {children}
      <button
        style={{
          display: "none",
        }}
        disabled={disabled}
        type={"submit"}>
        submit
      </button>
    </form>
  );
});
